/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Address } from '../models/address';
import { Address as AddressListAddress } from '../models/Address-list/address';
import { Address as AddressReadAddress } from '../models/Address-read/address';
import { Address as AddressWriteAddress } from '../models/Address-write/address';
import { Jsonld as AddressJsonld } from '../models/Address/jsonld';
import { Address as AddressJsonldListAddress } from '../models/Address/jsonld-list/address';
import { Address as AddressJsonldReadAddress } from '../models/Address/jsonld-read/address';
import { Address as AddressJsonldWriteAddress } from '../models/Address/jsonld-write/address';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAddressCollection
   */
  static readonly GetAddressCollectionPath = '/api/addresses';

  /**
   * Retrieves the collection of Address resources.
   *
   * Retrieves the collection of Address resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    street?: string;
    number?: string;
    city?: string;
    postalCode?: string;
    name?: string;
    surnames?: string;
    email?: string;
    phoneNumber?: string;
    'user.email'?: string;
    'island.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AddressListAddress>>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.GetAddressCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('street', params.street, {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('city', params.city, {"style":"form","explode":false});
      rb.query('postalCode', params.postalCode, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('surnames', params.surnames, {"style":"form","explode":false});
      rb.query('email', params.email, {"style":"form","explode":false});
      rb.query('phoneNumber', params.phoneNumber, {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('island.name', params['island.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AddressListAddress>>;
      })
    );
  }

  /**
   * Retrieves the collection of Address resources.
   *
   * Retrieves the collection of Address resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAddressCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    street?: string;
    number?: string;
    city?: string;
    postalCode?: string;
    name?: string;
    surnames?: string;
    email?: string;
    phoneNumber?: string;
    'user.email'?: string;
    'island.name'?: string;
    context?: HttpContext
  }
): Observable<Array<AddressListAddress>> {

    return this.getAddressCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AddressListAddress>>) => r.body as Array<AddressListAddress>)
    );
  }

  /**
   * Retrieves the collection of Address resources.
   *
   * Retrieves the collection of Address resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    street?: string;
    number?: string;
    city?: string;
    postalCode?: string;
    name?: string;
    surnames?: string;
    email?: string;
    phoneNumber?: string;
    'user.email'?: string;
    'island.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AddressListAddress>>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.GetAddressCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('street', params.street, {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('city', params.city, {"style":"form","explode":false});
      rb.query('postalCode', params.postalCode, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('surnames', params.surnames, {"style":"form","explode":false});
      rb.query('email', params.email, {"style":"form","explode":false});
      rb.query('phoneNumber', params.phoneNumber, {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('island.name', params['island.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AddressListAddress>>;
      })
    );
  }

  /**
   * Retrieves the collection of Address resources.
   *
   * Retrieves the collection of Address resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAddressCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    street?: string;
    number?: string;
    city?: string;
    postalCode?: string;
    name?: string;
    surnames?: string;
    email?: string;
    phoneNumber?: string;
    'user.email'?: string;
    'island.name'?: string;
    context?: HttpContext
  }
): Observable<Array<AddressListAddress>> {

    return this.getAddressCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AddressListAddress>>) => r.body as Array<AddressListAddress>)
    );
  }

  /**
   * Path part for operation postAddressCollection
   */
  static readonly PostAddressCollectionPath = '/api/addresses';

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAddressCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAddressCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Address resource
     */
    body: AddressWriteAddress
  }
): Observable<StrictHttpResponse<Address>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.PostAddressCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Address>;
      })
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAddressCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAddressCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Address resource
     */
    body: AddressWriteAddress
  }
): Observable<Address> {

    return this.postAddressCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Address>) => r.body as Address)
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAddressCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAddressCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Address resource
     */
    body: AddressWriteAddress
  }
): Observable<StrictHttpResponse<Address>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.PostAddressCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Address>;
      })
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAddressCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAddressCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Address resource
     */
    body: AddressWriteAddress
  }
): Observable<Address> {

    return this.postAddressCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Address>) => r.body as Address)
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAddressCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postAddressCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Address resource
     */
    body: AddressWriteAddress
  }
): Observable<StrictHttpResponse<Address>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.PostAddressCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Address>;
      })
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAddressCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postAddressCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Address resource
     */
    body: AddressWriteAddress
  }
): Observable<Address> {

    return this.postAddressCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Address>) => r.body as Address)
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAddressCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postAddressCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Address resource
     */
    body: AddressWriteAddress
  }
): Observable<StrictHttpResponse<Address>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.PostAddressCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Address>;
      })
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAddressCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postAddressCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Address resource
     */
    body: AddressWriteAddress
  }
): Observable<Address> {

    return this.postAddressCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Address>) => r.body as Address)
    );
  }

  /**
   * Path part for operation getAddressItem
   */
  static readonly GetAddressItemPath = '/api/addresses/{id}';

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressReadAddress>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.GetAddressItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressReadAddress>;
      })
    );
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAddressItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<AddressReadAddress> {

    return this.getAddressItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AddressReadAddress>) => r.body as AddressReadAddress)
    );
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressReadAddress>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.GetAddressItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressReadAddress>;
      })
    );
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAddressItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<AddressReadAddress> {

    return this.getAddressItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<AddressReadAddress>) => r.body as AddressReadAddress)
    );
  }

  /**
   * Path part for operation putAddressItem
   */
  static readonly PutAddressItemPath = '/api/addresses/{id}';

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAddressItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAddressItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Address resource
     */
    body: AddressWriteAddress
  }
): Observable<StrictHttpResponse<Address>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.PutAddressItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Address>;
      })
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAddressItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAddressItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Address resource
     */
    body: AddressWriteAddress
  }
): Observable<Address> {

    return this.putAddressItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Address>) => r.body as Address)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAddressItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAddressItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Address resource
     */
    body: AddressWriteAddress
  }
): Observable<StrictHttpResponse<Address>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.PutAddressItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Address>;
      })
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAddressItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAddressItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Address resource
     */
    body: AddressWriteAddress
  }
): Observable<Address> {

    return this.putAddressItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Address>) => r.body as Address)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAddressItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putAddressItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Address resource
     */
    body: AddressWriteAddress
  }
): Observable<StrictHttpResponse<Address>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.PutAddressItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Address>;
      })
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAddressItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putAddressItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Address resource
     */
    body: AddressWriteAddress
  }
): Observable<Address> {

    return this.putAddressItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Address>) => r.body as Address)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAddressItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putAddressItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Address resource
     */
    body: AddressWriteAddress
  }
): Observable<StrictHttpResponse<Address>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.PutAddressItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Address>;
      })
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAddressItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putAddressItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Address resource
     */
    body: AddressWriteAddress
  }
): Observable<Address> {

    return this.putAddressItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Address>) => r.body as Address)
    );
  }

  /**
   * Path part for operation deleteAddressItem
   */
  static readonly DeleteAddressItemPath = '/api/addresses/{id}';

  /**
   * Removes the Address resource.
   *
   * Removes the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAddressItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddressItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AddressService.DeleteAddressItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Address resource.
   *
   * Removes the Address resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAddressItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddressItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteAddressItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
