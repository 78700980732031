/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ShipmentMethod } from '../models/shipment-method';
import { ShipmentMethod as ShipmentMethodListShipmentMethod } from '../models/ShipmentMethod-list/shipment-method';
import { ShipmentMethod as ShipmentMethodReadShipmentMethod } from '../models/ShipmentMethod-read/shipment-method';
import { ShipmentMethod as ShipmentMethodWriteShipmentMethod } from '../models/ShipmentMethod-write/shipment-method';
import { Jsonld as ShipmentMethodJsonld } from '../models/ShipmentMethod/jsonld';
import { ShipmentMethod as ShipmentMethodJsonldListShipmentMethod } from '../models/ShipmentMethod/jsonld-list/shipment-method';
import { ShipmentMethod as ShipmentMethodJsonldReadShipmentMethod } from '../models/ShipmentMethod/jsonld-read/shipment-method';
import { ShipmentMethod as ShipmentMethodJsonldWriteShipmentMethod } from '../models/ShipmentMethod/jsonld-write/shipment-method';

@Injectable({
  providedIn: 'root',
})
export class ShipmentMethodService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getShipmentMethodCollection
   */
  static readonly GetShipmentMethodCollectionPath = '/api/shipment_methods';

  /**
   * Retrieves the collection of ShipmentMethod resources.
   *
   * Retrieves the collection of ShipmentMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShipmentMethodCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentMethodCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    price?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ShipmentMethodListShipmentMethod>>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.GetShipmentMethodCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('price', params.price, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShipmentMethodListShipmentMethod>>;
      })
    );
  }

  /**
   * Retrieves the collection of ShipmentMethod resources.
   *
   * Retrieves the collection of ShipmentMethod resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShipmentMethodCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentMethodCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    price?: number;
    context?: HttpContext
  }
): Observable<Array<ShipmentMethodListShipmentMethod>> {

    return this.getShipmentMethodCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ShipmentMethodListShipmentMethod>>) => r.body as Array<ShipmentMethodListShipmentMethod>)
    );
  }

  /**
   * Retrieves the collection of ShipmentMethod resources.
   *
   * Retrieves the collection of ShipmentMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShipmentMethodCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentMethodCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    price?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ShipmentMethodListShipmentMethod>>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.GetShipmentMethodCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('price', params.price, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShipmentMethodListShipmentMethod>>;
      })
    );
  }

  /**
   * Retrieves the collection of ShipmentMethod resources.
   *
   * Retrieves the collection of ShipmentMethod resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShipmentMethodCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentMethodCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    price?: number;
    context?: HttpContext
  }
): Observable<Array<ShipmentMethodListShipmentMethod>> {

    return this.getShipmentMethodCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ShipmentMethodListShipmentMethod>>) => r.body as Array<ShipmentMethodListShipmentMethod>)
    );
  }

  /**
   * Path part for operation postShipmentMethodCollection
   */
  static readonly PostShipmentMethodCollectionPath = '/api/shipment_methods';

  /**
   * Creates a ShipmentMethod resource.
   *
   * Creates a ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postShipmentMethodCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postShipmentMethodCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<StrictHttpResponse<ShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.PostShipmentMethodCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethod>;
      })
    );
  }

  /**
   * Creates a ShipmentMethod resource.
   *
   * Creates a ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postShipmentMethodCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postShipmentMethodCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<ShipmentMethod> {

    return this.postShipmentMethodCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethod>) => r.body as ShipmentMethod)
    );
  }

  /**
   * Creates a ShipmentMethod resource.
   *
   * Creates a ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postShipmentMethodCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postShipmentMethodCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<StrictHttpResponse<ShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.PostShipmentMethodCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethod>;
      })
    );
  }

  /**
   * Creates a ShipmentMethod resource.
   *
   * Creates a ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postShipmentMethodCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postShipmentMethodCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<ShipmentMethod> {

    return this.postShipmentMethodCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethod>) => r.body as ShipmentMethod)
    );
  }

  /**
   * Creates a ShipmentMethod resource.
   *
   * Creates a ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postShipmentMethodCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postShipmentMethodCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<StrictHttpResponse<ShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.PostShipmentMethodCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethod>;
      })
    );
  }

  /**
   * Creates a ShipmentMethod resource.
   *
   * Creates a ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postShipmentMethodCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postShipmentMethodCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<ShipmentMethod> {

    return this.postShipmentMethodCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethod>) => r.body as ShipmentMethod)
    );
  }

  /**
   * Creates a ShipmentMethod resource.
   *
   * Creates a ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postShipmentMethodCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postShipmentMethodCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<StrictHttpResponse<ShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.PostShipmentMethodCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethod>;
      })
    );
  }

  /**
   * Creates a ShipmentMethod resource.
   *
   * Creates a ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postShipmentMethodCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postShipmentMethodCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<ShipmentMethod> {

    return this.postShipmentMethodCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethod>) => r.body as ShipmentMethod)
    );
  }

  /**
   * Path part for operation getShipmentMethodItem
   */
  static readonly GetShipmentMethodItemPath = '/api/shipment_methods/{id}';

  /**
   * Retrieves a ShipmentMethod resource.
   *
   * Retrieves a ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShipmentMethodItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentMethodItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ShipmentMethodReadShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.GetShipmentMethodItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethodReadShipmentMethod>;
      })
    );
  }

  /**
   * Retrieves a ShipmentMethod resource.
   *
   * Retrieves a ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShipmentMethodItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentMethodItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<ShipmentMethodReadShipmentMethod> {

    return this.getShipmentMethodItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethodReadShipmentMethod>) => r.body as ShipmentMethodReadShipmentMethod)
    );
  }

  /**
   * Retrieves a ShipmentMethod resource.
   *
   * Retrieves a ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShipmentMethodItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentMethodItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ShipmentMethodReadShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.GetShipmentMethodItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethodReadShipmentMethod>;
      })
    );
  }

  /**
   * Retrieves a ShipmentMethod resource.
   *
   * Retrieves a ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShipmentMethodItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentMethodItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<ShipmentMethodReadShipmentMethod> {

    return this.getShipmentMethodItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethodReadShipmentMethod>) => r.body as ShipmentMethodReadShipmentMethod)
    );
  }

  /**
   * Path part for operation putShipmentMethodItem
   */
  static readonly PutShipmentMethodItemPath = '/api/shipment_methods/{id}';

  /**
   * Replaces the ShipmentMethod resource.
   *
   * Replaces the ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putShipmentMethodItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putShipmentMethodItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<StrictHttpResponse<ShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.PutShipmentMethodItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethod>;
      })
    );
  }

  /**
   * Replaces the ShipmentMethod resource.
   *
   * Replaces the ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putShipmentMethodItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putShipmentMethodItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<ShipmentMethod> {

    return this.putShipmentMethodItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethod>) => r.body as ShipmentMethod)
    );
  }

  /**
   * Replaces the ShipmentMethod resource.
   *
   * Replaces the ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putShipmentMethodItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putShipmentMethodItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<StrictHttpResponse<ShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.PutShipmentMethodItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethod>;
      })
    );
  }

  /**
   * Replaces the ShipmentMethod resource.
   *
   * Replaces the ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putShipmentMethodItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putShipmentMethodItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<ShipmentMethod> {

    return this.putShipmentMethodItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethod>) => r.body as ShipmentMethod)
    );
  }

  /**
   * Replaces the ShipmentMethod resource.
   *
   * Replaces the ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putShipmentMethodItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putShipmentMethodItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<StrictHttpResponse<ShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.PutShipmentMethodItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethod>;
      })
    );
  }

  /**
   * Replaces the ShipmentMethod resource.
   *
   * Replaces the ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putShipmentMethodItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putShipmentMethodItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<ShipmentMethod> {

    return this.putShipmentMethodItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethod>) => r.body as ShipmentMethod)
    );
  }

  /**
   * Replaces the ShipmentMethod resource.
   *
   * Replaces the ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putShipmentMethodItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putShipmentMethodItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<StrictHttpResponse<ShipmentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.PutShipmentMethodItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentMethod>;
      })
    );
  }

  /**
   * Replaces the ShipmentMethod resource.
   *
   * Replaces the ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putShipmentMethodItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putShipmentMethodItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated ShipmentMethod resource
     */
    body: ShipmentMethodWriteShipmentMethod
  }
): Observable<ShipmentMethod> {

    return this.putShipmentMethodItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentMethod>) => r.body as ShipmentMethod)
    );
  }

  /**
   * Path part for operation deleteShipmentMethodItem
   */
  static readonly DeleteShipmentMethodItemPath = '/api/shipment_methods/{id}';

  /**
   * Removes the ShipmentMethod resource.
   *
   * Removes the ShipmentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteShipmentMethodItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteShipmentMethodItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentMethodService.DeleteShipmentMethodItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the ShipmentMethod resource.
   *
   * Removes the ShipmentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteShipmentMethodItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteShipmentMethodItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteShipmentMethodItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
