import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function nieValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) return null;

		return validateDni(control.value) ||
			validateNie(control.value) ||
			validateCif(control.value)
			? null
			: { value: control.value };
	};
}

function validateDni(value: string): boolean {
	let number: number = parseInt(value.substring(0, value.length - 1)) % 23;

	let letter: string = value
		.substring(value.length, value.length - 1)
		.toUpperCase();
	let letters: string = 'T|R|W|A|G|M|Y|F|P|D|X|B|N|J|Z|S|Q|V|H|L|C|K|E|T';
	let regularExpression = /^\d{8}[a-zA-Z]$/;

	return (
		regularExpression.test(value) && letter == letters.split('|')[number]
	);
}

function validateNie(value: string): boolean {
	let nie_prefix = value.charAt(0);
	switch (nie_prefix) {
		case 'X':
			nie_prefix = '0';
			break;
		case 'Y':
			nie_prefix = '1';
			break;
		case 'Z':
			nie_prefix = '2';
			break;
	}
	return validateDni(nie_prefix + value.substring(1));
}

function validateCif(value: string): boolean {
	let CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;

	let match = value.match(CIF_REGEX);
	if (!match) return false;

	let letter = match[1],
		number = match[2],
		control = match[3];

	let even_sum = 0;
	let odd_sum = 0;
	let n;

	for (let i = 0; i < number.length; i++) {
		n = parseInt(number[i], 10);

		// Odd positions (Even index equals to odd position. i=0 equals first position)
		if (i % 2 === 0) {
			// Odd positions are multiplied first.
			n *= 2;

			// If the multiplication is bigger than 10 we need to adjust
			odd_sum += n < 10 ? n : n - 9;

			// Even positions
			// Just sum them
		} else {
			even_sum += n;
		}
	}

	let control_digit =
		10 - parseInt((even_sum + odd_sum).toString().slice(-1));
	let control_letter = 'JABCDEFGHI'.charAt(control_digit);

	// Control must be a digit
	if (letter.match(/[ABEH]/)) {
		return control == control_digit.toString();

		// Control must be a letter
	} else if (letter.match(/[KPQS]/)) {
		return control == control_letter;

		// Can be either
	} else {
		return control == control_digit.toString() || control == control_letter;
	}
}
