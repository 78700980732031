import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function zipValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		let validZipCodes: Array<string> = [
			'350',
			'351',
			'352',
			'353',
			'354',
			'355',
			'356',
			'380',
			'381',
			'382',
			'383',
			'384',
			'385',
			'386',
			'387',
			'388',
			'389',
			'356',
		];

		if (!control.value) return null;

		return validZipCodes.includes(control.value.substring(0, 3))
			? null
			: { value: control.value };
	};
}
