import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CustomPurchasePopoverComponent } from './custom-purchase-popover/custom-purchase-popover.component';
import { environment } from 'src/environments/environment';
import { CmsService } from 'src/app/api/services';
@Component({
  selector: 'app-custom-purchase-button',
  templateUrl: './custom-purchase-button.component.html',
  styleUrls: ['./custom-purchase-button.component.scss'],
})
export class CustomPurchaseButtonComponent implements OnInit {

  public message: any;

  constructor(
    private popoverCtrl: PopoverController,
    private cmsService: CmsService
    ) { }

  ngOnInit() {
    this.cmsService.getCmsItem$Json({id: environment.customPurchase}).subscribe((res) => {
			this.message = res      
		})
  }

  showPopup(){
    this.popoverCtrl.create({
      component: CustomPurchasePopoverComponent,
      animated: false,
      componentProps: {message: this.message},
      mode: 'md',
      size: 'auto',
      cssClass: 'popover'
    })
    .then(
      (pop)=>{
        pop.present();
      }
    )
  }

}
