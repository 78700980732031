/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Order } from '../models/order';
import { Order as OrderListOrder } from '../models/Order-list/order';
import { Order as OrderReadOrder } from '../models/Order-read/order';
import { Order as OrderWriteOrder } from '../models/Order-write/order';
import { Jsonld as OrderJsonld } from '../models/Order/jsonld';
import { Order as OrderJsonldListOrder } from '../models/Order/jsonld-list/order';
import { Order as OrderJsonldReadOrder } from '../models/Order/jsonld-read/order';
import { Order as OrderJsonldWriteOrder } from '../models/Order/jsonld-write/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation invoiceOrderItem
   */
  static readonly InvoiceOrderItemPath = '/api/invoice-pdf/{orderNumber}';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceOrderItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceOrderItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.InvoiceOrderItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invoiceOrderItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceOrderItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<Order> {

    return this.invoiceOrderItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceOrderItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceOrderItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.InvoiceOrderItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invoiceOrderItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceOrderItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<Order> {

    return this.invoiceOrderItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Path part for operation getOrderCollection
   */
  static readonly GetOrderCollectionPath = '/api/orders';

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'creationDate[before]'?: string;
    'creationDate[strictly_before]'?: string;
    'creationDate[after]'?: string;
    'creationDate[strictly_after]'?: string;
    number?: string;
    'user.email'?: string;
    'marketPlaces.name'?: string;
    'payments.status'?: string;
    'payments.method.name'?: string;
    total?: number;
    status?: string;
    'shipments.number'?: number;
    type?: string;
    'shipments.method.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OrderListOrder>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.GetOrderCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('creationDate[before]', params['creationDate[before]'], {"style":"form","explode":false});
      rb.query('creationDate[strictly_before]', params['creationDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('creationDate[after]', params['creationDate[after]'], {"style":"form","explode":false});
      rb.query('creationDate[strictly_after]', params['creationDate[strictly_after]'], {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('marketPlaces.name', params['marketPlaces.name'], {"style":"form","explode":false});
      rb.query('payments.status', params['payments.status'], {"style":"form","explode":false});
      rb.query('payments.method.name', params['payments.method.name'], {"style":"form","explode":false});
      rb.query('total', params.total, {"style":"form","explode":false});
      rb.query('status', params.status, {"style":"form","explode":false});
      rb.query('shipments.number', params['shipments.number'], {"style":"form","explode":false});
      rb.query('type', params.type, {"style":"form","explode":false});
      rb.query('shipments.method.name', params['shipments.method.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderListOrder>>;
      })
    );
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'creationDate[before]'?: string;
    'creationDate[strictly_before]'?: string;
    'creationDate[after]'?: string;
    'creationDate[strictly_after]'?: string;
    number?: string;
    'user.email'?: string;
    'marketPlaces.name'?: string;
    'payments.status'?: string;
    'payments.method.name'?: string;
    total?: number;
    status?: string;
    'shipments.number'?: number;
    type?: string;
    'shipments.method.name'?: string;
    context?: HttpContext
  }
): Observable<Array<OrderListOrder>> {

    return this.getOrderCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderListOrder>>) => r.body as Array<OrderListOrder>)
    );
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'creationDate[before]'?: string;
    'creationDate[strictly_before]'?: string;
    'creationDate[after]'?: string;
    'creationDate[strictly_after]'?: string;
    number?: string;
    'user.email'?: string;
    'marketPlaces.name'?: string;
    'payments.status'?: string;
    'payments.method.name'?: string;
    total?: number;
    status?: string;
    'shipments.number'?: number;
    type?: string;
    'shipments.method.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OrderListOrder>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.GetOrderCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('creationDate[before]', params['creationDate[before]'], {"style":"form","explode":false});
      rb.query('creationDate[strictly_before]', params['creationDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('creationDate[after]', params['creationDate[after]'], {"style":"form","explode":false});
      rb.query('creationDate[strictly_after]', params['creationDate[strictly_after]'], {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('marketPlaces.name', params['marketPlaces.name'], {"style":"form","explode":false});
      rb.query('payments.status', params['payments.status'], {"style":"form","explode":false});
      rb.query('payments.method.name', params['payments.method.name'], {"style":"form","explode":false});
      rb.query('total', params.total, {"style":"form","explode":false});
      rb.query('status', params.status, {"style":"form","explode":false});
      rb.query('shipments.number', params['shipments.number'], {"style":"form","explode":false});
      rb.query('type', params.type, {"style":"form","explode":false});
      rb.query('shipments.method.name', params['shipments.method.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderListOrder>>;
      })
    );
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'creationDate[before]'?: string;
    'creationDate[strictly_before]'?: string;
    'creationDate[after]'?: string;
    'creationDate[strictly_after]'?: string;
    number?: string;
    'user.email'?: string;
    'marketPlaces.name'?: string;
    'payments.status'?: string;
    'payments.method.name'?: string;
    total?: number;
    status?: string;
    'shipments.number'?: number;
    type?: string;
    'shipments.method.name'?: string;
    context?: HttpContext
  }
): Observable<Array<OrderListOrder>> {

    return this.getOrderCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderListOrder>>) => r.body as Array<OrderListOrder>)
    );
  }

  /**
   * Path part for operation postOrderCollection
   */
  static readonly PostOrderCollectionPath = '/api/orders';

  /**
   * Creates a Order resource.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Order resource
     */
    body: OrderWriteOrder
  }
): Observable<StrictHttpResponse<OrderReadOrder>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PostOrderCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReadOrder>;
      })
    );
  }

  /**
   * Creates a Order resource.
   *
   * Creates a Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Order resource
     */
    body: OrderWriteOrder
  }
): Observable<OrderReadOrder> {

    return this.postOrderCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReadOrder>) => r.body as OrderReadOrder)
    );
  }

  /**
   * Creates a Order resource.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Order resource
     */
    body: OrderWriteOrder
  }
): Observable<StrictHttpResponse<OrderReadOrder>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PostOrderCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReadOrder>;
      })
    );
  }

  /**
   * Creates a Order resource.
   *
   * Creates a Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Order resource
     */
    body: OrderWriteOrder
  }
): Observable<OrderReadOrder> {

    return this.postOrderCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReadOrder>) => r.body as OrderReadOrder)
    );
  }

  /**
   * Creates a Order resource.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Order resource
     */
    body: OrderWriteOrder
  }
): Observable<StrictHttpResponse<OrderReadOrder>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PostOrderCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReadOrder>;
      })
    );
  }

  /**
   * Creates a Order resource.
   *
   * Creates a Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Order resource
     */
    body: OrderWriteOrder
  }
): Observable<OrderReadOrder> {

    return this.postOrderCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReadOrder>) => r.body as OrderReadOrder)
    );
  }

  /**
   * Creates a Order resource.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Order resource
     */
    body: OrderWriteOrder
  }
): Observable<StrictHttpResponse<OrderReadOrder>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PostOrderCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReadOrder>;
      })
    );
  }

  /**
   * Creates a Order resource.
   *
   * Creates a Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Order resource
     */
    body: OrderWriteOrder
  }
): Observable<OrderReadOrder> {

    return this.postOrderCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReadOrder>) => r.body as OrderReadOrder)
    );
  }

  /**
   * Path part for operation getOrderItem
   */
  static readonly GetOrderItemPath = '/api/orders/{id}';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OrderReadOrder>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.GetOrderItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReadOrder>;
      })
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<OrderReadOrder> {

    return this.getOrderItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReadOrder>) => r.body as OrderReadOrder)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OrderReadOrder>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.GetOrderItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReadOrder>;
      })
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<OrderReadOrder> {

    return this.getOrderItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReadOrder>) => r.body as OrderReadOrder)
    );
  }

  /**
   * Path part for operation putOrderItem
   */
  static readonly PutOrderItemPath = '/api/orders/{id}';

  /**
   * Replaces the Order resource.
   *
   * Replaces the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Order resource
     */
    body: OrderWriteOrder
  }
): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PutOrderItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Replaces the Order resource.
   *
   * Replaces the Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Order resource
     */
    body: OrderWriteOrder
  }
): Observable<Order> {

    return this.putOrderItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Replaces the Order resource.
   *
   * Replaces the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Order resource
     */
    body: OrderWriteOrder
  }
): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PutOrderItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Replaces the Order resource.
   *
   * Replaces the Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Order resource
     */
    body: OrderWriteOrder
  }
): Observable<Order> {

    return this.putOrderItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Replaces the Order resource.
   *
   * Replaces the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Order resource
     */
    body: OrderWriteOrder
  }
): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PutOrderItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Replaces the Order resource.
   *
   * Replaces the Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Order resource
     */
    body: OrderWriteOrder
  }
): Observable<Order> {

    return this.putOrderItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Replaces the Order resource.
   *
   * Replaces the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Order resource
     */
    body: OrderWriteOrder
  }
): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.PutOrderItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Replaces the Order resource.
   *
   * Replaces the Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Order resource
     */
    body: OrderWriteOrder
  }
): Observable<Order> {

    return this.putOrderItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Path part for operation deleteOrderItem
   */
  static readonly DeleteOrderItemPath = '/api/orders/{id}';

  /**
   * Removes the Order resource.
   *
   * Removes the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrderItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.DeleteOrderItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Order resource.
   *
   * Removes the Order resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteOrderItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteOrderItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
