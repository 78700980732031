import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(orderStatus: string): string {
    if (orderStatus.toUpperCase() === 'CONFIRMED') {
      return 'PAGADO';
    } else {
      return 'PENDIENTE';
    }
  }

}
