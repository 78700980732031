import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	AddressReadAddress,
	AddressWriteAddress,
	Island,
	User,
} from 'src/app/api/models';
import {
	AddressService,
	IslandService,
	UserService,
} from 'src/app/api/services';
import {
	FormBuilder,
	FormGroup,
	Validators,
	AbstractControl,
} from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { nieValidator } from 'src/app/validators/nie.validator';
import { zipValidator } from 'src/app/validators/zip.validator';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-address-details',
	templateUrl: './address-details.component.html',
	styleUrls: ['./address-details.component.scss'],
})
export class AddressDetailsComponent implements OnInit {
	@ViewChild('address') address: any;

	@Input() displayRegister: boolean = false;

	@Output() addressValidated: EventEmitter<Boolean> =
		new EventEmitter<Boolean>();

	@Output() addressPostalCodeValidated: EventEmitter<Boolean> =
		new EventEmitter<Boolean>();

	@Output() addressNieValidated: EventEmitter<Boolean> =
		new EventEmitter<Boolean>();

	@Output() addressObject: EventEmitter<AddressWriteAddress> =
		new EventEmitter<AddressWriteAddress>();
	@Output() postalCode: EventEmitter<string> = new EventEmitter<string>();
	@Output() sameAddress: EventEmitter<boolean> = new EventEmitter<boolean>();

	public addressSaved: AddressReadAddress | undefined;
	public userLoged: User | undefined;
	public dataLoaded: boolean = false;
	public updatingData: boolean = false;
	public islandsList: Array<Island> = [];
	public selectedIsland: Island = {};
	public idIsland: string = '';
	public addressDetailsForm: FormGroup;
	public isLoading: boolean;
	public postalCodeForm: AbstractControl | null;
	public nieForm: AbstractControl | null;

	constructor(
		private formBuilder: FormBuilder,
		private addressService: AddressService,
		private userService: UserService,
		private islandService: IslandService,
		private cookieService: CookieService
	) {
		this.addressDetailsForm = this.formBuilder.group({
			type: ['particular', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			name: ['', Validators.required],
			surnames: ['', Validators.required],
			street: ['', Validators.required],
			number: ['', Validators.required],
			additionalInfo: [''],
			island: ['', Validators.required],
			province: ['', Validators.required],
			city: ['', Validators.required],
			postalCode: ['', [Validators.required, zipValidator()]],
			phoneNumber: ['', Validators.required],
			nie: ['', [Validators.required, nieValidator()]],
		});
	}

	ngOnInit(): void {
		this.isLoading = true;
		this.getUserLoged();
		this.getIslands();

		if (!this.cookieService.get('BEARER_TOKEN')) {
			this.dataLoaded = true;
		}
	}

	getUserLoged() {
		if (this.cookieService.get('BEARER_TOKEN')) {
			this.userService.meUserCollection$Json().subscribe(
				(response) => {
					this.userLoged = response as User;
					console.log(response);
					this.getDefaultAddress();
				},
				() => {
					this.isLoading = false;
				}
			);
		}
	}

	getDefaultAddress() {
		let id: string = '';
		let idSplited: Array<string> = [];

		if (this.userLoged && 'defaultShippingAddress' in this.userLoged) {
			if (this.userLoged.defaultShippingAddress) {
				id = this.userLoged.defaultShippingAddress;
			}
		}

		idSplited = id.split('/');
		if (idSplited.length > 0) {
			id = idSplited[idSplited.length - 1];
		}

		if (id) {
			this.addressService.getAddressItem$Json({ id: id }).subscribe(
				(response) => {
					this.addressSaved = response;
					console.log({ response: response });
					this.loadAddressDetails(this.addressSaved);
					this.isLoading = false;
				},
				() => {
					this.isLoading = false;
				}
			);
		} else {
			this.isLoading = false;
		}
	}

	loadAddressDetails(addressDetails: AddressReadAddress | undefined) {
		this.addressDetailsForm.controls['type'].setValue(
			addressDetails?.type ? addressDetails.type : 'particular'
		);

		this.addressDetailsForm.controls['email'].setValue(
			addressDetails?.email
		);
		this.addressDetailsForm.controls['name'].setValue(addressDetails?.name);
		this.addressDetailsForm.controls['surnames'].setValue(
			addressDetails?.surnames
		);
		this.addressDetailsForm.controls['street'].setValue(
			addressDetails?.street
		);
		this.addressDetailsForm.controls['number'].setValue(
			addressDetails?.number
		);
		this.addressDetailsForm.controls['additionalInfo'].setValue(
			addressDetails?.additionalInfo
		);
		this.addressDetailsForm.controls['island'].setValue(
			addressDetails?.island
		);
		this.addressDetailsForm.controls['city'].setValue(addressDetails?.city);
		this.addressDetailsForm.controls['postalCode'].setValue(
			addressDetails?.postalCode
		);
		this.addressDetailsForm.controls['phoneNumber'].setValue(
			addressDetails?.phoneNumber
		);
		this.addressDetailsForm.controls['nie'].setValue(addressDetails?.nie);

		this.addressDetailsForm.markAllAsTouched();

		this.check();
		this.sendPostalCode();
		this.changeIsland();

		this.dataLoaded = true;
	}

	check() {
		this.postalCodeForm = this.addressDetailsForm.get('postalCode');
		this.nieForm = this.addressDetailsForm.get('nie');

		if (this.postalCodeForm && this.postalCodeForm.touched)
			this.addressPostalCodeValidated.emit(this.postalCodeForm.valid);

		if (this.nieForm && this.nieForm.touched)
			this.addressNieValidated.emit(this.nieForm.valid);

		this.addressValidated.emit(this.addressDetailsForm.valid);
		this.differentAddress();
	}

	sendAddressToParent() {
		this.addressObject.emit(this.addressDetailsForm.value);
	}

	differentAddress() {
		if (this.addressDetailsForm.valid) {
			if (
				this.addressDetailsForm.controls['additionalInfo'].value == null
			) {
				this.addressDetailsForm.controls['additionalInfo'].setValue(
					'vacio'
				);
			}

			this.addressObject.emit(this.addressDetailsForm.value);
		}
	}

	controlAccordion(event?: any) {
		let panel;
		let element;

		// If the form data is being updated, don't do anything
		if (this.updatingData) {
			this.updatingData = false;
			return;
		}

		// Set the target depending if there is an event or not
		if (event) {
			element = event.srcElement;
		} else {
			element = this.address.nativeElement;
		}

		// Desactivate or Activate correct icon
		if (element && element.classList.contains('correct')) {
			element.classList.toggle('correct');
		}

		if (element) {
			panel = element.nextElementSibling;
			element.classList.toggle('active');
		}

		// Display or Hidde accordion content
		this.accordionDisplayToggle(panel);
	}

	accordionDisplayToggle(panel: any) {
		if (panel.style.display === 'block') {
			panel.style.display = 'none';
			if (this.addressDetailsForm.valid) {
				this.setTickIcon();
				panel.style.display = 'none';
			}
		} else {
			panel.style.display = 'block';
		}
	}

	setTickIcon() {
		let element = this.address.nativeElement;

		if (element.classList.contains('active')) {
			element.classList.toggle('active');
		}

		if (element) {
			element.classList.toggle('correct');
		}
	}

	sendPostalCode(event?: any) {
		if (event) {
			let cp = event.srcElement.value;

			if (cp.length >= 3) {
				this.postalCode.emit(cp);
			}
		} else {
			this.postalCode.emit(
				this.addressDetailsForm.controls['postalCode'].value
			);
		}
	}

	getIslands() {
		this.islandService.getIslandCollection$Json().subscribe((response) => {
			this.islandsList = response;
		});
	}

	searchIsland(cpIsland: string): Island {
		let searchIsland: Island = {};

		this.islandsList.forEach((island) => {
			island.cpPrefixes.forEach((subisland) => {
				if (cpIsland == subisland) {
					searchIsland = island;
				}
			});
		});
		return searchIsland;
	}

	changeIsland() {
		this.sendPostalCode();

		if (this.addressDetailsForm.controls['postalCode'].value) {
			let island = this.searchIsland(
				this.addressDetailsForm.controls['postalCode'].value.substring(
					0,
					3
				)
			);

			this.updatingData = true;
			console.log({ island: environment.iriIsland + island.id });
			this.addressDetailsForm.controls['island'].setValue(
				environment.iriIsland + island.id
			);

			this.addressDetailsForm.controls['province'].setValue(
				island.province
			);
		}

		if (this.addressDetailsForm.valid) {
			this.setTickIcon();
		}
	}

	sameDirection(event: any) {
		this.sendAddressToParent();
		this.sameAddress.emit(!event.srcElement.checked);
	}
}
