/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PaymentMethod } from '../models/payment-method';
import { PaymentMethod as PaymentMethodListPaymentMethod } from '../models/PaymentMethod-list/payment-method';
import { PaymentMethod as PaymentMethodReadPaymentMethod } from '../models/PaymentMethod-read/payment-method';
import { PaymentMethod as PaymentMethodWritePaymentMethod } from '../models/PaymentMethod-write/payment-method';
import { Jsonld as PaymentMethodJsonld } from '../models/PaymentMethod/jsonld';
import { PaymentMethod as PaymentMethodJsonldListPaymentMethod } from '../models/PaymentMethod/jsonld-list/payment-method';
import { PaymentMethod as PaymentMethodJsonldReadPaymentMethod } from '../models/PaymentMethod/jsonld-read/payment-method';
import { PaymentMethod as PaymentMethodJsonldWritePaymentMethod } from '../models/PaymentMethod/jsonld-write/payment-method';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPaymentMethodCollection
   */
  static readonly GetPaymentMethodCollectionPath = '/api/payment_methods';

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentMethodCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PaymentMethodListPaymentMethod>>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.GetPaymentMethodCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentMethodListPaymentMethod>>;
      })
    );
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentMethodCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    context?: HttpContext
  }
): Observable<Array<PaymentMethodListPaymentMethod>> {

    return this.getPaymentMethodCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PaymentMethodListPaymentMethod>>) => r.body as Array<PaymentMethodListPaymentMethod>)
    );
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentMethodCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PaymentMethodListPaymentMethod>>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.GetPaymentMethodCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentMethodListPaymentMethod>>;
      })
    );
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentMethodCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    context?: HttpContext
  }
): Observable<Array<PaymentMethodListPaymentMethod>> {

    return this.getPaymentMethodCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PaymentMethodListPaymentMethod>>) => r.body as Array<PaymentMethodListPaymentMethod>)
    );
  }

  /**
   * Path part for operation postPaymentMethodCollection
   */
  static readonly PostPaymentMethodCollectionPath = '/api/payment_methods';

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPaymentMethodCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPaymentMethodCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.PostPaymentMethodCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPaymentMethodCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPaymentMethodCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<PaymentMethod> {

    return this.postPaymentMethodCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPaymentMethodCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPaymentMethodCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.PostPaymentMethodCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPaymentMethodCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPaymentMethodCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<PaymentMethod> {

    return this.postPaymentMethodCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPaymentMethodCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postPaymentMethodCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.PostPaymentMethodCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPaymentMethodCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postPaymentMethodCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<PaymentMethod> {

    return this.postPaymentMethodCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPaymentMethodCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postPaymentMethodCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.PostPaymentMethodCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPaymentMethodCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postPaymentMethodCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<PaymentMethod> {

    return this.postPaymentMethodCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Path part for operation getPaymentMethodItem
   */
  static readonly GetPaymentMethodItemPath = '/api/payment_methods/{id}';

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentMethodItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaymentMethodReadPaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.GetPaymentMethodItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethodReadPaymentMethod>;
      })
    );
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentMethodItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<PaymentMethodReadPaymentMethod> {

    return this.getPaymentMethodItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethodReadPaymentMethod>) => r.body as PaymentMethodReadPaymentMethod)
    );
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentMethodItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaymentMethodReadPaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.GetPaymentMethodItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethodReadPaymentMethod>;
      })
    );
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentMethodItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethodItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<PaymentMethodReadPaymentMethod> {

    return this.getPaymentMethodItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethodReadPaymentMethod>) => r.body as PaymentMethodReadPaymentMethod)
    );
  }

  /**
   * Path part for operation putPaymentMethodItem
   */
  static readonly PutPaymentMethodItemPath = '/api/payment_methods/{id}';

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPaymentMethodItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPaymentMethodItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.PutPaymentMethodItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPaymentMethodItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPaymentMethodItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<PaymentMethod> {

    return this.putPaymentMethodItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPaymentMethodItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPaymentMethodItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.PutPaymentMethodItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPaymentMethodItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPaymentMethodItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<PaymentMethod> {

    return this.putPaymentMethodItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPaymentMethodItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putPaymentMethodItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.PutPaymentMethodItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPaymentMethodItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putPaymentMethodItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<PaymentMethod> {

    return this.putPaymentMethodItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPaymentMethodItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putPaymentMethodItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<StrictHttpResponse<PaymentMethod>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.PutPaymentMethodItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentMethod>;
      })
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPaymentMethodItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putPaymentMethodItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated PaymentMethod resource
     */
    body: PaymentMethodWritePaymentMethod
  }
): Observable<PaymentMethod> {

    return this.putPaymentMethodItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentMethod>) => r.body as PaymentMethod)
    );
  }

  /**
   * Path part for operation deletePaymentMethodItem
   */
  static readonly DeletePaymentMethodItemPath = '/api/payment_methods/{id}';

  /**
   * Removes the PaymentMethod resource.
   *
   * Removes the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePaymentMethodItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaymentMethodItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentMethodService.DeletePaymentMethodItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the PaymentMethod resource.
   *
   * Removes the PaymentMethod resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePaymentMethodItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePaymentMethodItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deletePaymentMethodItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
