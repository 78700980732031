import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
	selector: 'app-header-small',
	templateUrl: './header-small.component.html',
	styleUrls: ['./header-small.component.scss'],
})
export class HeaderSmallComponent implements OnInit {
	url: string;
	@Input() display: boolean = false;

	constructor(private router: Router) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.url = event.url;
				this.visibility();
			}
		});
	}

	ngOnInit() {}

	visibility() {
		let regEx = /^[/]home/;

		if (!regEx.test(this.url)) {
			this.display = true;
		} else {
			this.display = false;
		}
	}
}
