/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderReturn } from '../models/order-return';
import { OrderReturn as OrderReturnListOrderReturn } from '../models/OrderReturn-list/order-return';
import { OrderReturn as OrderReturnReadOrderReturn } from '../models/OrderReturn-read/order-return';
import { OrderReturn as OrderReturnWriteOrderReturn } from '../models/OrderReturn-write/order-return';
import { Jsonld as OrderReturnJsonld } from '../models/OrderReturn/jsonld';
import { OrderReturn as OrderReturnJsonldListOrderReturn } from '../models/OrderReturn/jsonld-list/order-return';
import { OrderReturn as OrderReturnJsonldReadOrderReturn } from '../models/OrderReturn/jsonld-read/order-return';
import { OrderReturn as OrderReturnJsonldWriteOrderReturn } from '../models/OrderReturn/jsonld-write/order-return';

@Injectable({
  providedIn: 'root',
})
export class OrderReturnService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOrderReturnCollection
   */
  static readonly GetOrderReturnCollectionPath = '/api/order_returns';

  /**
   * Retrieves the collection of OrderReturn resources.
   *
   * Retrieves the collection of OrderReturn resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderReturnCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OrderReturnListOrderReturn>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.GetOrderReturnCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderReturnListOrderReturn>>;
      })
    );
  }

  /**
   * Retrieves the collection of OrderReturn resources.
   *
   * Retrieves the collection of OrderReturn resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderReturnCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    context?: HttpContext
  }
): Observable<Array<OrderReturnListOrderReturn>> {

    return this.getOrderReturnCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderReturnListOrderReturn>>) => r.body as Array<OrderReturnListOrderReturn>)
    );
  }

  /**
   * Retrieves the collection of OrderReturn resources.
   *
   * Retrieves the collection of OrderReturn resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderReturnCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OrderReturnListOrderReturn>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.GetOrderReturnCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderReturnListOrderReturn>>;
      })
    );
  }

  /**
   * Retrieves the collection of OrderReturn resources.
   *
   * Retrieves the collection of OrderReturn resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderReturnCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    context?: HttpContext
  }
): Observable<Array<OrderReturnListOrderReturn>> {

    return this.getOrderReturnCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderReturnListOrderReturn>>) => r.body as Array<OrderReturnListOrderReturn>)
    );
  }

  /**
   * Path part for operation postOrderReturnCollection
   */
  static readonly PostOrderReturnCollectionPath = '/api/order_returns';

  /**
   * Creates a OrderReturn resource.
   *
   * Creates a OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderReturnCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderReturnCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.PostOrderReturnCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Creates a OrderReturn resource.
   *
   * Creates a OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderReturnCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderReturnCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<OrderReturn> {

    return this.postOrderReturnCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Creates a OrderReturn resource.
   *
   * Creates a OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderReturnCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderReturnCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.PostOrderReturnCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Creates a OrderReturn resource.
   *
   * Creates a OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderReturnCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderReturnCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<OrderReturn> {

    return this.postOrderReturnCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Creates a OrderReturn resource.
   *
   * Creates a OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderReturnCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderReturnCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.PostOrderReturnCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Creates a OrderReturn resource.
   *
   * Creates a OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderReturnCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderReturnCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<OrderReturn> {

    return this.postOrderReturnCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Creates a OrderReturn resource.
   *
   * Creates a OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderReturnCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderReturnCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.PostOrderReturnCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Creates a OrderReturn resource.
   *
   * Creates a OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderReturnCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderReturnCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<OrderReturn> {

    return this.postOrderReturnCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Path part for operation getOrderReturnItem
   */
  static readonly GetOrderReturnItemPath = '/api/order_returns/{id}';

  /**
   * Retrieves a OrderReturn resource.
   *
   * Retrieves a OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderReturnItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OrderReturnReadOrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.GetOrderReturnItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturnReadOrderReturn>;
      })
    );
  }

  /**
   * Retrieves a OrderReturn resource.
   *
   * Retrieves a OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderReturnItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<OrderReturnReadOrderReturn> {

    return this.getOrderReturnItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturnReadOrderReturn>) => r.body as OrderReturnReadOrderReturn)
    );
  }

  /**
   * Retrieves a OrderReturn resource.
   *
   * Retrieves a OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderReturnItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OrderReturnReadOrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.GetOrderReturnItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturnReadOrderReturn>;
      })
    );
  }

  /**
   * Retrieves a OrderReturn resource.
   *
   * Retrieves a OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderReturnItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderReturnItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<OrderReturnReadOrderReturn> {

    return this.getOrderReturnItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturnReadOrderReturn>) => r.body as OrderReturnReadOrderReturn)
    );
  }

  /**
   * Path part for operation putOrderReturnItem
   */
  static readonly PutOrderReturnItemPath = '/api/order_returns/{id}';

  /**
   * Replaces the OrderReturn resource.
   *
   * Replaces the OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderReturnItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderReturnItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.PutOrderReturnItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Replaces the OrderReturn resource.
   *
   * Replaces the OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderReturnItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderReturnItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<OrderReturn> {

    return this.putOrderReturnItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Replaces the OrderReturn resource.
   *
   * Replaces the OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderReturnItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderReturnItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.PutOrderReturnItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Replaces the OrderReturn resource.
   *
   * Replaces the OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderReturnItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderReturnItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<OrderReturn> {

    return this.putOrderReturnItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Replaces the OrderReturn resource.
   *
   * Replaces the OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderReturnItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderReturnItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.PutOrderReturnItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Replaces the OrderReturn resource.
   *
   * Replaces the OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderReturnItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderReturnItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<OrderReturn> {

    return this.putOrderReturnItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Replaces the OrderReturn resource.
   *
   * Replaces the OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderReturnItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderReturnItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<StrictHttpResponse<OrderReturn>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.PutOrderReturnItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderReturn>;
      })
    );
  }

  /**
   * Replaces the OrderReturn resource.
   *
   * Replaces the OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderReturnItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderReturnItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderReturn resource
     */
    body: OrderReturnWriteOrderReturn
  }
): Observable<OrderReturn> {

    return this.putOrderReturnItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderReturn>) => r.body as OrderReturn)
    );
  }

  /**
   * Path part for operation deleteOrderReturnItem
   */
  static readonly DeleteOrderReturnItemPath = '/api/order_returns/{id}';

  /**
   * Removes the OrderReturn resource.
   *
   * Removes the OrderReturn resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrderReturnItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderReturnItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderReturnService.DeleteOrderReturnItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the OrderReturn resource.
   *
   * Removes the OrderReturn resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteOrderReturnItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderReturnItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteOrderReturnItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
