import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: string): string {
    let localDate;
    localDate = new Date(date);
    return localDate.toLocaleDateString();
  }

}
