/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Island } from '../models/island';
import { Island as IslandListIsland } from '../models/Island-list/island';
import { Island as IslandReadIsland } from '../models/Island-read/island';
import { Island as IslandWriteIsland } from '../models/Island-write/island';
import { Jsonld as IslandJsonld } from '../models/Island/jsonld';
import { Island as IslandJsonldListIsland } from '../models/Island/jsonld-list/island';
import { Island as IslandJsonldReadIsland } from '../models/Island/jsonld-read/island';
import { Island as IslandJsonldWriteIsland } from '../models/Island/jsonld-write/island';

@Injectable({
  providedIn: 'root',
})
export class IslandService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getIslandCollection
   */
  static readonly GetIslandCollectionPath = '/api/islands';

  /**
   * Retrieves the collection of Island resources.
   *
   * Retrieves the collection of Island resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIslandCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIslandCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    cpPrefixes?: string;
    planeExtraCost?: number;
    boatExtraCost?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<IslandListIsland>>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.GetIslandCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('cpPrefixes', params.cpPrefixes, {"style":"form","explode":false});
      rb.query('planeExtraCost', params.planeExtraCost, {"style":"form","explode":false});
      rb.query('boatExtraCost', params.boatExtraCost, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IslandListIsland>>;
      })
    );
  }

  /**
   * Retrieves the collection of Island resources.
   *
   * Retrieves the collection of Island resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIslandCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIslandCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    cpPrefixes?: string;
    planeExtraCost?: number;
    boatExtraCost?: number;
    context?: HttpContext
  }
): Observable<Array<IslandListIsland>> {

    return this.getIslandCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<IslandListIsland>>) => r.body as Array<IslandListIsland>)
    );
  }

  /**
   * Retrieves the collection of Island resources.
   *
   * Retrieves the collection of Island resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIslandCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIslandCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    cpPrefixes?: string;
    planeExtraCost?: number;
    boatExtraCost?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<IslandListIsland>>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.GetIslandCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('cpPrefixes', params.cpPrefixes, {"style":"form","explode":false});
      rb.query('planeExtraCost', params.planeExtraCost, {"style":"form","explode":false});
      rb.query('boatExtraCost', params.boatExtraCost, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IslandListIsland>>;
      })
    );
  }

  /**
   * Retrieves the collection of Island resources.
   *
   * Retrieves the collection of Island resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIslandCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIslandCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    cpPrefixes?: string;
    planeExtraCost?: number;
    boatExtraCost?: number;
    context?: HttpContext
  }
): Observable<Array<IslandListIsland>> {

    return this.getIslandCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<IslandListIsland>>) => r.body as Array<IslandListIsland>)
    );
  }

  /**
   * Path part for operation postIslandCollection
   */
  static readonly PostIslandCollectionPath = '/api/islands';

  /**
   * Creates a Island resource.
   *
   * Creates a Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postIslandCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postIslandCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Island resource
     */
    body: IslandWriteIsland
  }
): Observable<StrictHttpResponse<Island>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.PostIslandCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Island>;
      })
    );
  }

  /**
   * Creates a Island resource.
   *
   * Creates a Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postIslandCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postIslandCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Island resource
     */
    body: IslandWriteIsland
  }
): Observable<Island> {

    return this.postIslandCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Island>) => r.body as Island)
    );
  }

  /**
   * Creates a Island resource.
   *
   * Creates a Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postIslandCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postIslandCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Island resource
     */
    body: IslandWriteIsland
  }
): Observable<StrictHttpResponse<Island>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.PostIslandCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Island>;
      })
    );
  }

  /**
   * Creates a Island resource.
   *
   * Creates a Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postIslandCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postIslandCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Island resource
     */
    body: IslandWriteIsland
  }
): Observable<Island> {

    return this.postIslandCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Island>) => r.body as Island)
    );
  }

  /**
   * Creates a Island resource.
   *
   * Creates a Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postIslandCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postIslandCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Island resource
     */
    body: IslandWriteIsland
  }
): Observable<StrictHttpResponse<Island>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.PostIslandCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Island>;
      })
    );
  }

  /**
   * Creates a Island resource.
   *
   * Creates a Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postIslandCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postIslandCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Island resource
     */
    body: IslandWriteIsland
  }
): Observable<Island> {

    return this.postIslandCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Island>) => r.body as Island)
    );
  }

  /**
   * Creates a Island resource.
   *
   * Creates a Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postIslandCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postIslandCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Island resource
     */
    body: IslandWriteIsland
  }
): Observable<StrictHttpResponse<Island>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.PostIslandCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Island>;
      })
    );
  }

  /**
   * Creates a Island resource.
   *
   * Creates a Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postIslandCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postIslandCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Island resource
     */
    body: IslandWriteIsland
  }
): Observable<Island> {

    return this.postIslandCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Island>) => r.body as Island)
    );
  }

  /**
   * Path part for operation getIslandItem
   */
  static readonly GetIslandItemPath = '/api/islands/{id}';

  /**
   * Retrieves a Island resource.
   *
   * Retrieves a Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIslandItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIslandItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IslandReadIsland>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.GetIslandItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IslandReadIsland>;
      })
    );
  }

  /**
   * Retrieves a Island resource.
   *
   * Retrieves a Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIslandItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIslandItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<IslandReadIsland> {

    return this.getIslandItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<IslandReadIsland>) => r.body as IslandReadIsland)
    );
  }

  /**
   * Retrieves a Island resource.
   *
   * Retrieves a Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIslandItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIslandItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IslandReadIsland>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.GetIslandItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IslandReadIsland>;
      })
    );
  }

  /**
   * Retrieves a Island resource.
   *
   * Retrieves a Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIslandItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIslandItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<IslandReadIsland> {

    return this.getIslandItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<IslandReadIsland>) => r.body as IslandReadIsland)
    );
  }

  /**
   * Path part for operation putIslandItem
   */
  static readonly PutIslandItemPath = '/api/islands/{id}';

  /**
   * Replaces the Island resource.
   *
   * Replaces the Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putIslandItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putIslandItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Island resource
     */
    body: IslandWriteIsland
  }
): Observable<StrictHttpResponse<Island>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.PutIslandItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Island>;
      })
    );
  }

  /**
   * Replaces the Island resource.
   *
   * Replaces the Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putIslandItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putIslandItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Island resource
     */
    body: IslandWriteIsland
  }
): Observable<Island> {

    return this.putIslandItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Island>) => r.body as Island)
    );
  }

  /**
   * Replaces the Island resource.
   *
   * Replaces the Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putIslandItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putIslandItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Island resource
     */
    body: IslandWriteIsland
  }
): Observable<StrictHttpResponse<Island>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.PutIslandItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Island>;
      })
    );
  }

  /**
   * Replaces the Island resource.
   *
   * Replaces the Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putIslandItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putIslandItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Island resource
     */
    body: IslandWriteIsland
  }
): Observable<Island> {

    return this.putIslandItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Island>) => r.body as Island)
    );
  }

  /**
   * Replaces the Island resource.
   *
   * Replaces the Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putIslandItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putIslandItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Island resource
     */
    body: IslandWriteIsland
  }
): Observable<StrictHttpResponse<Island>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.PutIslandItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Island>;
      })
    );
  }

  /**
   * Replaces the Island resource.
   *
   * Replaces the Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putIslandItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putIslandItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Island resource
     */
    body: IslandWriteIsland
  }
): Observable<Island> {

    return this.putIslandItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Island>) => r.body as Island)
    );
  }

  /**
   * Replaces the Island resource.
   *
   * Replaces the Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putIslandItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putIslandItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Island resource
     */
    body: IslandWriteIsland
  }
): Observable<StrictHttpResponse<Island>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.PutIslandItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Island>;
      })
    );
  }

  /**
   * Replaces the Island resource.
   *
   * Replaces the Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putIslandItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putIslandItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Island resource
     */
    body: IslandWriteIsland
  }
): Observable<Island> {

    return this.putIslandItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Island>) => r.body as Island)
    );
  }

  /**
   * Path part for operation deleteIslandItem
   */
  static readonly DeleteIslandItemPath = '/api/islands/{id}';

  /**
   * Removes the Island resource.
   *
   * Removes the Island resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteIslandItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteIslandItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IslandService.DeleteIslandItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Island resource.
   *
   * Removes the Island resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteIslandItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteIslandItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteIslandItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
