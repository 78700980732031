import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
	BannerReadBanner,
	MarketPlaceReadMarketPlace,
} from 'src/app/api/models';
import { BannerService, MarketPlaceService } from 'src/app/api/services';
import SwiperCore, { Pagination, Autoplay } from 'swiper';

SwiperCore.use([Pagination, Autoplay]);

@Component({
	selector: 'app-slider-home',
	templateUrl: './slider-home.component.html',
	styleUrls: ['./slider-home.component.scss'],
})
export class SliderHomeComponent implements OnInit {
	private sliderMarketplaces: Array<MarketPlaceReadMarketPlace>;
	private sliderBanner: Array<BannerReadBanner>;

	constructor(
		private serviceMarketplace: MarketPlaceService,
		private serviceBanner: BannerService
	) {
		this.getLogos();
		this.getBanners();
	}

	ngOnInit() {}

	getLogos() {
		let param = {
			isInHome: true,
		};

		this.serviceMarketplace
			.getMarketPlaceCollection$Json(param)
			.subscribe((response) => {
				this.sliderMarketplaces = response;
				console.log(response);
			});
	}

	getBanners() {
		let param = {
			isInHome: true,
		};

		this.serviceBanner
			.getBannerCollection$Json(param)
			.subscribe((response) => {
				this.sliderBanner = response;
				console.log(response);
			});
	}

	haveImage(mediaObject) {
		if (!mediaObject) {
			return false;
		}

		if (mediaObject.hasOwnProperty('contentUrl')) {
			return true;
		}

		return false;
	}
}
