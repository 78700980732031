// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	customPurchase: '26',
	iriIsland: '/api/islands/',
	cmsCategoryID: 15,
	socialLoginURL: 'https://api.dev.syniva.es/social-login',
	cmsBannerID: '34',

	mediaUrl: 'https://api.dev.syniva.es',
	apiResetPassword: 'https://api.dev.syniva.es/reset-password',
	apiUnknownImage:
		'https://api.dev.syniva.es/images/media_objects/unknow.jpg',

	middleEndUrl: 'https://middle-end.dev.syniva.es',
	middleEndCart: 'https://middle-end.dev.syniva.es/cart',
	middleEndCustomPurchase: 'https://middle-end.dev.syniva.es/cart/custom',

	cookieDomain: '.syniva.es',

	firebase: {
		apiKey: 'AIzaSyDGs5-8XlkTb18kkQa8aC6ARgVErY34CTc',
		authDomain: 'syniva-4a581.firebaseapp.com',
		projectId: 'syniva-4a581',
		storageBucket: 'syniva-4a581.appspot.com',
		messagingSenderId: '776800487568',
		appId: '1:776800487568:web:217334ec2e251b907c7015',
		measurementId: 'G-VSBXBLMWD9',
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
