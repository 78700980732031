import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MarketPlaceReadMarketPlace } from 'src/app/api/models';
import { MarketPlaceService } from 'src/app/api/services';
import { NavControllerService } from '../../services/nav-controller.service';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-search-tab',
	templateUrl: './search-tab.component.html',
	styleUrls: ['./search-tab.component.scss'],
})
export class SearchTabComponent implements OnInit {
	// FIXME: navController history

	public marketplacesSearchList: Array<MarketPlaceReadMarketPlace> = [];
	public results: number = 0;

	public searchField: FormControl;

	constructor(
		private marketplaceService: MarketPlaceService,
		private navService: NavControllerService,
		private navController: NavController
	) {
		this.searchField = new FormControl('');
	}

	ngOnInit() {
		this.setSearchListener();
	}

	setSearchListener() {
		this.searchField.valueChanges.subscribe((value) => {
			if (value != '') {
				value = value.toUpperCase();
				if (!this.navService.historial.includes(value)) {
					this.navService.historial.push(value);
				}
				this.search(value);
			}
			if (value == '') {
				this.marketplacesSearchList = [];
				this.results = 0;
				return;
			}
		});
	}

	search(value: string) {
		this.marketplacesSearchList = [];
		let param = {
			tagNameSearch: value
		};
		this.marketplaceService
		// aparte de name tags y pasarle el mismo value
			.getMarketPlaceCollection$Json(param)
			.subscribe((response) => {
				let ordenedResponse = response.filter(market => {
					return market.name.toUpperCase().search(value) !== -1 ;
				});
				
				this.marketplacesSearchList = [...ordenedResponse, ...response.filter(x => ordenedResponse.indexOf(x) === -1)];
				this.results = this.marketplacesSearchList.length;
			});
	}

	searchAgain(search: string) {
		this.searchField.setValue(search);
		this.search(search);
	}

	anyMarketFounded() {
		console.log(!this.marketplacesSearchList);
		return !this.marketplacesSearchList;
	}

	clearHistorial() {
		this.navService.historial = [];
	}

	goToAllCategories() {
		this.navController.navigateRoot(['/category']);
	}
}
