import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressReadAddress } from 'src/app/api/models';

@Component({
  selector: 'app-display-shipping-address',
  templateUrl: './display-shipping-address.component.html',
  styleUrls: ['./display-shipping-address.component.scss'],
})
export class DisplayShippingAddressComponent implements OnInit {

  @ViewChild('address') address: any

  @Input() displayRegister: boolean = false;
  @Input() shippingAddress: any;

  public addressDetails: FormGroup = this.formBuilder.group({
    email: ['', Validators.required],
    name: ['', Validators.required],
    surnames: ['', Validators.required],
    street: ['', Validators.required],
    number: ['', Validators.required],
    door: [''],
    floor: [''],
    island: ['', Validators.required],
    city: ['', Validators.required],
    postalCode: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    nie: ['', Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder
    ) {

  }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    if (this.shippingAddress != undefined) {
      this.loadAddressDetails(this.shippingAddress);
    }
  }


  loadAddressDetails(addressDetails: any| undefined) {
    this.addressDetails.controls['email'].setValue(addressDetails?.email);
    this.addressDetails.controls['name'].setValue(addressDetails?.name);
    this.addressDetails.controls['surnames'].setValue(addressDetails?.surnames);
    this.addressDetails.controls['street'].setValue(addressDetails?.street);
    this.addressDetails.controls['number'].setValue(addressDetails?.number);
    this.addressDetails.controls['door'].setValue(addressDetails?.door);
    this.addressDetails.controls['floor'].setValue(addressDetails?.floor);
    this.addressDetails.controls['island'].setValue(addressDetails?.island?.name);
    this.addressDetails.controls['city'].setValue(addressDetails?.city);
    this.addressDetails.controls['postalCode'].setValue(addressDetails?.postalCode);
    this.addressDetails.controls['phoneNumber'].setValue(addressDetails?.phoneNumber);
    this.addressDetails.controls['nie'].setValue(addressDetails?.nie);
  }

  controlAccordion(){
    let panel;
    let element;

    element = this.address.nativeElement;

    if (element) {
      panel = element.nextElementSibling;
      element.classList.toggle("active");
    }

    // Display or Hidde accordion content
    this.accordionDisplayToggle(panel);
  }

  accordionDisplayToggle(panel: any) {
    if (panel.style.display === "block") {
      panel.style.display = "none";
        panel.style.display = "none";
      
    } else {
      panel.style.display = "block";
    }
  }

  setDefaultIsland(id: string) {
    let iri = id.split('/');
    let defaultId = iri[iri.length-1];

    //this.idIsland = defaultId;
  }


}
