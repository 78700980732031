import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { CmsService } from 'src/app/api/services';

@Component({
	selector: 'app-custom-purchase-popover',
	templateUrl: './custom-purchase-popover.component.html',
	styleUrls: ['./custom-purchase-popover.component.scss'],
})
export class CustomPurchasePopoverComponent implements OnInit {

	@Input() message: any;

	constructor(
		private popoverCtrl: PopoverController,
		private cmsService: CmsService) {}

	ngOnInit() {
		this.cmsService.getCmsItem$Json({id: environment.customPurchase}).subscribe((res) => {
			this.message = res
		})
	}

	goToCustomPurchase() {
		window.location.href = environment.middleEndCustomPurchase;
	}

	close() {
		this.popoverCtrl.getTop().then((pop) => pop.dismiss());
	}
}
