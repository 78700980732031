import { Injectable } from '@angular/core';
import {
	InAppBrowser,
	InAppBrowserOptions,
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalController, PopoverController } from '@ionic/angular';
import { RegisterComponent } from '../authentication/register/register.component';
import { MenuTabComponent } from '../nav-tabs/menu-tab/menu-tab.component';
import { SearchTabComponent } from '../nav-tabs/search-tab/search-tab.component';
import { UserTabComponent } from '../nav-tabs/user-tab/user-tab.component';
import { CategoryComponent } from '../pages/category/category.component';
import { HelpComponent } from '../pages/help/help.component';
import { ChildCategoryComponent } from '../pages/help/child-category/child-category.component';
import { OrderOptionsComponent } from '../pages/orders-list/order-options/order-options.component';
import { OrderResumeComponent } from '../pages/orders-list/order-options/order-resume/order-resume.component';
import { PaymentsComponent } from '../pages/payments/payments.component';
import { UserDataComponent } from '../pages/user-data/user-data.component';
import { ShipmentsStatusComponent } from '../pages/orders-list/shipments-status.component';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NavControllerService {
	public navTabs: Map<string, any> = new Map<string, boolean>([
		['cart', false],
		['user', false],
		['menu', false],
		['search', false],
		['category', false],
		['user-data', false],
		['shipment-status', false],
		['payments', false],
		['help', false],
		['info', false],
		['child-category', false],
		['fqa', false],
		['shipping', false],
		['pay', false],
		['company', false],
		['settings', false],
		['order-options', false],
		['order-details', false],
		['register', false],
	]);

	public currentTabs = [];

	//Búsquedas recientes
	public historial = [];

	constructor(
		private modalCtrl: ModalController,
		private popoverCtrl: PopoverController,
		private iab: InAppBrowser
	) {}

	openMenu() {
		this.modalCtrl
			.create({
				component: MenuTabComponent,
				id: 'menu',
				animated: false,
			})
			.then((modal) => {
				this.setOpen('menu', modal);
				modal.present();
			});
	}

	openSearch() {
		this.modalCtrl
			.create({
				component: SearchTabComponent,
				id: 'search',
				animated: false,
			})
			.then((modal) => {
				this.setOpen('search', modal);
				modal.present();
			});
	}

	openUserProfile() {
		this.modalCtrl
			.create({
				component: UserTabComponent,
				id: 'user',
				animated: false,
			})
			.then((modal) => {
				this.setOpen('user', modal);
				modal.present();
			});
	}

	openChildCategory(parentName: string) {
		this.modalCtrl
			.create({
				component: ChildCategoryComponent,
				id: 'child-category',
				animated: false,
				componentProps: {
					categoryName: parentName,
				},
			})
			.then((modal) => {
				this.setOpen('child-category', modal);
				modal.present();
			});
	}

	openCart() {
		let options: InAppBrowserOptions = {
			location: 'no',
			hidenavigationbuttons: 'yes',
			hideurlbar: 'yes',
			zoom: 'no',
		};

		this.iab.create(environment.middleEndCart, '_blank', options);
	}

	openSubcategory(id: string | number, name: string) {
		this.popoverCtrl.getTop().then((popover) => {
			if (popover) {
				this.popoverCtrl.dismiss();
			}
		});

		if (typeof id === 'number') {
			id = id.toString();
		}

		this.modalCtrl
			.create({
				component: CategoryComponent,
				id: 'category',
				animated: false,
				componentProps: {
					parentId: id,
					categoryName: name,
				},
			})
			.then((modal) => {
				this.setOpen('category', modal);
				modal.present();
			});
	}

	openUserData() {
		this.modalCtrl
			.create({
				component: UserDataComponent,
				id: 'user-data',
				animated: false,
			})
			.then((modal) => {
				this.setOpen('user-data', modal);
				modal.present();
			});
	}

	openShipmentsStatus() {
		this.modalCtrl
			.create({
				component: ShipmentsStatusComponent,
				id: 'shipment-status',
				animated: false,
			})
			.then((modal) => {
				this.setOpen('shipment-status', modal);
				modal.present();
			});
	}

	openOrderOptions(orderId: string) {
		this.modalCtrl
			.create({
				component: OrderOptionsComponent,
				id: 'order-options',
				animated: false,
				componentProps: {
					orderId: orderId,
				},
			})
			.then((modal) => {
				this.setOpen('order-options', modal);
				modal.present();
			});
	}

	openOrderDetails(order: any) {
		this.modalCtrl
			.create({
				component: OrderResumeComponent,
				id: 'order-details',
				animated: false,
				componentProps: {
					order: order,
				},
			})
			.then((modal) => {
				this.setOpen('order-details', modal);
				modal.present();
			});
	}

	openRegister() {
		this.modalCtrl
			.create({
				component: RegisterComponent,
				id: 'register',
				animated: false,
			})
			.then((modal) => {
				this.setOpen('register', modal);
				modal.present();
			});
	}

	openPayments() {
		this.modalCtrl
			.create({
				component: PaymentsComponent,
				id: 'shipment-status',
				animated: false,
			})
			.then((modal) => {
				this.setOpen('payments', modal);
				modal.present();
			});
	}

	openHelp() {
		this.modalCtrl
			.create({
				component: HelpComponent,
				id: 'help',
				animated: false,
			})
			.then((modal) => {
				this.setOpen('help', modal);
				modal.present();
			});
	}

	setOpen(id: string, modal: any) {
		this.navTabs.set(id, modal);
	}

	setClose(id: string) {
		this.navTabs.set(id, false);
	}

	anyModalIsOpen() {
		let isOpen;
		this.navTabs.forEach((value: boolean, key: string) => {
			if (value) {
				isOpen = true;
			}
		});
		return isOpen;
	}

	isAlreadyOpen(id: string) {
		return this.navTabs.get(id);
	}

	imAlreadyOpen(id: string) {
		return this.navTabs.get(id);
	}

	closeModal(id: string) {
		this.navTabs.forEach((value: any, key: string) => {
			if (value && key == id) {
				this.setClose(key);
				value.dismiss();
			}
		});
	}

	closeAllModals() {
		this.navTabs.forEach((value: any, key: string) => {
			if (value) {
				this.setClose(key);
				value.dismiss();
			}
		});
	}

	resetBooleans() {
		this.navTabs.forEach((value: boolean, key: string) => {
			this.navTabs.set(key, false);
		});
	}
}
