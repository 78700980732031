import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'src/app/api/models';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-order-detail',
	templateUrl: './order-detail.component.html',
	styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
	@Input() order: Order;

	public orderName: string = '';
	public orderLogo: string = '';
	public marketplaceName: string = '';

	constructor(private navController: NavController) {}

	ngOnInit() {
		this.getMarketplaceName();
	}

	getMarketplaceName() {
		this.order.marketPlaces.forEach((market: any) => {
			this.marketplaceName += market.name.toUpperCase() + ' ';
		});
	}

	openDetails() {
		this.navController.navigateRoot([
			'/order-options',
			{ orderId: this.order.id },
		]);
	}
}
