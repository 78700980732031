import { NgModule, APP_INITIALIZER, Provider, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ApiConfiguration } from '../app/api/api-configuration';
import { environment } from '../environments/environment';

import {
	IonicModule,
	IonicRouteStrategy,
	IonInfiniteScroll,
} from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/globalComponents/header/header.component';
import { HomeComponent } from './components/pages/home/home.component';
import { SwiperModule } from 'swiper/angular';
import { LoginComponent } from './components/authentication/login/login.component';
import { AuthInterceptorService } from './components/authentication/interceptor/auth-interceptor.service';
import { RegisterComponent } from './components/authentication/register/register.component';
import { UserTabComponent } from './components/nav-tabs/user-tab/user-tab.component';
import { SearchTabComponent } from './components/nav-tabs/search-tab/search-tab.component';
import { MenuTabComponent } from './components/nav-tabs/menu-tab/menu-tab.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { SliderHomeComponent } from './components/slider-home/slider-home.component';
import { MarketplaceCardComponent } from './components/globalComponents/marketplace-card/marketplace-card.component';
import { CartTabComponent } from './components/nav-tabs/cart-tab/cart-tab.component';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { CartComponent } from './components/pages/purchaseProcess/cart/cart.component';
import { UserMenuComponent } from './components/pages/user-menu/user-menu.component';
import { UserDataComponent } from './components/pages/user-data/user-data.component';
import { PaymentsComponent } from './components/pages/payments/payments.component';
import { HelpComponent } from './components/pages/help/help.component';
import { OrderCompletedComponent } from './components/pages/purchaseProcess/order-completed/order-completed.component';
import { PaymentComponent } from './components/pages/purchaseProcess/payment/payment.component';
import { CustomPruchaseComponent } from './components/pages/custom-pruchase/custom-pruchase.component';
import { CommonModule } from '@angular/common';
import { MediaObjectPipe } from './components/pipes/media-object.pipe';
import { OrderStatusPipe } from './components/pipes/order-status.pipe';
import { ScrollbarThemeDirective } from './components/directives/scrollbar-theme.directive';
import { HeaderSmallComponent } from './components/globalComponents/header-small/header-small.component';
import { ChildCategoryComponent } from './components/pages/help/child-category/child-category.component';
import { CustomPurchaseButtonComponent } from './components/globalComponents/custom-purchase-button/custom-purchase-button.component';
import { CategorySubmenuComponent } from './components/nav-tabs/menu-tab/category-submenu/category-submenu.component';
import { AddressDetailsComponent } from './components/globalComponents/accordions/address-details/address-details.component';
import { PaymentDetailsComponent } from './components/globalComponents/accordions/payment-details/payment-details.component';
import { BillingDetailsComponent } from './components/globalComponents/accordions/billing-details/billing-details.component';
import { OrderResumeComponent } from './components/pages/orders-list/order-options/order-resume/order-resume.component';
import { DateFormatPipe } from './components/pipes/date-format.pipe';
import { FooterComponent } from './components/globalComponents/footer/footer.component';
import { ShipmentsStatusComponent } from './components/pages/orders-list/shipments-status.component';
import { OrderDetailComponent } from './components/pages/orders-list/order-details/order-detail.component';
import { OrderOptionsComponent } from './components/pages/orders-list/order-options/order-options.component';
import { ChangePswdComponent } from './components/pages/user-data/change-pswd/change-pswd.component';
import { RecoverPswdComponent } from './components/pages/recover-pswd/recover-pswd.component';
import { DisplayBillingAddressComponent } from './components/pages/orders-list/order-options/order-resume/display-billing-address/display-billing-address.component';
import { DisplayShippingAddressComponent } from './components/pages/orders-list/order-options/order-resume/display-shipping-address/display-shipping-address.component';
import { OrderPaymentComponent } from './components/pages/orders-list/order-options/order-resume/order-payment/order-payment.component';
import { ItemCardComponent } from './components/pages/orders-list/order-options/order-resume/item-card/item-card.component';
import { ConfirmationPopoverComponent } from './components/popovers/confirmation-popover/confirmation-popover.component';
import { GeneralInfoComponent } from './components/pages/help/general-info/general-info.component';
import { CookieService } from 'ngx-cookie-service';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LoginButtonFacebookComponent } from './components/authentication/login/login-button-facebook/login-button-facebook.component';
import { LoginButtonGoogleComponent } from './components/authentication/login/login-button-google/login-button-google.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import {CookieBannerComponent} from './components/globalComponents/cookieBanner/cookieBanner.component';

registerLocaleData(localeEs);

/*** SWAGGER GEN ***/
export function initApiConfiguration(config: ApiConfiguration): Function {
	return () => {
		config.rootUrl = environment.mediaUrl;
	};
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const INIT_API_CONFIGURATION: Provider = {
	provide: APP_INITIALIZER,
	useFactory: initApiConfiguration,
	deps: [ApiConfiguration],
	multi: true,
};

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		HomeComponent,
		MarketplaceCardComponent,
		LoginComponent,
		RegisterComponent,
		UserTabComponent,
		SearchTabComponent,
		CategoryComponent,
		SliderHomeComponent,
		CartTabComponent,
		CategorySubmenuComponent,
		CartComponent,
		PaymentComponent,
		OrderCompletedComponent,
		UserMenuComponent,
		UserDataComponent,
		PaymentsComponent,
		HelpComponent,
		CustomPruchaseComponent,
		MediaObjectPipe,
		OrderStatusPipe,
		ScrollbarThemeDirective,
		HeaderSmallComponent,
		ChildCategoryComponent,
		CustomPurchaseButtonComponent,
		MenuTabComponent,
		AddressDetailsComponent,
		PaymentDetailsComponent,
		OrderResumeComponent,
		DateFormatPipe,
		FooterComponent,
		OrderDetailComponent,
		OrderOptionsComponent,
		BillingDetailsComponent,
		ShipmentsStatusComponent,
		ChangePswdComponent,
		RecoverPswdComponent,
		DisplayBillingAddressComponent,
		DisplayShippingAddressComponent,
		ItemCardComponent,
		OrderPaymentComponent,
		ConfirmationPopoverComponent,
		GeneralInfoComponent,
		LoginButtonFacebookComponent,
		LoginButtonGoogleComponent,
		CookieBannerComponent
	],
	entryComponents: [],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		FormsModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		SwiperModule,
		CommonModule,
		AngularFireModule.initializeApp(environment.firebase),
    	AngularFireAuthModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
	],
	exports: [ScrollbarThemeDirective],
	providers: [
		INIT_API_CONFIGURATION,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: LOCALE_ID,
			useValue: 'es-ES',
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		InAppBrowser,
		CookieService,
	],

	bootstrap: [AppComponent],

	
})


export class AppModule {}
