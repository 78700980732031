import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Token } from 'src/app/api/models';
import { CookieService } from 'ngx-cookie-service';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(
		private cookieService: CookieService,
		private navController: NavController
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (req.url.endsWith('/authentication_token')) {
			req = req.clone({
				withCredentials: true,
			});
		}

		let token: Token;

		if (this.cookieService.get('BEARER_TOKEN')) {
			token = JSON.parse(
				`{"token": "${this.cookieService.get('BEARER_TOKEN')}"}`
			);
		}

		let request = req;

		if (token) {
			request = req.clone({
				setHeaders: {
					authorization: `Bearer ${token.token}`,
				},
			});
		}

		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (
					error.status === 401 &&
					this.cookieService.get('BEARER_TOKEN')
				) {
					this.cookieService.delete(
						'BEARER_TOKEN',
						'/',
						environment.cookieDomain
					);
					this.navController.navigateRoot(['/login']);
				}

				return throwError(error);
			})
		);
	}
}
