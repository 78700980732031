import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import {
	MarketPlaceCategory,
	MarketPlaceCategoryListMarketPlaceCategoryListParent,
} from 'src/app/api/models';
import { MarketPlaceCategoryService } from 'src/app/api/services';
import { CategorySubmenuComponent } from './category-submenu/category-submenu.component';
import { Location } from '@angular/common';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-menu-tab',
	templateUrl: './menu-tab.component.html',
	styleUrls: ['./menu-tab.component.scss'],
})
export class MenuTabComponent implements OnInit {
	@Input() selectdId: string;
	public values: string = '';
	public categorySelected: string = '13';
	public categoriesList: Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>;
	public subcategoriesList: Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>;
	private popOverOpen: boolean;

	constructor(
		private categoryService: MarketPlaceCategoryService,
		private navController: NavController,
		private modalCtrl: ModalController,
		private popoverCtrl: PopoverController,
		private location: Location
	) {}

	ngOnInit() {
		this.getMainCategories();
	}

	getMainCategories() {
		let param = {
			'exists[parent]': false,
		};

		this.categoryService
			.getMarketPlaceCategoryCollection$Json(param)
			.subscribe((response) => {
				this.categoriesList = response;
			});
	}

	openHelp() {
		this.navController.navigateRoot(['/help']);
	}

	openSearch() {
		this.navController.navigateRoot(['/search']);
	}

	loadSubCategory(
		event: PointerEvent,
		id: string,
		name: string
	): MarketPlaceCategory {
		let param = {
			parent: id,
		};

		this.subcategoriesList = [];
		let collection;
		this.categoryService
			.getMarketPlaceCategoryCollection$Json(param)
			.subscribe((response) => {
				this.createPopover(id, response, event, name);
			});
		return collection;
	}

	linkRoute(id: string) {
		this.modalCtrl.dismiss();
		this.navController.navigateRoot(['/category', id]);
	}

	marketplaces(id: string) {
		this.modalCtrl.dismiss();
		this.navController.navigateRoot(['/category', id]);
	}

	dismis() {
		this.subcategoriesList = [];
	}

	createPopover(
		id,
		data: Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>,
		event,
		name
	) {
		if (this.popOverOpen) {
			return false;
		} else {
			this.popOverOpen = true;
		}

		let positionTarget = event.target.getBoundingClientRect();
		console.log(positionTarget);

		this.popoverCtrl
			.create({
				component: CategorySubmenuComponent,
				arrow: false,
				side: 'right',
				componentProps: {
					categories: data,
					parentId: id,
					categoryName: name,
				},
				cssClass: 'categoryPopup',
			})
			.then((popover) => {
				popover.style.zIndex = '0';
				popover.present().then(() => {
					let content =
						popover.shadowRoot.querySelector('.popover-content');
					content.setAttribute(
						'style',
						`top: ${positionTarget.top}px; right: ${
							window.screen.width / 10
						}px`
					);
					popover.style.zIndex = '2000002';
				});

				popover.onDidDismiss().then(() => {
					this.popOverOpen = false;
				});
			});
	}

	goback() {
		this.location.back();
	}

	goToCustomPurchase() {
		this.navController.navigateRoot(['/custom-purchase']);
	}
}
