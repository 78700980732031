/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Supplement } from '../models/supplement';
import { Supplement as SupplementListSupplement } from '../models/Supplement-list/supplement';
import { Supplement as SupplementReadSupplement } from '../models/Supplement-read/supplement';
import { Supplement as SupplementWriteSupplement } from '../models/Supplement-write/supplement';
import { Jsonld as SupplementJsonld } from '../models/Supplement/jsonld';
import { Supplement as SupplementJsonldListSupplement } from '../models/Supplement/jsonld-list/supplement';
import { Supplement as SupplementJsonldReadSupplement } from '../models/Supplement/jsonld-read/supplement';
import { Supplement as SupplementJsonldWriteSupplement } from '../models/Supplement/jsonld-write/supplement';

@Injectable({
  providedIn: 'root',
})
export class SupplementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSupplementCollection
   */
  static readonly GetSupplementCollectionPath = '/api/supplements';

  /**
   * Retrieves the collection of Supplement resources.
   *
   * Retrieves the collection of Supplement resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupplementCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplementCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'order.creationDate[before]'?: string;
    'order.creationDate[strictly_before]'?: string;
    'order.creationDate[after]'?: string;
    'order.creationDate[strictly_after]'?: string;
    order?: string;
    'order[]'?: Array<string>;
    'order.number'?: string;
    'order.status'?: string;
    'order.status[]'?: Array<string>;
    'order.shipments.number'?: number;
    'order.marketPlaces.name'?: string;
    'order.type'?: string;
    'order.type[]'?: Array<string>;
    'order.marketOrder.purchaseId'?: string;
    'order.supplement.supplement'?: number;
    'payment.number'?: number;
    'payment.status'?: string;
    'order.user.email'?: string;
    'order.user.name'?: string;
    'order.total'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SupplementListSupplement>>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.GetSupplementCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('order.creationDate[before]', params['order.creationDate[before]'], {"style":"form","explode":false});
      rb.query('order.creationDate[strictly_before]', params['order.creationDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('order.creationDate[after]', params['order.creationDate[after]'], {"style":"form","explode":false});
      rb.query('order.creationDate[strictly_after]', params['order.creationDate[strictly_after]'], {"style":"form","explode":false});
      rb.query('order', params.order, {"style":"form","explode":false});
      rb.query('order[]', params['order[]'], {"style":"form","explode":true});
      rb.query('order.number', params['order.number'], {"style":"form","explode":false});
      rb.query('order.status', params['order.status'], {"style":"form","explode":false});
      rb.query('order.status[]', params['order.status[]'], {"style":"form","explode":true});
      rb.query('order.shipments.number', params['order.shipments.number'], {"style":"form","explode":false});
      rb.query('order.marketPlaces.name', params['order.marketPlaces.name'], {"style":"form","explode":false});
      rb.query('order.type', params['order.type'], {"style":"form","explode":false});
      rb.query('order.type[]', params['order.type[]'], {"style":"form","explode":true});
      rb.query('order.marketOrder.purchaseId', params['order.marketOrder.purchaseId'], {"style":"form","explode":false});
      rb.query('order.supplement.supplement', params['order.supplement.supplement'], {"style":"form","explode":false});
      rb.query('payment.number', params['payment.number'], {"style":"form","explode":false});
      rb.query('payment.status', params['payment.status'], {"style":"form","explode":false});
      rb.query('order.user.email', params['order.user.email'], {"style":"form","explode":false});
      rb.query('order.user.name', params['order.user.name'], {"style":"form","explode":false});
      rb.query('order.total', params['order.total'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SupplementListSupplement>>;
      })
    );
  }

  /**
   * Retrieves the collection of Supplement resources.
   *
   * Retrieves the collection of Supplement resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupplementCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplementCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'order.creationDate[before]'?: string;
    'order.creationDate[strictly_before]'?: string;
    'order.creationDate[after]'?: string;
    'order.creationDate[strictly_after]'?: string;
    order?: string;
    'order[]'?: Array<string>;
    'order.number'?: string;
    'order.status'?: string;
    'order.status[]'?: Array<string>;
    'order.shipments.number'?: number;
    'order.marketPlaces.name'?: string;
    'order.type'?: string;
    'order.type[]'?: Array<string>;
    'order.marketOrder.purchaseId'?: string;
    'order.supplement.supplement'?: number;
    'payment.number'?: number;
    'payment.status'?: string;
    'order.user.email'?: string;
    'order.user.name'?: string;
    'order.total'?: number;
    context?: HttpContext
  }
): Observable<Array<SupplementListSupplement>> {

    return this.getSupplementCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SupplementListSupplement>>) => r.body as Array<SupplementListSupplement>)
    );
  }

  /**
   * Retrieves the collection of Supplement resources.
   *
   * Retrieves the collection of Supplement resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupplementCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplementCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'order.creationDate[before]'?: string;
    'order.creationDate[strictly_before]'?: string;
    'order.creationDate[after]'?: string;
    'order.creationDate[strictly_after]'?: string;
    order?: string;
    'order[]'?: Array<string>;
    'order.number'?: string;
    'order.status'?: string;
    'order.status[]'?: Array<string>;
    'order.shipments.number'?: number;
    'order.marketPlaces.name'?: string;
    'order.type'?: string;
    'order.type[]'?: Array<string>;
    'order.marketOrder.purchaseId'?: string;
    'order.supplement.supplement'?: number;
    'payment.number'?: number;
    'payment.status'?: string;
    'order.user.email'?: string;
    'order.user.name'?: string;
    'order.total'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SupplementListSupplement>>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.GetSupplementCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('order.creationDate[before]', params['order.creationDate[before]'], {"style":"form","explode":false});
      rb.query('order.creationDate[strictly_before]', params['order.creationDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('order.creationDate[after]', params['order.creationDate[after]'], {"style":"form","explode":false});
      rb.query('order.creationDate[strictly_after]', params['order.creationDate[strictly_after]'], {"style":"form","explode":false});
      rb.query('order', params.order, {"style":"form","explode":false});
      rb.query('order[]', params['order[]'], {"style":"form","explode":true});
      rb.query('order.number', params['order.number'], {"style":"form","explode":false});
      rb.query('order.status', params['order.status'], {"style":"form","explode":false});
      rb.query('order.status[]', params['order.status[]'], {"style":"form","explode":true});
      rb.query('order.shipments.number', params['order.shipments.number'], {"style":"form","explode":false});
      rb.query('order.marketPlaces.name', params['order.marketPlaces.name'], {"style":"form","explode":false});
      rb.query('order.type', params['order.type'], {"style":"form","explode":false});
      rb.query('order.type[]', params['order.type[]'], {"style":"form","explode":true});
      rb.query('order.marketOrder.purchaseId', params['order.marketOrder.purchaseId'], {"style":"form","explode":false});
      rb.query('order.supplement.supplement', params['order.supplement.supplement'], {"style":"form","explode":false});
      rb.query('payment.number', params['payment.number'], {"style":"form","explode":false});
      rb.query('payment.status', params['payment.status'], {"style":"form","explode":false});
      rb.query('order.user.email', params['order.user.email'], {"style":"form","explode":false});
      rb.query('order.user.name', params['order.user.name'], {"style":"form","explode":false});
      rb.query('order.total', params['order.total'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SupplementListSupplement>>;
      })
    );
  }

  /**
   * Retrieves the collection of Supplement resources.
   *
   * Retrieves the collection of Supplement resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupplementCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplementCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'order.creationDate[before]'?: string;
    'order.creationDate[strictly_before]'?: string;
    'order.creationDate[after]'?: string;
    'order.creationDate[strictly_after]'?: string;
    order?: string;
    'order[]'?: Array<string>;
    'order.number'?: string;
    'order.status'?: string;
    'order.status[]'?: Array<string>;
    'order.shipments.number'?: number;
    'order.marketPlaces.name'?: string;
    'order.type'?: string;
    'order.type[]'?: Array<string>;
    'order.marketOrder.purchaseId'?: string;
    'order.supplement.supplement'?: number;
    'payment.number'?: number;
    'payment.status'?: string;
    'order.user.email'?: string;
    'order.user.name'?: string;
    'order.total'?: number;
    context?: HttpContext
  }
): Observable<Array<SupplementListSupplement>> {

    return this.getSupplementCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SupplementListSupplement>>) => r.body as Array<SupplementListSupplement>)
    );
  }

  /**
   * Path part for operation postSupplementCollection
   */
  static readonly PostSupplementCollectionPath = '/api/supplements';

  /**
   * Creates a Supplement resource.
   *
   * Creates a Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSupplementCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSupplementCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<StrictHttpResponse<Supplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.PostSupplementCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Supplement>;
      })
    );
  }

  /**
   * Creates a Supplement resource.
   *
   * Creates a Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSupplementCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSupplementCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<Supplement> {

    return this.postSupplementCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Supplement>) => r.body as Supplement)
    );
  }

  /**
   * Creates a Supplement resource.
   *
   * Creates a Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSupplementCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSupplementCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<StrictHttpResponse<Supplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.PostSupplementCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Supplement>;
      })
    );
  }

  /**
   * Creates a Supplement resource.
   *
   * Creates a Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSupplementCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSupplementCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<Supplement> {

    return this.postSupplementCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Supplement>) => r.body as Supplement)
    );
  }

  /**
   * Creates a Supplement resource.
   *
   * Creates a Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSupplementCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postSupplementCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<StrictHttpResponse<Supplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.PostSupplementCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Supplement>;
      })
    );
  }

  /**
   * Creates a Supplement resource.
   *
   * Creates a Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSupplementCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postSupplementCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<Supplement> {

    return this.postSupplementCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Supplement>) => r.body as Supplement)
    );
  }

  /**
   * Creates a Supplement resource.
   *
   * Creates a Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSupplementCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postSupplementCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<StrictHttpResponse<Supplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.PostSupplementCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Supplement>;
      })
    );
  }

  /**
   * Creates a Supplement resource.
   *
   * Creates a Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSupplementCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postSupplementCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<Supplement> {

    return this.postSupplementCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Supplement>) => r.body as Supplement)
    );
  }

  /**
   * Path part for operation getSupplementItem
   */
  static readonly GetSupplementItemPath = '/api/supplements/{id}';

  /**
   * Retrieves a Supplement resource.
   *
   * Retrieves a Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupplementItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplementItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SupplementReadSupplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.GetSupplementItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplementReadSupplement>;
      })
    );
  }

  /**
   * Retrieves a Supplement resource.
   *
   * Retrieves a Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupplementItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplementItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<SupplementReadSupplement> {

    return this.getSupplementItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SupplementReadSupplement>) => r.body as SupplementReadSupplement)
    );
  }

  /**
   * Retrieves a Supplement resource.
   *
   * Retrieves a Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupplementItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplementItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SupplementReadSupplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.GetSupplementItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplementReadSupplement>;
      })
    );
  }

  /**
   * Retrieves a Supplement resource.
   *
   * Retrieves a Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupplementItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplementItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<SupplementReadSupplement> {

    return this.getSupplementItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<SupplementReadSupplement>) => r.body as SupplementReadSupplement)
    );
  }

  /**
   * Path part for operation putSupplementItem
   */
  static readonly PutSupplementItemPath = '/api/supplements/{id}';

  /**
   * Replaces the Supplement resource.
   *
   * Replaces the Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSupplementItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSupplementItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<StrictHttpResponse<Supplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.PutSupplementItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Supplement>;
      })
    );
  }

  /**
   * Replaces the Supplement resource.
   *
   * Replaces the Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putSupplementItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSupplementItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<Supplement> {

    return this.putSupplementItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Supplement>) => r.body as Supplement)
    );
  }

  /**
   * Replaces the Supplement resource.
   *
   * Replaces the Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSupplementItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSupplementItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<StrictHttpResponse<Supplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.PutSupplementItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Supplement>;
      })
    );
  }

  /**
   * Replaces the Supplement resource.
   *
   * Replaces the Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putSupplementItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSupplementItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<Supplement> {

    return this.putSupplementItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Supplement>) => r.body as Supplement)
    );
  }

  /**
   * Replaces the Supplement resource.
   *
   * Replaces the Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSupplementItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putSupplementItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<StrictHttpResponse<Supplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.PutSupplementItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Supplement>;
      })
    );
  }

  /**
   * Replaces the Supplement resource.
   *
   * Replaces the Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putSupplementItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putSupplementItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<Supplement> {

    return this.putSupplementItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Supplement>) => r.body as Supplement)
    );
  }

  /**
   * Replaces the Supplement resource.
   *
   * Replaces the Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSupplementItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putSupplementItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<StrictHttpResponse<Supplement>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.PutSupplementItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Supplement>;
      })
    );
  }

  /**
   * Replaces the Supplement resource.
   *
   * Replaces the Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putSupplementItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putSupplementItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Supplement resource
     */
    body: SupplementWriteSupplement
  }
): Observable<Supplement> {

    return this.putSupplementItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Supplement>) => r.body as Supplement)
    );
  }

  /**
   * Path part for operation deleteSupplementItem
   */
  static readonly DeleteSupplementItemPath = '/api/supplements/{id}';

  /**
   * Removes the Supplement resource.
   *
   * Removes the Supplement resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSupplementItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSupplementItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SupplementService.DeleteSupplementItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Supplement resource.
   *
   * Removes the Supplement resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSupplementItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSupplementItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteSupplementItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
