/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MediaObject } from '../models/media-object';
import { MediaObject as MediaObjectListMediaObject } from '../models/MediaObject-list/media-object';
import { MediaObject as MediaObjectReadMediaObject } from '../models/MediaObject-read/media-object';
import { MediaObject as MediaObjectWriteMediaObject } from '../models/MediaObject-write/media-object';
import { Jsonld as MediaObjectJsonld } from '../models/MediaObject/jsonld';
import { MediaObject as MediaObjectJsonldListMediaObject } from '../models/MediaObject/jsonld-list/media-object';
import { MediaObject as MediaObjectJsonldReadMediaObject } from '../models/MediaObject/jsonld-read/media-object';
import { MediaObject as MediaObjectJsonldWriteMediaObject } from '../models/MediaObject/jsonld-write/media-object';

@Injectable({
  providedIn: 'root',
})
export class MediaObjectService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMediaObjectCollection
   */
  static readonly GetMediaObjectCollectionPath = '/api/media_objects';

  /**
   * Retrieves the collection of MediaObject resources.
   *
   * Retrieves the collection of MediaObject resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaObjectCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaObjectCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    fileType?: string;
    description?: string;
    title?: string;
    name?: string;
    size?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MediaObjectListMediaObject>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.GetMediaObjectCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('fileType', params.fileType, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('title', params.title, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('size', params.size, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MediaObjectListMediaObject>>;
      })
    );
  }

  /**
   * Retrieves the collection of MediaObject resources.
   *
   * Retrieves the collection of MediaObject resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMediaObjectCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaObjectCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    fileType?: string;
    description?: string;
    title?: string;
    name?: string;
    size?: number;
    context?: HttpContext
  }
): Observable<Array<MediaObjectListMediaObject>> {

    return this.getMediaObjectCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MediaObjectListMediaObject>>) => r.body as Array<MediaObjectListMediaObject>)
    );
  }

  /**
   * Retrieves the collection of MediaObject resources.
   *
   * Retrieves the collection of MediaObject resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaObjectCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaObjectCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    fileType?: string;
    description?: string;
    title?: string;
    name?: string;
    size?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MediaObjectListMediaObject>>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.GetMediaObjectCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('fileType', params.fileType, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('title', params.title, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('size', params.size, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MediaObjectListMediaObject>>;
      })
    );
  }

  /**
   * Retrieves the collection of MediaObject resources.
   *
   * Retrieves the collection of MediaObject resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMediaObjectCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaObjectCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    fileType?: string;
    description?: string;
    title?: string;
    name?: string;
    size?: number;
    context?: HttpContext
  }
): Observable<Array<MediaObjectListMediaObject>> {

    return this.getMediaObjectCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MediaObjectListMediaObject>>) => r.body as Array<MediaObjectListMediaObject>)
    );
  }

  /**
   * Path part for operation postMediaObjectCollection
   */
  static readonly PostMediaObjectCollectionPath = '/api/media_objects';

  /**
   * Creates a MediaObject resource.
   *
   * Creates a MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMediaObjectCollection$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postMediaObjectCollection$Json$Response(params?: {
    context?: HttpContext
    body?: {
'description'?: string;
'title'?: string;
'name'?: string;
'size'?: number;
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<MediaObject>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.PostMediaObjectCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaObject>;
      })
    );
  }

  /**
   * Creates a MediaObject resource.
   *
   * Creates a MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMediaObjectCollection$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postMediaObjectCollection$Json(params?: {
    context?: HttpContext
    body?: {
'description'?: string;
'title'?: string;
'name'?: string;
'size'?: number;
'file'?: Blob;
}
  }
): Observable<MediaObject> {

    return this.postMediaObjectCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaObject>) => r.body as MediaObject)
    );
  }

  /**
   * Creates a MediaObject resource.
   *
   * Creates a MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMediaObjectCollection$Html()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postMediaObjectCollection$Html$Response(params?: {
    context?: HttpContext
    body?: {
'description'?: string;
'title'?: string;
'name'?: string;
'size'?: number;
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<MediaObject>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.PostMediaObjectCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaObject>;
      })
    );
  }

  /**
   * Creates a MediaObject resource.
   *
   * Creates a MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMediaObjectCollection$Html$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postMediaObjectCollection$Html(params?: {
    context?: HttpContext
    body?: {
'description'?: string;
'title'?: string;
'name'?: string;
'size'?: number;
'file'?: Blob;
}
  }
): Observable<MediaObject> {

    return this.postMediaObjectCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MediaObject>) => r.body as MediaObject)
    );
  }

  /**
   * Path part for operation getMediaObjectItem
   */
  static readonly GetMediaObjectItemPath = '/api/media_objects/{id}';

  /**
   * Retrieves a MediaObject resource.
   *
   * Retrieves a MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaObjectItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaObjectItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MediaObjectReadMediaObject>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.GetMediaObjectItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaObjectReadMediaObject>;
      })
    );
  }

  /**
   * Retrieves a MediaObject resource.
   *
   * Retrieves a MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMediaObjectItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaObjectItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<MediaObjectReadMediaObject> {

    return this.getMediaObjectItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaObjectReadMediaObject>) => r.body as MediaObjectReadMediaObject)
    );
  }

  /**
   * Retrieves a MediaObject resource.
   *
   * Retrieves a MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaObjectItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaObjectItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MediaObjectReadMediaObject>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.GetMediaObjectItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaObjectReadMediaObject>;
      })
    );
  }

  /**
   * Retrieves a MediaObject resource.
   *
   * Retrieves a MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMediaObjectItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaObjectItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<MediaObjectReadMediaObject> {

    return this.getMediaObjectItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MediaObjectReadMediaObject>) => r.body as MediaObjectReadMediaObject)
    );
  }

  /**
   * Path part for operation putMediaObjectItem
   */
  static readonly PutMediaObjectItemPath = '/api/media_objects/{id}';

  /**
   * Replaces the MediaObject resource.
   *
   * Replaces the MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMediaObjectItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMediaObjectItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MediaObject resource
     */
    body: MediaObjectWriteMediaObject
  }
): Observable<StrictHttpResponse<MediaObject>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.PutMediaObjectItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaObject>;
      })
    );
  }

  /**
   * Replaces the MediaObject resource.
   *
   * Replaces the MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMediaObjectItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMediaObjectItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MediaObject resource
     */
    body: MediaObjectWriteMediaObject
  }
): Observable<MediaObject> {

    return this.putMediaObjectItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaObject>) => r.body as MediaObject)
    );
  }

  /**
   * Replaces the MediaObject resource.
   *
   * Replaces the MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMediaObjectItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMediaObjectItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MediaObject resource
     */
    body: MediaObjectWriteMediaObject
  }
): Observable<StrictHttpResponse<MediaObject>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.PutMediaObjectItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaObject>;
      })
    );
  }

  /**
   * Replaces the MediaObject resource.
   *
   * Replaces the MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMediaObjectItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMediaObjectItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MediaObject resource
     */
    body: MediaObjectWriteMediaObject
  }
): Observable<MediaObject> {

    return this.putMediaObjectItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MediaObject>) => r.body as MediaObject)
    );
  }

  /**
   * Replaces the MediaObject resource.
   *
   * Replaces the MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMediaObjectItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMediaObjectItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MediaObject resource
     */
    body: MediaObjectWriteMediaObject
  }
): Observable<StrictHttpResponse<MediaObject>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.PutMediaObjectItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaObject>;
      })
    );
  }

  /**
   * Replaces the MediaObject resource.
   *
   * Replaces the MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMediaObjectItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMediaObjectItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MediaObject resource
     */
    body: MediaObjectWriteMediaObject
  }
): Observable<MediaObject> {

    return this.putMediaObjectItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaObject>) => r.body as MediaObject)
    );
  }

  /**
   * Replaces the MediaObject resource.
   *
   * Replaces the MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMediaObjectItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMediaObjectItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MediaObject resource
     */
    body: MediaObjectWriteMediaObject
  }
): Observable<StrictHttpResponse<MediaObject>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.PutMediaObjectItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaObject>;
      })
    );
  }

  /**
   * Replaces the MediaObject resource.
   *
   * Replaces the MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMediaObjectItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMediaObjectItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MediaObject resource
     */
    body: MediaObjectWriteMediaObject
  }
): Observable<MediaObject> {

    return this.putMediaObjectItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MediaObject>) => r.body as MediaObject)
    );
  }

  /**
   * Path part for operation deleteMediaObjectItem
   */
  static readonly DeleteMediaObjectItemPath = '/api/media_objects/{id}';

  /**
   * Removes the MediaObject resource.
   *
   * Removes the MediaObject resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMediaObjectItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMediaObjectItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MediaObjectService.DeleteMediaObjectItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the MediaObject resource.
   *
   * Removes the MediaObject resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMediaObjectItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMediaObjectItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteMediaObjectItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
