/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Shipment } from '../models/shipment';
import { Shipment as ShipmentListShipment } from '../models/Shipment-list/shipment';
import { Shipment as ShipmentReadShipment } from '../models/Shipment-read/shipment';
import { Shipment as ShipmentWriteShipment } from '../models/Shipment-write/shipment';
import { Jsonld as ShipmentJsonld } from '../models/Shipment/jsonld';
import { Shipment as ShipmentJsonldListShipment } from '../models/Shipment/jsonld-list/shipment';
import { Shipment as ShipmentJsonldReadShipment } from '../models/Shipment/jsonld-read/shipment';
import { Shipment as ShipmentJsonldWriteShipment } from '../models/Shipment/jsonld-write/shipment';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getShipmentCollection
   */
  static readonly GetShipmentCollectionPath = '/api/shipments';

  /**
   * Retrieves the collection of Shipment resources.
   *
   * Retrieves the collection of Shipment resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShipmentCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    status?: string;
    'order.number'?: string;
    'user.email'?: string;
    'method.name'?: string;
    number?: number;
    'estimatedArrivalDate[before]'?: string;
    'estimatedArrivalDate[strictly_before]'?: string;
    'estimatedArrivalDate[after]'?: string;
    'estimatedArrivalDate[strictly_after]'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ShipmentListShipment>>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.GetShipmentCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('status', params.status, {"style":"form","explode":false});
      rb.query('order.number', params['order.number'], {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('method.name', params['method.name'], {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('estimatedArrivalDate[before]', params['estimatedArrivalDate[before]'], {"style":"form","explode":false});
      rb.query('estimatedArrivalDate[strictly_before]', params['estimatedArrivalDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('estimatedArrivalDate[after]', params['estimatedArrivalDate[after]'], {"style":"form","explode":false});
      rb.query('estimatedArrivalDate[strictly_after]', params['estimatedArrivalDate[strictly_after]'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShipmentListShipment>>;
      })
    );
  }

  /**
   * Retrieves the collection of Shipment resources.
   *
   * Retrieves the collection of Shipment resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShipmentCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    status?: string;
    'order.number'?: string;
    'user.email'?: string;
    'method.name'?: string;
    number?: number;
    'estimatedArrivalDate[before]'?: string;
    'estimatedArrivalDate[strictly_before]'?: string;
    'estimatedArrivalDate[after]'?: string;
    'estimatedArrivalDate[strictly_after]'?: string;
    context?: HttpContext
  }
): Observable<Array<ShipmentListShipment>> {

    return this.getShipmentCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ShipmentListShipment>>) => r.body as Array<ShipmentListShipment>)
    );
  }

  /**
   * Retrieves the collection of Shipment resources.
   *
   * Retrieves the collection of Shipment resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShipmentCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    status?: string;
    'order.number'?: string;
    'user.email'?: string;
    'method.name'?: string;
    number?: number;
    'estimatedArrivalDate[before]'?: string;
    'estimatedArrivalDate[strictly_before]'?: string;
    'estimatedArrivalDate[after]'?: string;
    'estimatedArrivalDate[strictly_after]'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ShipmentListShipment>>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.GetShipmentCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('status', params.status, {"style":"form","explode":false});
      rb.query('order.number', params['order.number'], {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('method.name', params['method.name'], {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('estimatedArrivalDate[before]', params['estimatedArrivalDate[before]'], {"style":"form","explode":false});
      rb.query('estimatedArrivalDate[strictly_before]', params['estimatedArrivalDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('estimatedArrivalDate[after]', params['estimatedArrivalDate[after]'], {"style":"form","explode":false});
      rb.query('estimatedArrivalDate[strictly_after]', params['estimatedArrivalDate[strictly_after]'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShipmentListShipment>>;
      })
    );
  }

  /**
   * Retrieves the collection of Shipment resources.
   *
   * Retrieves the collection of Shipment resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShipmentCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    status?: string;
    'order.number'?: string;
    'user.email'?: string;
    'method.name'?: string;
    number?: number;
    'estimatedArrivalDate[before]'?: string;
    'estimatedArrivalDate[strictly_before]'?: string;
    'estimatedArrivalDate[after]'?: string;
    'estimatedArrivalDate[strictly_after]'?: string;
    context?: HttpContext
  }
): Observable<Array<ShipmentListShipment>> {

    return this.getShipmentCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ShipmentListShipment>>) => r.body as Array<ShipmentListShipment>)
    );
  }

  /**
   * Path part for operation postShipmentCollection
   */
  static readonly PostShipmentCollectionPath = '/api/shipments';

  /**
   * Creates a Shipment resource.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postShipmentCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postShipmentCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<StrictHttpResponse<Shipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.PostShipmentCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shipment>;
      })
    );
  }

  /**
   * Creates a Shipment resource.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postShipmentCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postShipmentCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<Shipment> {

    return this.postShipmentCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Shipment>) => r.body as Shipment)
    );
  }

  /**
   * Creates a Shipment resource.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postShipmentCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postShipmentCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<StrictHttpResponse<Shipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.PostShipmentCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shipment>;
      })
    );
  }

  /**
   * Creates a Shipment resource.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postShipmentCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postShipmentCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<Shipment> {

    return this.postShipmentCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Shipment>) => r.body as Shipment)
    );
  }

  /**
   * Creates a Shipment resource.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postShipmentCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postShipmentCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<StrictHttpResponse<Shipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.PostShipmentCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shipment>;
      })
    );
  }

  /**
   * Creates a Shipment resource.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postShipmentCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postShipmentCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<Shipment> {

    return this.postShipmentCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Shipment>) => r.body as Shipment)
    );
  }

  /**
   * Creates a Shipment resource.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postShipmentCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postShipmentCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<StrictHttpResponse<Shipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.PostShipmentCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shipment>;
      })
    );
  }

  /**
   * Creates a Shipment resource.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postShipmentCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postShipmentCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<Shipment> {

    return this.postShipmentCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Shipment>) => r.body as Shipment)
    );
  }

  /**
   * Path part for operation getShipmentItem
   */
  static readonly GetShipmentItemPath = '/api/shipments/{id}';

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShipmentItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ShipmentReadShipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.GetShipmentItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentReadShipment>;
      })
    );
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShipmentItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<ShipmentReadShipment> {

    return this.getShipmentItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentReadShipment>) => r.body as ShipmentReadShipment)
    );
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShipmentItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ShipmentReadShipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.GetShipmentItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ShipmentReadShipment>;
      })
    );
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShipmentItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShipmentItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<ShipmentReadShipment> {

    return this.getShipmentItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<ShipmentReadShipment>) => r.body as ShipmentReadShipment)
    );
  }

  /**
   * Path part for operation putShipmentItem
   */
  static readonly PutShipmentItemPath = '/api/shipments/{id}';

  /**
   * Replaces the Shipment resource.
   *
   * Replaces the Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putShipmentItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putShipmentItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<StrictHttpResponse<Shipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.PutShipmentItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shipment>;
      })
    );
  }

  /**
   * Replaces the Shipment resource.
   *
   * Replaces the Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putShipmentItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putShipmentItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<Shipment> {

    return this.putShipmentItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Shipment>) => r.body as Shipment)
    );
  }

  /**
   * Replaces the Shipment resource.
   *
   * Replaces the Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putShipmentItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putShipmentItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<StrictHttpResponse<Shipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.PutShipmentItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shipment>;
      })
    );
  }

  /**
   * Replaces the Shipment resource.
   *
   * Replaces the Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putShipmentItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putShipmentItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<Shipment> {

    return this.putShipmentItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Shipment>) => r.body as Shipment)
    );
  }

  /**
   * Replaces the Shipment resource.
   *
   * Replaces the Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putShipmentItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putShipmentItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<StrictHttpResponse<Shipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.PutShipmentItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shipment>;
      })
    );
  }

  /**
   * Replaces the Shipment resource.
   *
   * Replaces the Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putShipmentItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putShipmentItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<Shipment> {

    return this.putShipmentItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Shipment>) => r.body as Shipment)
    );
  }

  /**
   * Replaces the Shipment resource.
   *
   * Replaces the Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putShipmentItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putShipmentItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<StrictHttpResponse<Shipment>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.PutShipmentItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shipment>;
      })
    );
  }

  /**
   * Replaces the Shipment resource.
   *
   * Replaces the Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putShipmentItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putShipmentItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Shipment resource
     */
    body: ShipmentWriteShipment
  }
): Observable<Shipment> {

    return this.putShipmentItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Shipment>) => r.body as Shipment)
    );
  }

  /**
   * Path part for operation deleteShipmentItem
   */
  static readonly DeleteShipmentItemPath = '/api/shipments/{id}';

  /**
   * Removes the Shipment resource.
   *
   * Removes the Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteShipmentItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteShipmentItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ShipmentService.DeleteShipmentItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Shipment resource.
   *
   * Removes the Shipment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteShipmentItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteShipmentItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteShipmentItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
