import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss'],
})
export class OrderPaymentComponent implements OnInit {

  @ViewChild('accordion') accordion;

  @Input('resume') resume: any;

  constructor() { }

  ngOnInit() {
    console.log(this.resume);
  }

  controlAccordion(){
    let panel;
    let element;

    element = this.accordion.nativeElement;

    if (element) {
      panel = element.nextElementSibling;
      element.classList.toggle("active");
    }

    // Display or Hidde accordion content
    this.accordionDisplayToggle(panel);
  }
  
  accordionDisplayToggle(panel: any) {
    if (panel.style.display === "block") {
      panel.style.display = "none";
   
    
        panel.style.display = "none";
      
    } else {
      panel.style.display = "block";
    }
  }

}
