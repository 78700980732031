import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from 'src/app/api/models';
import { WebViewService } from 'src/app/components/services/web-view.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

  @Input() from: string;

  @Input() address: Address;
  @Input() invoice: Address;
  @Input() shippment;
  @Input() paymentMethod;

  private checkAdress: boolean;

  constructor(private router: Router, private iab: WebViewService) { }

  ngOnInit() {}

  pay(){
    this.iab.openPayView();
  }

  goToHome(){
    this.router.navigate(['/home']);
  }

  goBack(){
    //abrira la tienda en la que estaba
  }

  submit(){
    this.checkAdress = true;
  }
}
