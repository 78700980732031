/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderHistory } from '../models/order-history';
import { OrderHistory as OrderHistoryListOrderHistory } from '../models/OrderHistory-list/order-history';
import { OrderHistory as OrderHistoryReadOrderHistory } from '../models/OrderHistory-read/order-history';
import { OrderHistory as OrderHistoryWriteOrderHistory } from '../models/OrderHistory-write/order-history';
import { Jsonld as OrderHistoryJsonld } from '../models/OrderHistory/jsonld';
import { OrderHistory as OrderHistoryJsonldListOrderHistory } from '../models/OrderHistory/jsonld-list/order-history';
import { OrderHistory as OrderHistoryJsonldReadOrderHistory } from '../models/OrderHistory/jsonld-read/order-history';
import { OrderHistory as OrderHistoryJsonldWriteOrderHistory } from '../models/OrderHistory/jsonld-write/order-history';

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOrderHistoryCollection
   */
  static readonly GetOrderHistoryCollectionPath = '/api/order_histories';

  /**
   * Retrieves the collection of OrderHistory resources.
   *
   * Retrieves the collection of OrderHistory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderHistoryCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    order?: string;
    'order[]'?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OrderHistoryListOrderHistory>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.GetOrderHistoryCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('order', params.order, {"style":"form","explode":false});
      rb.query('order[]', params['order[]'], {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderHistoryListOrderHistory>>;
      })
    );
  }

  /**
   * Retrieves the collection of OrderHistory resources.
   *
   * Retrieves the collection of OrderHistory resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderHistoryCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    order?: string;
    'order[]'?: Array<string>;
    context?: HttpContext
  }
): Observable<Array<OrderHistoryListOrderHistory>> {

    return this.getOrderHistoryCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderHistoryListOrderHistory>>) => r.body as Array<OrderHistoryListOrderHistory>)
    );
  }

  /**
   * Retrieves the collection of OrderHistory resources.
   *
   * Retrieves the collection of OrderHistory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderHistoryCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    order?: string;
    'order[]'?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OrderHistoryListOrderHistory>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.GetOrderHistoryCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('order', params.order, {"style":"form","explode":false});
      rb.query('order[]', params['order[]'], {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderHistoryListOrderHistory>>;
      })
    );
  }

  /**
   * Retrieves the collection of OrderHistory resources.
   *
   * Retrieves the collection of OrderHistory resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderHistoryCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    order?: string;
    'order[]'?: Array<string>;
    context?: HttpContext
  }
): Observable<Array<OrderHistoryListOrderHistory>> {

    return this.getOrderHistoryCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrderHistoryListOrderHistory>>) => r.body as Array<OrderHistoryListOrderHistory>)
    );
  }

  /**
   * Path part for operation postOrderHistoryCollection
   */
  static readonly PostOrderHistoryCollectionPath = '/api/order_histories';

  /**
   * Creates a OrderHistory resource.
   *
   * Creates a OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderHistoryCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderHistoryCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<StrictHttpResponse<OrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.PostOrderHistoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistory>;
      })
    );
  }

  /**
   * Creates a OrderHistory resource.
   *
   * Creates a OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderHistoryCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderHistoryCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<OrderHistory> {

    return this.postOrderHistoryCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistory>) => r.body as OrderHistory)
    );
  }

  /**
   * Creates a OrderHistory resource.
   *
   * Creates a OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderHistoryCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderHistoryCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<StrictHttpResponse<OrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.PostOrderHistoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistory>;
      })
    );
  }

  /**
   * Creates a OrderHistory resource.
   *
   * Creates a OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderHistoryCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderHistoryCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<OrderHistory> {

    return this.postOrderHistoryCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistory>) => r.body as OrderHistory)
    );
  }

  /**
   * Creates a OrderHistory resource.
   *
   * Creates a OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderHistoryCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderHistoryCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<StrictHttpResponse<OrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.PostOrderHistoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistory>;
      })
    );
  }

  /**
   * Creates a OrderHistory resource.
   *
   * Creates a OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderHistoryCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderHistoryCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<OrderHistory> {

    return this.postOrderHistoryCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistory>) => r.body as OrderHistory)
    );
  }

  /**
   * Creates a OrderHistory resource.
   *
   * Creates a OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderHistoryCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderHistoryCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<StrictHttpResponse<OrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.PostOrderHistoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistory>;
      })
    );
  }

  /**
   * Creates a OrderHistory resource.
   *
   * Creates a OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrderHistoryCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postOrderHistoryCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<OrderHistory> {

    return this.postOrderHistoryCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistory>) => r.body as OrderHistory)
    );
  }

  /**
   * Path part for operation getOrderHistoryItem
   */
  static readonly GetOrderHistoryItemPath = '/api/order_histories/{id}';

  /**
   * Retrieves a OrderHistory resource.
   *
   * Retrieves a OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderHistoryItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OrderHistoryReadOrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.GetOrderHistoryItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistoryReadOrderHistory>;
      })
    );
  }

  /**
   * Retrieves a OrderHistory resource.
   *
   * Retrieves a OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderHistoryItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<OrderHistoryReadOrderHistory> {

    return this.getOrderHistoryItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistoryReadOrderHistory>) => r.body as OrderHistoryReadOrderHistory)
    );
  }

  /**
   * Retrieves a OrderHistory resource.
   *
   * Retrieves a OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderHistoryItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OrderHistoryReadOrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.GetOrderHistoryItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistoryReadOrderHistory>;
      })
    );
  }

  /**
   * Retrieves a OrderHistory resource.
   *
   * Retrieves a OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderHistoryItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderHistoryItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<OrderHistoryReadOrderHistory> {

    return this.getOrderHistoryItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistoryReadOrderHistory>) => r.body as OrderHistoryReadOrderHistory)
    );
  }

  /**
   * Path part for operation putOrderHistoryItem
   */
  static readonly PutOrderHistoryItemPath = '/api/order_histories/{id}';

  /**
   * Replaces the OrderHistory resource.
   *
   * Replaces the OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderHistoryItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderHistoryItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<StrictHttpResponse<OrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.PutOrderHistoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistory>;
      })
    );
  }

  /**
   * Replaces the OrderHistory resource.
   *
   * Replaces the OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderHistoryItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderHistoryItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<OrderHistory> {

    return this.putOrderHistoryItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistory>) => r.body as OrderHistory)
    );
  }

  /**
   * Replaces the OrderHistory resource.
   *
   * Replaces the OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderHistoryItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderHistoryItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<StrictHttpResponse<OrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.PutOrderHistoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistory>;
      })
    );
  }

  /**
   * Replaces the OrderHistory resource.
   *
   * Replaces the OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderHistoryItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putOrderHistoryItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<OrderHistory> {

    return this.putOrderHistoryItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistory>) => r.body as OrderHistory)
    );
  }

  /**
   * Replaces the OrderHistory resource.
   *
   * Replaces the OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderHistoryItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderHistoryItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<StrictHttpResponse<OrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.PutOrderHistoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistory>;
      })
    );
  }

  /**
   * Replaces the OrderHistory resource.
   *
   * Replaces the OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderHistoryItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderHistoryItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<OrderHistory> {

    return this.putOrderHistoryItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistory>) => r.body as OrderHistory)
    );
  }

  /**
   * Replaces the OrderHistory resource.
   *
   * Replaces the OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putOrderHistoryItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderHistoryItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<StrictHttpResponse<OrderHistory>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.PutOrderHistoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistory>;
      })
    );
  }

  /**
   * Replaces the OrderHistory resource.
   *
   * Replaces the OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putOrderHistoryItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putOrderHistoryItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated OrderHistory resource
     */
    body: OrderHistoryWriteOrderHistory
  }
): Observable<OrderHistory> {

    return this.putOrderHistoryItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<OrderHistory>) => r.body as OrderHistory)
    );
  }

  /**
   * Path part for operation deleteOrderHistoryItem
   */
  static readonly DeleteOrderHistoryItemPath = '/api/order_histories/{id}';

  /**
   * Removes the OrderHistory resource.
   *
   * Removes the OrderHistory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrderHistoryItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderHistoryItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderHistoryService.DeleteOrderHistoryItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the OrderHistory resource.
   *
   * Removes the OrderHistory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteOrderHistoryItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderHistoryItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteOrderHistoryItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
