import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-order-completed',
	templateUrl: './order-completed.component.html',
	styleUrls: ['./order-completed.component.scss'],
})
export class OrderCompletedComponent implements OnInit {
	constructor(private navController: NavController) {}

	ngOnInit() {}

	goToHome() {
		this.navController.navigateRoot(['/shipments']);
	}
}
