import { Component, OnInit } from '@angular/core';
import { CmsCategoryService } from 'src/app/api/services/cms-category.service';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
	public listCategories = [];
	public contCategories: number = 0;

	constructor(
		private navController: NavController,
		private cmsCategoryService: CmsCategoryService
	) {}

	ngOnInit() {
		this.getCategories();
	}

	goToInfo() {
		this.navController.navigateRoot(['/general-info']);
	}

	goSubCategory(parentName: string) {
		this.navController.navigateRoot([
			'/child-category',
			{ categoryName: parentName },
		]);
	}

	getCategories() {
		this.cmsCategoryService
			.getCmsCategoryCollection$Json({"parent.id": environment.cmsCategoryID})
			.subscribe((res) => {
				this.listCategories = res				
			});
	}
}
