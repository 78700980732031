import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/authentication/login/login.component';
import { RegisterComponent } from './components/authentication/register/register.component';
import { CartComponent } from './components/pages/purchaseProcess/cart/cart.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { HelpComponent } from './components/pages/help/help.component';
import { HomeComponent } from './components/pages/home/home.component';
import { OrderCompletedComponent } from './components/pages/purchaseProcess/order-completed/order-completed.component';
import { PaymentComponent } from './components/pages/purchaseProcess/payment/payment.component';
import { PaymentsComponent } from './components/pages/payments/payments.component';
import { ShipmentsStatusComponent } from './components/pages/orders-list/shipments-status.component';
import { UserMenuComponent } from './components/pages/user-menu/user-menu.component';
import { SearchTabComponent } from './components/nav-tabs/search-tab/search-tab.component';
import { CustomPruchaseComponent } from './components/pages/custom-pruchase/custom-pruchase.component';
import { ChildCategoryComponent } from './components/pages/help/child-category/child-category.component';
import { RecoverPswdComponent } from './components/pages/recover-pswd/recover-pswd.component';
import { GeneralInfoComponent } from './components/pages/help/general-info/general-info.component';
import { MenuTabComponent } from './components/nav-tabs/menu-tab/menu-tab.component';
import { UserDataComponent } from './components/pages/user-data/user-data.component';
import { OrderResumeComponent } from './components/pages/orders-list/order-options/order-resume/order-resume.component';
import { OrderOptionsComponent } from './components/pages/orders-list/order-options/order-options.component';

const routes: Routes = [
	{
		path: 'home',
		component: HomeComponent,
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
	{
		path: 'menu',
		component: MenuTabComponent,
	},
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'register',
		component: RegisterComponent,
	},
	{
		path: 'forgot-password',
		component: RecoverPswdComponent,
	},
	{
		path: 'search',
		component: SearchTabComponent,
	},
	{
		path: 'category',
		component: CategoryComponent,
	},
	{
		path: 'cart',
		component: CartComponent,
	},
	{
		path: 'payment',
		component: PaymentComponent,
	},
	{
		path: 'order-completed',
		component: OrderCompletedComponent,
	},
	{
		path: 'user',
		component: UserMenuComponent,
	},
	{
		path: 'shipments',
		component: ShipmentsStatusComponent,
	},
	{
		path: 'payments',
		component: PaymentsComponent,
	},
	{
		path: 'help',
		component: HelpComponent,
	},
	{
		path: 'custom-purchase',
		component: CustomPruchaseComponent,
	},
	{
		path: 'general-info',
		component: GeneralInfoComponent,
	},
	{
		path: 'child-category',
		component: ChildCategoryComponent,
	},
	{
		path: 'user-data',
		component: UserDataComponent,
	},
	{
		path: 'order-resume',
		component: OrderResumeComponent,
	},
	{
		path: 'order-options',
		component: OrderOptionsComponent,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
