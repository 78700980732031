import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[appScrollbarTheme]',
})
export class ScrollbarThemeDirective {
	constructor(element: ElementRef) {
		const stylesheet = `
        ::-webkit-scrollbar {
            width: 1px;
        }

        ::-webkit-scrollbar-track {
            background: white;
        }

        ::-webkit-scrollbar-thumb {
            background: white;
        }

        ::-webkit-scrollbar-thumb:hover {}
    `;

		const styleElmt =
			element.nativeElement.shadowRoot.querySelector('style');
		if (styleElmt) {
			styleElmt.append(stylesheet);
		} else {
			const barStyle = document.createElement('style');
			barStyle.append(stylesheet);
			element.nativeElement.shadowRoot.appendChild(barStyle);
		}
	}
}
