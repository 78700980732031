import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
	selector: 'app-confirmation-popover',
	templateUrl: './confirmation-popover.component.html',
	styleUrls: ['./confirmation-popover.component.scss'],
})
export class ConfirmationPopoverComponent implements OnInit {
	@Input() callback: Function;
	@Input() message: string;

	public isLoading: boolean;

	constructor(private popoverController: PopoverController) {}

	ngOnInit() {}

	action() {
		this.isLoading = true;
		this.callback();
	}

	close() {
		this.popoverController.getTop().then((pop) => pop.dismiss());
	}
}