import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-user-tab',
  templateUrl: './user-tab.component.html',
  styleUrls: ['./user-tab.component.scss'],
})
export class UserTabComponent implements OnInit {
  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private cookieService: CookieService,
  ) {}

  public loged: boolean = false;

  ngOnInit() {
    this.loged = !!this.cookieService.get('BEARER_TOKEN')
  }



}
