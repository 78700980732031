/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MarketPlaceCategory } from '../models/market-place-category';
import { Parent as MarketPlaceCategoryListMarketPlaceCategoryListParent } from '../models/MarketPlaceCategory-list/marketPlaceCategory_list/parent';
import { Parent as MarketPlaceCategoryReadMarketPlaceCategoryReadParent } from '../models/MarketPlaceCategory-read/marketPlaceCategory_read/parent';
import { MarketPlaceCategory as MarketPlaceCategoryWriteMarketPlaceCategory } from '../models/MarketPlaceCategory-write/market-place-category';
import { Jsonld as MarketPlaceCategoryJsonld } from '../models/MarketPlaceCategory/jsonld';
import { Parent as MarketPlaceCategoryJsonldListMarketPlaceCategoryListParent } from '../models/MarketPlaceCategory/jsonld-list/marketPlaceCategory_list/parent';
import { Parent as MarketPlaceCategoryJsonldReadMarketPlaceCategoryReadParent } from '../models/MarketPlaceCategory/jsonld-read/marketPlaceCategory_read/parent';
import { MarketPlaceCategory as MarketPlaceCategoryJsonldWriteMarketPlaceCategory } from '../models/MarketPlaceCategory/jsonld-write/market-place-category';

@Injectable({
  providedIn: 'root',
})
export class MarketPlaceCategoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMarketPlaceCategoryCollection
   */
  static readonly GetMarketPlaceCategoryCollectionPath = '/api/market_place_categories';

  /**
   * Retrieves the collection of MarketPlaceCategory resources.
   *
   * Retrieves the collection of MarketPlaceCategory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketPlaceCategoryCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCategoryCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    parent?: string;
    'parent[]'?: Array<string>;
    'exists[parent]'?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.GetMarketPlaceCategoryCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('parent', params.parent, {"style":"form","explode":false});
      rb.query('parent[]', params['parent[]'], {"style":"form","explode":true});
      rb.query('exists[parent]', params['exists[parent]'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>>;
      })
    );
  }

  /**
   * Retrieves the collection of MarketPlaceCategory resources.
   *
   * Retrieves the collection of MarketPlaceCategory resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketPlaceCategoryCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCategoryCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    parent?: string;
    'parent[]'?: Array<string>;
    'exists[parent]'?: boolean;
    context?: HttpContext
  }
): Observable<Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>> {

    return this.getMarketPlaceCategoryCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>>) => r.body as Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>)
    );
  }

  /**
   * Retrieves the collection of MarketPlaceCategory resources.
   *
   * Retrieves the collection of MarketPlaceCategory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketPlaceCategoryCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCategoryCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    parent?: string;
    'parent[]'?: Array<string>;
    'exists[parent]'?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.GetMarketPlaceCategoryCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('parent', params.parent, {"style":"form","explode":false});
      rb.query('parent[]', params['parent[]'], {"style":"form","explode":true});
      rb.query('exists[parent]', params['exists[parent]'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>>;
      })
    );
  }

  /**
   * Retrieves the collection of MarketPlaceCategory resources.
   *
   * Retrieves the collection of MarketPlaceCategory resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketPlaceCategoryCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCategoryCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    parent?: string;
    'parent[]'?: Array<string>;
    'exists[parent]'?: boolean;
    context?: HttpContext
  }
): Observable<Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>> {

    return this.getMarketPlaceCategoryCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>>) => r.body as Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>)
    );
  }

  /**
   * Path part for operation postMarketPlaceCategoryCollection
   */
  static readonly PostMarketPlaceCategoryCollectionPath = '/api/market_place_categories';

  /**
   * Creates a MarketPlaceCategory resource.
   *
   * Creates a MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketPlaceCategoryCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketPlaceCategoryCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<StrictHttpResponse<MarketPlaceCategory>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.PostMarketPlaceCategoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategory>;
      })
    );
  }

  /**
   * Creates a MarketPlaceCategory resource.
   *
   * Creates a MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketPlaceCategoryCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketPlaceCategoryCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<MarketPlaceCategory> {

    return this.postMarketPlaceCategoryCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategory>) => r.body as MarketPlaceCategory)
    );
  }

  /**
   * Creates a MarketPlaceCategory resource.
   *
   * Creates a MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketPlaceCategoryCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketPlaceCategoryCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<StrictHttpResponse<MarketPlaceCategory>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.PostMarketPlaceCategoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategory>;
      })
    );
  }

  /**
   * Creates a MarketPlaceCategory resource.
   *
   * Creates a MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketPlaceCategoryCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketPlaceCategoryCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<MarketPlaceCategory> {

    return this.postMarketPlaceCategoryCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategory>) => r.body as MarketPlaceCategory)
    );
  }

  /**
   * Creates a MarketPlaceCategory resource.
   *
   * Creates a MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketPlaceCategoryCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketPlaceCategoryCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<StrictHttpResponse<MarketPlaceCategory>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.PostMarketPlaceCategoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategory>;
      })
    );
  }

  /**
   * Creates a MarketPlaceCategory resource.
   *
   * Creates a MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketPlaceCategoryCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketPlaceCategoryCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<MarketPlaceCategory> {

    return this.postMarketPlaceCategoryCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategory>) => r.body as MarketPlaceCategory)
    );
  }

  /**
   * Creates a MarketPlaceCategory resource.
   *
   * Creates a MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketPlaceCategoryCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketPlaceCategoryCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<StrictHttpResponse<MarketPlaceCategory>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.PostMarketPlaceCategoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategory>;
      })
    );
  }

  /**
   * Creates a MarketPlaceCategory resource.
   *
   * Creates a MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketPlaceCategoryCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketPlaceCategoryCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<MarketPlaceCategory> {

    return this.postMarketPlaceCategoryCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategory>) => r.body as MarketPlaceCategory)
    );
  }

  /**
   * Path part for operation getMarketPlaceCategoryItem
   */
  static readonly GetMarketPlaceCategoryItemPath = '/api/market_place_categories/{id}';

  /**
   * Retrieves a MarketPlaceCategory resource.
   *
   * Retrieves a MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketPlaceCategoryItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCategoryItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MarketPlaceCategoryReadMarketPlaceCategoryReadParent>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.GetMarketPlaceCategoryItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategoryReadMarketPlaceCategoryReadParent>;
      })
    );
  }

  /**
   * Retrieves a MarketPlaceCategory resource.
   *
   * Retrieves a MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketPlaceCategoryItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCategoryItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<MarketPlaceCategoryReadMarketPlaceCategoryReadParent> {

    return this.getMarketPlaceCategoryItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategoryReadMarketPlaceCategoryReadParent>) => r.body as MarketPlaceCategoryReadMarketPlaceCategoryReadParent)
    );
  }

  /**
   * Retrieves a MarketPlaceCategory resource.
   *
   * Retrieves a MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketPlaceCategoryItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCategoryItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MarketPlaceCategoryReadMarketPlaceCategoryReadParent>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.GetMarketPlaceCategoryItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategoryReadMarketPlaceCategoryReadParent>;
      })
    );
  }

  /**
   * Retrieves a MarketPlaceCategory resource.
   *
   * Retrieves a MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketPlaceCategoryItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCategoryItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<MarketPlaceCategoryReadMarketPlaceCategoryReadParent> {

    return this.getMarketPlaceCategoryItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategoryReadMarketPlaceCategoryReadParent>) => r.body as MarketPlaceCategoryReadMarketPlaceCategoryReadParent)
    );
  }

  /**
   * Path part for operation putMarketPlaceCategoryItem
   */
  static readonly PutMarketPlaceCategoryItemPath = '/api/market_place_categories/{id}';

  /**
   * Replaces the MarketPlaceCategory resource.
   *
   * Replaces the MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketPlaceCategoryItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketPlaceCategoryItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<StrictHttpResponse<MarketPlaceCategory>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.PutMarketPlaceCategoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategory>;
      })
    );
  }

  /**
   * Replaces the MarketPlaceCategory resource.
   *
   * Replaces the MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketPlaceCategoryItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketPlaceCategoryItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<MarketPlaceCategory> {

    return this.putMarketPlaceCategoryItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategory>) => r.body as MarketPlaceCategory)
    );
  }

  /**
   * Replaces the MarketPlaceCategory resource.
   *
   * Replaces the MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketPlaceCategoryItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketPlaceCategoryItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<StrictHttpResponse<MarketPlaceCategory>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.PutMarketPlaceCategoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategory>;
      })
    );
  }

  /**
   * Replaces the MarketPlaceCategory resource.
   *
   * Replaces the MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketPlaceCategoryItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketPlaceCategoryItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<MarketPlaceCategory> {

    return this.putMarketPlaceCategoryItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategory>) => r.body as MarketPlaceCategory)
    );
  }

  /**
   * Replaces the MarketPlaceCategory resource.
   *
   * Replaces the MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketPlaceCategoryItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketPlaceCategoryItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<StrictHttpResponse<MarketPlaceCategory>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.PutMarketPlaceCategoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategory>;
      })
    );
  }

  /**
   * Replaces the MarketPlaceCategory resource.
   *
   * Replaces the MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketPlaceCategoryItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketPlaceCategoryItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<MarketPlaceCategory> {

    return this.putMarketPlaceCategoryItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategory>) => r.body as MarketPlaceCategory)
    );
  }

  /**
   * Replaces the MarketPlaceCategory resource.
   *
   * Replaces the MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketPlaceCategoryItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketPlaceCategoryItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<StrictHttpResponse<MarketPlaceCategory>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.PutMarketPlaceCategoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceCategory>;
      })
    );
  }

  /**
   * Replaces the MarketPlaceCategory resource.
   *
   * Replaces the MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketPlaceCategoryItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketPlaceCategoryItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlaceCategory resource
     */
    body: MarketPlaceCategoryWriteMarketPlaceCategory
  }
): Observable<MarketPlaceCategory> {

    return this.putMarketPlaceCategoryItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceCategory>) => r.body as MarketPlaceCategory)
    );
  }

  /**
   * Path part for operation deleteMarketPlaceCategoryItem
   */
  static readonly DeleteMarketPlaceCategoryItemPath = '/api/market_place_categories/{id}';

  /**
   * Removes the MarketPlaceCategory resource.
   *
   * Removes the MarketPlaceCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMarketPlaceCategoryItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMarketPlaceCategoryItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceCategoryService.DeleteMarketPlaceCategoryItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the MarketPlaceCategory resource.
   *
   * Removes the MarketPlaceCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMarketPlaceCategoryItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMarketPlaceCategoryItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteMarketPlaceCategoryItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
