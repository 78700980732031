import { MarketPlaceCategoryService } from 'src/app/api/services';
import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MarketPlaceCategoryListMarketPlaceCategoryListParent } from 'src/app/api/models';
import { CookieService } from 'ngx-cookie-service';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input() display: boolean = false;
	@Input() menu: boolean = false;
	@Input() home: boolean = false;

	public url: string;
	public cartNumber: number;
	public subCategoriesList: Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>;
	public categoriesList: Array<MarketPlaceCategoryListMarketPlaceCategoryListParent> =
		[];
	public isLogged: boolean;
	public environment = environment;

	constructor(
		private router: Router,
		private categoriesService: MarketPlaceCategoryService,
		private cookieService: CookieService,
		private navController: NavController
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.url = event.url;
				this.visibility();
			}
		});
	}

	ngOnInit() {
		this.getCategories();
		this.updateCartNumber();
		this.isLogged = this.cookieService.get('BEARER_TOKEN') ? true : false;
	}

	openMenu() {
		this.navController.navigateRoot(['/menu']);
	}

	openSubcategory(id: any) {
		this.navController.navigateRoot(['/category', { parentId: id }]);
	}

	getCategories() {
		this.subCategoriesList = [];
		this.categoriesList = [];

		let params = {
			page: 1,
			itemsPerPage: 12,
			'exists[parent]': false,
		};

		this.categoriesService
			.getMarketPlaceCategoryCollection$Json(params)
			.subscribe((response) => {
				this.categoriesList = response;
			});
	}

	visibility() {
		let regEx = /^[/]home/;

		if (regEx.test(this.url)) {
			this.display = true;
		} else {
			this.display = true;
		}
	}

	goToLogin() {
		this.cookieService.get('BEARER_TOKEN')
			? this.navController.navigateRoot(['/user'])
			: this.navController.navigateRoot(['/login']);
	}

	updateCartNumber() {
		if (this.cookieService.get('cartNumber')) {
			this.cartNumber = parseInt(this.cookieService.get('cartNumber'));
		} else {
			this.cartNumber = 0;
		}
	}
}
