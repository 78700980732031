import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { MarketPlaceCategoryListMarketPlaceCategoryListParent } from 'src/app/api/models';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-category-submenu',
	templateUrl: './category-submenu.component.html',
	styleUrls: ['./category-submenu.component.scss'],
})
export class CategorySubmenuComponent implements OnInit {
	@Input()
	categories: Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>;
	@Input() parentId: number;
	@Input() categoryName: string;

	constructor(
		private popCtrl: PopoverController,
		private navController: NavController
	) {}

	ngOnInit() {}

	close() {
		this.popCtrl.getTop().then((popover) => {
			popover.dismiss();
		});
	}

	openSubcategory(parentId: number) {
		this.navController.navigateRoot(['/category', { parentId: parentId }]);
	}
}
