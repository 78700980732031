import { Component, OnInit } from '@angular/core';
import { OrderListOrder } from 'src/app/api/models';
import { OrderService } from 'src/app/api/services';
import { Location } from '@angular/common';

@Component({
	selector: 'app-shipments-status',
	templateUrl: './shipments-status.component.html',
	styleUrls: ['./shipments-status.component.scss'],
})
export class ShipmentsStatusComponent implements OnInit {
	private orders: Array<OrderListOrder>;
	private page = 1;
	public isFirstLoad: boolean = true;
	private areOrders: boolean = false;
	public isLoading: boolean;

	constructor(
		private orderService: OrderService,
		private location: Location
	) {}

	ngOnInit() {
		this.isLoading = true;
		this.orderService.getOrderCollection$Json().subscribe(
			(response) => {
				this.orders = response;
				this.page++;
				if (response.length == 0) {
					this.areOrders = true;
				}
				this.isLoading = false;
			},
			() => {
				this.isLoading = false;
			}
		);
	}

	goBack() {
		this.location.back();
	}

	getPedidos(event) {
		let param;
		param = {
			page: this.page,
		};

		this.orderService
			.getOrderCollection$Json(param)
			.subscribe((response) => {
				if (this.isFirstLoad) {
					event.target.complete();
					this.isFirstLoad = false;
				}
				if (response.length < 1) {
					event.target.disabled = true;
				}
				this.orders.push(...response);
				this.page++;
			});
	}
}
