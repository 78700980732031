import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NavController } from '@ionic/angular';
@Component({
	selector: 'app-order-options',
	templateUrl: './order-options.component.html',
	styleUrls: ['./order-options.component.scss'],
})
export class OrderOptionsComponent implements OnInit {
	public orderId: string = '';

	constructor(
		private route: ActivatedRoute,
		private location: Location,
		private navController: NavController
	) {}

	ngOnInit() {
		this.route.params.subscribe((res) => {
			this.orderId = res.orderId;
		});
	}

	openViewOrder() {
		this.navController.navigateRoot([
			'/order-resume',
			{ orderId: this.orderId },
		]);
	}

	back() {
		this.location.back();
	}
}
