import { Component, OnInit } from '@angular/core';
import { MarketPlaceReadMarketPlace } from 'src/app/api/models';
import { MarketPlaceService } from 'src/app/api/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public activeMarketplaces: Array<MarketPlaceReadMarketPlace>;
  public sliderMarketplaces: Array<MarketPlaceReadMarketPlace>;

  constructor(private marketplaceService: MarketPlaceService) {}

  ngOnInit() {}

  getActiveMarketplaces() {
    let today = new Date().toDateString();
    let params = {
      'activationDate[before]': today,
    };

    this.marketplaceService
      .getMarketPlaceCollection$Json(params)
      .subscribe((respone) => {
        this.activeMarketplaces = respone;
      });
  }

  getisInHome() {
    let params = {
      isInHome: true,
    };

    this.marketplaceService
      .getMarketPlaceCollection$Json(params)
      .subscribe((respone) => {
        this.sliderMarketplaces = respone;
      });
  }
}
