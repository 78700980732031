import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Credentials } from 'src/app/api/models/credentials';
import { TokenService } from 'src/app/api/services';
import { ToastMsgsService } from '../../services/toast-msgs.service';
import { CookieService } from 'ngx-cookie-service';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	public form: FormGroup;
	public isLoading: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private tokenService: TokenService,
		private router: Router,
		private toast: ToastMsgsService,
		private cookieService: CookieService,
		private navController: NavController
	) {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});
	}

	ngOnInit() {
		this.checkLoged();
	}

	checkLoged() {
		if (this.cookieService.get('BEARER_TOKEN')) {
			this.navController.navigateRoot(['/user']);
		}
	}

	submit() {
		this.isLoading = true;
		let credentials: Credentials = {
			email: this.form.get('email')?.value,
			password: this.form.get('password')?.value,
		};

		let param = {
			body: credentials,
		};

		this.tokenService.postCredentialsItem(param).subscribe(
			() => {
				this.isLoading = false;
				this.navController.navigateRoot(['/user']);
			},
			(error) => {
				if (error.error.code === 401) {
					this.toast.errorToast(
						'EL USUARIO O CONTRASEÑA ES INVÁLIDO'
					);
					this.isLoading = false;
				}
			}
		);
	}

	goToRegister() {
		this.navController.navigateRoot(['/register']);
	}

	openHelp() {
		this.navController.navigateRoot(['/help']);
	}

	next() {
		this.navController.navigateRoot(['/user']);
	}

	back() {}
}
