/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Item } from '../models/item';
import { Item as ItemListItem } from '../models/Item-list/item';
import { Item as ItemReadItem } from '../models/Item-read/item';
import { Item as ItemWriteItem } from '../models/Item-write/item';
import { Jsonld as ItemJsonld } from '../models/Item/jsonld';
import { Item as ItemJsonldListItem } from '../models/Item/jsonld-list/item';
import { Item as ItemJsonldReadItem } from '../models/Item/jsonld-read/item';
import { Item as ItemJsonldWriteItem } from '../models/Item/jsonld-write/item';

@Injectable({
  providedIn: 'root',
})
export class ItemService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getItemCollection
   */
  static readonly GetItemCollectionPath = '/api/items';

  /**
   * Retrieves the collection of Item resources.
   *
   * Retrieves the collection of Item resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    price?: number;
    size?: string;
    code?: string;
    'order.number'?: string;
    order?: string;
    'order[]'?: Array<string>;
    'marketPlace.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemListItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.GetItemCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('price', params.price, {"style":"form","explode":false});
      rb.query('size', params.size, {"style":"form","explode":false});
      rb.query('code', params.code, {"style":"form","explode":false});
      rb.query('order.number', params['order.number'], {"style":"form","explode":false});
      rb.query('order', params.order, {"style":"form","explode":false});
      rb.query('order[]', params['order[]'], {"style":"form","explode":true});
      rb.query('marketPlace.name', params['marketPlace.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemListItem>>;
      })
    );
  }

  /**
   * Retrieves the collection of Item resources.
   *
   * Retrieves the collection of Item resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getItemCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    price?: number;
    size?: string;
    code?: string;
    'order.number'?: string;
    order?: string;
    'order[]'?: Array<string>;
    'marketPlace.name'?: string;
    context?: HttpContext
  }
): Observable<Array<ItemListItem>> {

    return this.getItemCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemListItem>>) => r.body as Array<ItemListItem>)
    );
  }

  /**
   * Retrieves the collection of Item resources.
   *
   * Retrieves the collection of Item resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    price?: number;
    size?: string;
    code?: string;
    'order.number'?: string;
    order?: string;
    'order[]'?: Array<string>;
    'marketPlace.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ItemListItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.GetItemCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('price', params.price, {"style":"form","explode":false});
      rb.query('size', params.size, {"style":"form","explode":false});
      rb.query('code', params.code, {"style":"form","explode":false});
      rb.query('order.number', params['order.number'], {"style":"form","explode":false});
      rb.query('order', params.order, {"style":"form","explode":false});
      rb.query('order[]', params['order[]'], {"style":"form","explode":true});
      rb.query('marketPlace.name', params['marketPlace.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemListItem>>;
      })
    );
  }

  /**
   * Retrieves the collection of Item resources.
   *
   * Retrieves the collection of Item resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getItemCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    price?: number;
    size?: string;
    code?: string;
    'order.number'?: string;
    order?: string;
    'order[]'?: Array<string>;
    'marketPlace.name'?: string;
    context?: HttpContext
  }
): Observable<Array<ItemListItem>> {

    return this.getItemCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemListItem>>) => r.body as Array<ItemListItem>)
    );
  }

  /**
   * Path part for operation postItemCollection
   */
  static readonly PostItemCollectionPath = '/api/items';

  /**
   * Creates a Item resource.
   *
   * Creates a Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postItemCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postItemCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Item resource
     */
    body: ItemWriteItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.PostItemCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a Item resource.
   *
   * Creates a Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postItemCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postItemCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Item resource
     */
    body: ItemWriteItem
  }
): Observable<Item> {

    return this.postItemCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a Item resource.
   *
   * Creates a Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postItemCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postItemCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Item resource
     */
    body: ItemWriteItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.PostItemCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a Item resource.
   *
   * Creates a Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postItemCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postItemCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Item resource
     */
    body: ItemWriteItem
  }
): Observable<Item> {

    return this.postItemCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a Item resource.
   *
   * Creates a Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postItemCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postItemCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Item resource
     */
    body: ItemWriteItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.PostItemCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a Item resource.
   *
   * Creates a Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postItemCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postItemCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Item resource
     */
    body: ItemWriteItem
  }
): Observable<Item> {

    return this.postItemCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a Item resource.
   *
   * Creates a Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postItemCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postItemCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Item resource
     */
    body: ItemWriteItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.PostItemCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a Item resource.
   *
   * Creates a Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postItemCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postItemCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Item resource
     */
    body: ItemWriteItem
  }
): Observable<Item> {

    return this.postItemCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Path part for operation getItemItem
   */
  static readonly GetItemItemPath = '/api/items/{id}';

  /**
   * Retrieves a Item resource.
   *
   * Retrieves a Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ItemReadItem>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.GetItemItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ItemReadItem>;
      })
    );
  }

  /**
   * Retrieves a Item resource.
   *
   * Retrieves a Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getItemItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<ItemReadItem> {

    return this.getItemItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ItemReadItem>) => r.body as ItemReadItem)
    );
  }

  /**
   * Retrieves a Item resource.
   *
   * Retrieves a Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ItemReadItem>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.GetItemItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ItemReadItem>;
      })
    );
  }

  /**
   * Retrieves a Item resource.
   *
   * Retrieves a Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getItemItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<ItemReadItem> {

    return this.getItemItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<ItemReadItem>) => r.body as ItemReadItem)
    );
  }

  /**
   * Path part for operation putItemItem
   */
  static readonly PutItemItemPath = '/api/items/{id}';

  /**
   * Replaces the Item resource.
   *
   * Replaces the Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putItemItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putItemItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Item resource
     */
    body: ItemWriteItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.PutItemItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replaces the Item resource.
   *
   * Replaces the Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putItemItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putItemItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Item resource
     */
    body: ItemWriteItem
  }
): Observable<Item> {

    return this.putItemItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replaces the Item resource.
   *
   * Replaces the Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putItemItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putItemItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Item resource
     */
    body: ItemWriteItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.PutItemItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replaces the Item resource.
   *
   * Replaces the Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putItemItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putItemItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Item resource
     */
    body: ItemWriteItem
  }
): Observable<Item> {

    return this.putItemItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replaces the Item resource.
   *
   * Replaces the Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putItemItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putItemItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Item resource
     */
    body: ItemWriteItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.PutItemItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replaces the Item resource.
   *
   * Replaces the Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putItemItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putItemItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Item resource
     */
    body: ItemWriteItem
  }
): Observable<Item> {

    return this.putItemItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replaces the Item resource.
   *
   * Replaces the Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putItemItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putItemItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Item resource
     */
    body: ItemWriteItem
  }
): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.PutItemItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replaces the Item resource.
   *
   * Replaces the Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putItemItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putItemItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Item resource
     */
    body: ItemWriteItem
  }
): Observable<Item> {

    return this.putItemItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Path part for operation deleteItemItem
   */
  static readonly DeleteItemItemPath = '/api/items/{id}';

  /**
   * Removes the Item resource.
   *
   * Removes the Item resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteItemItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteItemItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.DeleteItemItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Item resource.
   *
   * Removes the Item resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteItemItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteItemItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteItemItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
