import {
	Component,
	ElementRef,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserWriteUser } from 'src/app/api/models';
import { UserService } from 'src/app/api/services';
import { ToastMsgsService } from 'src/app/components/services/toast-msgs.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-change-pswd',
	templateUrl: './change-pswd.component.html',
	styleUrls: ['./change-pswd.component.scss'],
})
export class ChangePswdComponent implements OnInit {
	@ViewChild('form') form: ElementRef;

	@Output() newPswd: EventEmitter<any> = new EventEmitter();

	public pswdIncorrect: boolean = false;
	public email;
	public user: UserWriteUser = {
		plainPassword: '',
	};
	public isLoading: boolean;

	constructor(
		private userService: UserService,
		private http: HttpClient,
		private toastService: ToastMsgsService
	) {}

	ngOnInit() {
		this.setEmail();
	}

	controlAccordion() {
		let panel;
		let element;

		element = this.form.nativeElement;

		if (element) {
			panel = element.nextElementSibling;
			element.classList.toggle('active');
		}
		// Display or Hidde accordion content
		this.accordionDisplayToggle(panel);
	}

	accordionDisplayToggle(panel: any) {
		if (panel.style.display === 'block') {
			panel.style.display = 'none';

			panel.style.display = 'none';
		} else {
			panel.style.display = 'block';
		}
	}

	setEmail() {
		this.userService.meUserCollection$Json().subscribe((res) => {
			this.email = res['email'];
		});
	}

	postRecoverPswd() {
		this.isLoading = true;
		let url = environment.apiResetPassword;
		let param = {
			email: this.email,
		};

		this.http.post(url, param).subscribe(
			() => {
				this.toastService.successToast('Revise el buzón de su correo');
				this.isLoading = false;
			},
			() => {
				this.toastService.errorToast('Ha ocurrido un error');
				this.isLoading = false;
			}
		);
	}
}
