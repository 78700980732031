import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
	constructor(private navController: NavController) {}

	ngOnInit() {}

	next() {
		this.navController.navigateRoot(['/order-completed']);
	}
}
