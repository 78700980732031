import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'app-general-info',
	templateUrl: './general-info.component.html',
	styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent implements OnInit {
	constructor(private location: Location) {}

	ngOnInit() {}

	goBack() {
		this.location.back();
	}
}
