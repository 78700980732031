import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastMsgsService } from '../../services/toast-msgs.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-recover-pswd',
	templateUrl: './recover-pswd.component.html',
	styleUrls: ['./recover-pswd.component.scss'],
})
export class RecoverPswdComponent implements OnInit {
	public email: string = '';
	public isLoading: boolean;
	public recoverForm: FormGroup = this.formBuilder.group({
		email: ['', [Validators.required, Validators.email]],
	});

	constructor(
		private http: HttpClient,
		private formBuilder: FormBuilder,
		private toastService: ToastMsgsService,
		private location: Location
	) {}

	ngOnInit() {}

	back() {
		this.location.back();
	}

	postRecoverPswd() {
		this.isLoading = true;

		let url = environment.apiResetPassword;
		let param = {
			email: this.email,
		};

		this.http.post(url, param).subscribe(
			() => {
				this.toastService.successToast('Revise el buzón de su correo');
				this.isLoading = false;
			},
			(err) => {
				this.isLoading = false;
				if (err.status === 404) {
					this.toastService.errorToast('El usuarios no existe');
				} else if (
					err.error.class.includes('TooManyPasswordRequestsException')
				) {
					this.toastService.errorToast(
						'Este usuario ya tiene una solicitud abierta'
					);
				}
			}
		);
	}
}
