/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MarketOrder } from '../models/market-order';
import { MarketOrder as MarketOrderListMarketOrder } from '../models/MarketOrder-list/market-order';
import { MarketOrder as MarketOrderReadMarketOrder } from '../models/MarketOrder-read/market-order';
import { MarketOrder as MarketOrderWriteMarketOrder } from '../models/MarketOrder-write/market-order';
import { Jsonld as MarketOrderJsonld } from '../models/MarketOrder/jsonld';
import { MarketOrder as MarketOrderJsonldListMarketOrder } from '../models/MarketOrder/jsonld-list/market-order';
import { MarketOrder as MarketOrderJsonldReadMarketOrder } from '../models/MarketOrder/jsonld-read/market-order';
import { MarketOrder as MarketOrderJsonldWriteMarketOrder } from '../models/MarketOrder/jsonld-write/market-order';

@Injectable({
  providedIn: 'root',
})
export class MarketOrderService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMarketOrderCollection
   */
  static readonly GetMarketOrderCollectionPath = '/api/market_orders';

  /**
   * Retrieves the collection of MarketOrder resources.
   *
   * Retrieves the collection of MarketOrder resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketOrderCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketOrderCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    order?: string;
    'order[]'?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MarketOrderListMarketOrder>>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.GetMarketOrderCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('order', params.order, {"style":"form","explode":false});
      rb.query('order[]', params['order[]'], {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MarketOrderListMarketOrder>>;
      })
    );
  }

  /**
   * Retrieves the collection of MarketOrder resources.
   *
   * Retrieves the collection of MarketOrder resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketOrderCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketOrderCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    order?: string;
    'order[]'?: Array<string>;
    context?: HttpContext
  }
): Observable<Array<MarketOrderListMarketOrder>> {

    return this.getMarketOrderCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MarketOrderListMarketOrder>>) => r.body as Array<MarketOrderListMarketOrder>)
    );
  }

  /**
   * Retrieves the collection of MarketOrder resources.
   *
   * Retrieves the collection of MarketOrder resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketOrderCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketOrderCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    order?: string;
    'order[]'?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MarketOrderListMarketOrder>>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.GetMarketOrderCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('order', params.order, {"style":"form","explode":false});
      rb.query('order[]', params['order[]'], {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MarketOrderListMarketOrder>>;
      })
    );
  }

  /**
   * Retrieves the collection of MarketOrder resources.
   *
   * Retrieves the collection of MarketOrder resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketOrderCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketOrderCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    order?: string;
    'order[]'?: Array<string>;
    context?: HttpContext
  }
): Observable<Array<MarketOrderListMarketOrder>> {

    return this.getMarketOrderCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MarketOrderListMarketOrder>>) => r.body as Array<MarketOrderListMarketOrder>)
    );
  }

  /**
   * Path part for operation postMarketOrderCollection
   */
  static readonly PostMarketOrderCollectionPath = '/api/market_orders';

  /**
   * Creates a MarketOrder resource.
   *
   * Creates a MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketOrderCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketOrderCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<StrictHttpResponse<MarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.PostMarketOrderCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrder>;
      })
    );
  }

  /**
   * Creates a MarketOrder resource.
   *
   * Creates a MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketOrderCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketOrderCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<MarketOrder> {

    return this.postMarketOrderCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrder>) => r.body as MarketOrder)
    );
  }

  /**
   * Creates a MarketOrder resource.
   *
   * Creates a MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketOrderCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketOrderCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<StrictHttpResponse<MarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.PostMarketOrderCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrder>;
      })
    );
  }

  /**
   * Creates a MarketOrder resource.
   *
   * Creates a MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketOrderCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketOrderCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<MarketOrder> {

    return this.postMarketOrderCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrder>) => r.body as MarketOrder)
    );
  }

  /**
   * Creates a MarketOrder resource.
   *
   * Creates a MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketOrderCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketOrderCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<StrictHttpResponse<MarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.PostMarketOrderCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrder>;
      })
    );
  }

  /**
   * Creates a MarketOrder resource.
   *
   * Creates a MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketOrderCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketOrderCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<MarketOrder> {

    return this.postMarketOrderCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrder>) => r.body as MarketOrder)
    );
  }

  /**
   * Creates a MarketOrder resource.
   *
   * Creates a MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketOrderCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketOrderCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<StrictHttpResponse<MarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.PostMarketOrderCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrder>;
      })
    );
  }

  /**
   * Creates a MarketOrder resource.
   *
   * Creates a MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketOrderCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketOrderCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<MarketOrder> {

    return this.postMarketOrderCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrder>) => r.body as MarketOrder)
    );
  }

  /**
   * Path part for operation getMarketOrderItem
   */
  static readonly GetMarketOrderItemPath = '/api/market_orders/{id}';

  /**
   * Retrieves a MarketOrder resource.
   *
   * Retrieves a MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketOrderItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketOrderItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MarketOrderReadMarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.GetMarketOrderItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrderReadMarketOrder>;
      })
    );
  }

  /**
   * Retrieves a MarketOrder resource.
   *
   * Retrieves a MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketOrderItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketOrderItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<MarketOrderReadMarketOrder> {

    return this.getMarketOrderItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrderReadMarketOrder>) => r.body as MarketOrderReadMarketOrder)
    );
  }

  /**
   * Retrieves a MarketOrder resource.
   *
   * Retrieves a MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketOrderItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketOrderItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MarketOrderReadMarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.GetMarketOrderItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrderReadMarketOrder>;
      })
    );
  }

  /**
   * Retrieves a MarketOrder resource.
   *
   * Retrieves a MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketOrderItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketOrderItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<MarketOrderReadMarketOrder> {

    return this.getMarketOrderItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrderReadMarketOrder>) => r.body as MarketOrderReadMarketOrder)
    );
  }

  /**
   * Path part for operation putMarketOrderItem
   */
  static readonly PutMarketOrderItemPath = '/api/market_orders/{id}';

  /**
   * Replaces the MarketOrder resource.
   *
   * Replaces the MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketOrderItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketOrderItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<StrictHttpResponse<MarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.PutMarketOrderItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrder>;
      })
    );
  }

  /**
   * Replaces the MarketOrder resource.
   *
   * Replaces the MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketOrderItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketOrderItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<MarketOrder> {

    return this.putMarketOrderItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrder>) => r.body as MarketOrder)
    );
  }

  /**
   * Replaces the MarketOrder resource.
   *
   * Replaces the MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketOrderItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketOrderItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<StrictHttpResponse<MarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.PutMarketOrderItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrder>;
      })
    );
  }

  /**
   * Replaces the MarketOrder resource.
   *
   * Replaces the MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketOrderItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketOrderItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<MarketOrder> {

    return this.putMarketOrderItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrder>) => r.body as MarketOrder)
    );
  }

  /**
   * Replaces the MarketOrder resource.
   *
   * Replaces the MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketOrderItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketOrderItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<StrictHttpResponse<MarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.PutMarketOrderItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrder>;
      })
    );
  }

  /**
   * Replaces the MarketOrder resource.
   *
   * Replaces the MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketOrderItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketOrderItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<MarketOrder> {

    return this.putMarketOrderItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrder>) => r.body as MarketOrder)
    );
  }

  /**
   * Replaces the MarketOrder resource.
   *
   * Replaces the MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketOrderItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketOrderItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<StrictHttpResponse<MarketOrder>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.PutMarketOrderItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketOrder>;
      })
    );
  }

  /**
   * Replaces the MarketOrder resource.
   *
   * Replaces the MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketOrderItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketOrderItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketOrder resource
     */
    body: MarketOrderWriteMarketOrder
  }
): Observable<MarketOrder> {

    return this.putMarketOrderItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketOrder>) => r.body as MarketOrder)
    );
  }

  /**
   * Path part for operation deleteMarketOrderItem
   */
  static readonly DeleteMarketOrderItemPath = '/api/market_orders/{id}';

  /**
   * Removes the MarketOrder resource.
   *
   * Removes the MarketOrder resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMarketOrderItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMarketOrderItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MarketOrderService.DeleteMarketOrderItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the MarketOrder resource.
   *
   * Removes the MarketOrder resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMarketOrderItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMarketOrderItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteMarketOrderItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
