import { Component, Input, OnInit } from '@angular/core';
import { Item, Order } from 'src/app/api/models';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/api/services';
@Component({
	selector: 'app-order-resume',
	templateUrl: './order-resume.component.html',
	styleUrls: ['./order-resume.component.scss'],
})
export class OrderResumeComponent implements OnInit {
	public orderId: string;
	public order: any = {
		creationDate: '',
		number: '',
	};
	public items: Array<Item> = [];
	public marketplaceName: string = '';
	public paymentResume: any = {};
	public resume: any = {
		subtotal: 0,
	};
	public isLoading: boolean;

	constructor(
		private route: ActivatedRoute,
		private location: Location,
		private orderService: OrderService
	) {}

	ngOnInit() {
		this.isLoading = true;
		this.route.params.subscribe((res) => {
			this.orderId = res.orderId;
		});

		this.getOrder(this.orderId);
	}

	getMarkeplaceName() {
		this.order.marketPlaces.forEach((market: any, index) => {
			if (index + 1 != this.order.marketPlaces.length)
				this.marketplaceName += market.name + ' ';
			else this.marketplaceName += market.name;
		});
	}

	getOrder(orderId: string) {
		this.orderService.getOrderItem$Json({ id: orderId }).subscribe(
			(res) => {
				console.log(res);
				this.order = res as Order;
				this.getMarkeplaceName();
				this.resume = this.order.orderData;
				this.isLoading = false;
			},
			() => {
				this.isLoading = false;
			}
		);
	}

	back() {
		this.location.back();
	}
}
