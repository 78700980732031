import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastMsgsService {

  constructor(private toastCtrl: ToastController) { }

  errorToast(msg: string) {
    this.toastCtrl.create({
      message: msg,
      duration: 3000,
      cssClass: 'toast-error',
      color: 'danger'
    }).then(
      (toast) => {
        toast.present();
      }
    )
  }

  successToast(msg: string) {
    this.toastCtrl.create({
      message: msg,
      duration: 3000,
      cssClass: 'toast-success',
      color: 'success'
    }).then(
      (toast) => {
        toast.present();
      }
    )
  }

}
