import { Component, Input, OnInit } from '@angular/core';
import {
	InAppBrowser,
	InAppBrowserOptions,
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { MarketPlace } from 'src/app/api/models';
import { WebViewService } from '../../services/web-view.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-marketplace-card',
	templateUrl: './marketplace-card.component.html',
	styleUrls: ['./marketplace-card.component.scss'],
})
export class MarketplaceCardComponent implements OnInit {
	@Input() marketplace: MarketPlace;
	@Input() title: boolean = true;
	public environment = environment;

	constructor(private iab: InAppBrowser) {}

	private regExBack = /.*back/;

	private options: InAppBrowserOptions = {
		zoom: 'no',
		fullscreen: 'yes',
		hideurlbar: 'yes',
		hidenavigationbuttons: 'yes',
		toolbar: 'no',
		useWideViewPort: 'yes',
		location: 'no',
	};

	ngOnInit() {}

	openProxy() {
		let browser = this.iab.create(
			this.marketplace.url,
			'_blank',
			this.options
		);
		browser.on('loadstart').subscribe((event) => {
			if (this.regExBack.test(event.url)) {
				browser.close();
			}
		});
	}
}
