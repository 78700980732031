/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CmsCategory } from '../models/cms-category';
import { Parent as CmsCategoryListCmsCategoryListParent } from '../models/CmsCategory-list/cmsCategory_list/parent';
import { Parent as CmsCategoryReadCmsCategoryReadParent } from '../models/CmsCategory-read/cmsCategory_read/parent';
import { CmsCategory as CmsCategoryWriteCmsCategory } from '../models/CmsCategory-write/cms-category';
import { Jsonld as CmsCategoryJsonld } from '../models/CmsCategory/jsonld';
import { Parent as CmsCategoryJsonldListCmsCategoryListParent } from '../models/CmsCategory/jsonld-list/cmsCategory_list/parent';
import { Parent as CmsCategoryJsonldReadCmsCategoryReadParent } from '../models/CmsCategory/jsonld-read/cmsCategory_read/parent';
import { CmsCategory as CmsCategoryJsonldWriteCmsCategory } from '../models/CmsCategory/jsonld-write/cms-category';

@Injectable({
  providedIn: 'root',
})
export class CmsCategoryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCmsCategoryCollection
   */
  static readonly GetCmsCategoryCollectionPath = '/api/cms_categories';

  /**
   * Retrieves the collection of CmsCategory resources.
   *
   * Retrieves the collection of CmsCategory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCmsCategoryCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCategoryCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    weigth?: number;
    'weigth[]'?: Array<number>;
    'parent.name'?: string;
    'parent.id'?: number;
    'parent.id[]'?: Array<number>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CmsCategoryListCmsCategoryListParent>>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.GetCmsCategoryCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('weigth', params.weigth, {"style":"form","explode":false});
      rb.query('weigth[]', params['weigth[]'], {"style":"form","explode":true});
      rb.query('parent.name', params['parent.name'], {"style":"form","explode":false});
      rb.query('parent.id', params['parent.id'], {"style":"form","explode":false});
      rb.query('parent.id[]', params['parent.id[]'], {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CmsCategoryListCmsCategoryListParent>>;
      })
    );
  }

  /**
   * Retrieves the collection of CmsCategory resources.
   *
   * Retrieves the collection of CmsCategory resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCmsCategoryCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCategoryCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    weigth?: number;
    'weigth[]'?: Array<number>;
    'parent.name'?: string;
    'parent.id'?: number;
    'parent.id[]'?: Array<number>;
    context?: HttpContext
  }
): Observable<Array<CmsCategoryListCmsCategoryListParent>> {

    return this.getCmsCategoryCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CmsCategoryListCmsCategoryListParent>>) => r.body as Array<CmsCategoryListCmsCategoryListParent>)
    );
  }

  /**
   * Retrieves the collection of CmsCategory resources.
   *
   * Retrieves the collection of CmsCategory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCmsCategoryCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCategoryCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    weigth?: number;
    'weigth[]'?: Array<number>;
    'parent.name'?: string;
    'parent.id'?: number;
    'parent.id[]'?: Array<number>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CmsCategoryListCmsCategoryListParent>>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.GetCmsCategoryCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('weigth', params.weigth, {"style":"form","explode":false});
      rb.query('weigth[]', params['weigth[]'], {"style":"form","explode":true});
      rb.query('parent.name', params['parent.name'], {"style":"form","explode":false});
      rb.query('parent.id', params['parent.id'], {"style":"form","explode":false});
      rb.query('parent.id[]', params['parent.id[]'], {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CmsCategoryListCmsCategoryListParent>>;
      })
    );
  }

  /**
   * Retrieves the collection of CmsCategory resources.
   *
   * Retrieves the collection of CmsCategory resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCmsCategoryCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCategoryCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    weigth?: number;
    'weigth[]'?: Array<number>;
    'parent.name'?: string;
    'parent.id'?: number;
    'parent.id[]'?: Array<number>;
    context?: HttpContext
  }
): Observable<Array<CmsCategoryListCmsCategoryListParent>> {

    return this.getCmsCategoryCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CmsCategoryListCmsCategoryListParent>>) => r.body as Array<CmsCategoryListCmsCategoryListParent>)
    );
  }

  /**
   * Path part for operation postCmsCategoryCollection
   */
  static readonly PostCmsCategoryCollectionPath = '/api/cms_categories';

  /**
   * Creates a CmsCategory resource.
   *
   * Creates a CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCmsCategoryCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCmsCategoryCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<StrictHttpResponse<CmsCategory>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.PostCmsCategoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategory>;
      })
    );
  }

  /**
   * Creates a CmsCategory resource.
   *
   * Creates a CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCmsCategoryCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCmsCategoryCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<CmsCategory> {

    return this.postCmsCategoryCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategory>) => r.body as CmsCategory)
    );
  }

  /**
   * Creates a CmsCategory resource.
   *
   * Creates a CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCmsCategoryCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCmsCategoryCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<StrictHttpResponse<CmsCategory>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.PostCmsCategoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategory>;
      })
    );
  }

  /**
   * Creates a CmsCategory resource.
   *
   * Creates a CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCmsCategoryCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCmsCategoryCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<CmsCategory> {

    return this.postCmsCategoryCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategory>) => r.body as CmsCategory)
    );
  }

  /**
   * Creates a CmsCategory resource.
   *
   * Creates a CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCmsCategoryCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postCmsCategoryCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<StrictHttpResponse<CmsCategory>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.PostCmsCategoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategory>;
      })
    );
  }

  /**
   * Creates a CmsCategory resource.
   *
   * Creates a CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCmsCategoryCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postCmsCategoryCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<CmsCategory> {

    return this.postCmsCategoryCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategory>) => r.body as CmsCategory)
    );
  }

  /**
   * Creates a CmsCategory resource.
   *
   * Creates a CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCmsCategoryCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postCmsCategoryCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<StrictHttpResponse<CmsCategory>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.PostCmsCategoryCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategory>;
      })
    );
  }

  /**
   * Creates a CmsCategory resource.
   *
   * Creates a CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCmsCategoryCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postCmsCategoryCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<CmsCategory> {

    return this.postCmsCategoryCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategory>) => r.body as CmsCategory)
    );
  }

  /**
   * Path part for operation getCmsCategoryItem
   */
  static readonly GetCmsCategoryItemPath = '/api/cms_categories/{id}';

  /**
   * Retrieves a CmsCategory resource.
   *
   * Retrieves a CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCmsCategoryItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCategoryItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CmsCategoryReadCmsCategoryReadParent>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.GetCmsCategoryItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategoryReadCmsCategoryReadParent>;
      })
    );
  }

  /**
   * Retrieves a CmsCategory resource.
   *
   * Retrieves a CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCmsCategoryItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCategoryItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<CmsCategoryReadCmsCategoryReadParent> {

    return this.getCmsCategoryItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategoryReadCmsCategoryReadParent>) => r.body as CmsCategoryReadCmsCategoryReadParent)
    );
  }

  /**
   * Retrieves a CmsCategory resource.
   *
   * Retrieves a CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCmsCategoryItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCategoryItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CmsCategoryReadCmsCategoryReadParent>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.GetCmsCategoryItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategoryReadCmsCategoryReadParent>;
      })
    );
  }

  /**
   * Retrieves a CmsCategory resource.
   *
   * Retrieves a CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCmsCategoryItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCategoryItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<CmsCategoryReadCmsCategoryReadParent> {

    return this.getCmsCategoryItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategoryReadCmsCategoryReadParent>) => r.body as CmsCategoryReadCmsCategoryReadParent)
    );
  }

  /**
   * Path part for operation putCmsCategoryItem
   */
  static readonly PutCmsCategoryItemPath = '/api/cms_categories/{id}';

  /**
   * Replaces the CmsCategory resource.
   *
   * Replaces the CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCmsCategoryItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCmsCategoryItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<StrictHttpResponse<CmsCategory>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.PutCmsCategoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategory>;
      })
    );
  }

  /**
   * Replaces the CmsCategory resource.
   *
   * Replaces the CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCmsCategoryItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCmsCategoryItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<CmsCategory> {

    return this.putCmsCategoryItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategory>) => r.body as CmsCategory)
    );
  }

  /**
   * Replaces the CmsCategory resource.
   *
   * Replaces the CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCmsCategoryItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCmsCategoryItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<StrictHttpResponse<CmsCategory>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.PutCmsCategoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategory>;
      })
    );
  }

  /**
   * Replaces the CmsCategory resource.
   *
   * Replaces the CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCmsCategoryItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCmsCategoryItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<CmsCategory> {

    return this.putCmsCategoryItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategory>) => r.body as CmsCategory)
    );
  }

  /**
   * Replaces the CmsCategory resource.
   *
   * Replaces the CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCmsCategoryItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCmsCategoryItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<StrictHttpResponse<CmsCategory>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.PutCmsCategoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategory>;
      })
    );
  }

  /**
   * Replaces the CmsCategory resource.
   *
   * Replaces the CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCmsCategoryItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCmsCategoryItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<CmsCategory> {

    return this.putCmsCategoryItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategory>) => r.body as CmsCategory)
    );
  }

  /**
   * Replaces the CmsCategory resource.
   *
   * Replaces the CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCmsCategoryItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCmsCategoryItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<StrictHttpResponse<CmsCategory>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.PutCmsCategoryItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsCategory>;
      })
    );
  }

  /**
   * Replaces the CmsCategory resource.
   *
   * Replaces the CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCmsCategoryItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCmsCategoryItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated CmsCategory resource
     */
    body: CmsCategoryWriteCmsCategory
  }
): Observable<CmsCategory> {

    return this.putCmsCategoryItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<CmsCategory>) => r.body as CmsCategory)
    );
  }

  /**
   * Path part for operation deleteCmsCategoryItem
   */
  static readonly DeleteCmsCategoryItemPath = '/api/cms_categories/{id}';

  /**
   * Removes the CmsCategory resource.
   *
   * Removes the CmsCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCmsCategoryItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCmsCategoryItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CmsCategoryService.DeleteCmsCategoryItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the CmsCategory resource.
   *
   * Removes the CmsCategory resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCmsCategoryItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCmsCategoryItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteCmsCategoryItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
