import { Component, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { MarketPlace } from './api/models';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  @Output() marketplaces: MarketPlace;
  public showCookies: boolean = true
  public cookie: any;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService
  ) {
    translate.setDefaultLang('es-ES');

  }

  ngOnInit(){
    if(this.cookieService.get('accepted_cookies')=="true"){
      this.showCookies=!this.showCookies
    }
  }
}
