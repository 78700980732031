/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Variable } from '../models/variable';
import { Variable as VariableListVariable } from '../models/Variable-list/variable';
import { Variable as VariableReadVariable } from '../models/Variable-read/variable';
import { Variable as VariableWriteVariable } from '../models/Variable-write/variable';
import { Jsonld as VariableJsonld } from '../models/Variable/jsonld';
import { Variable as VariableJsonldListVariable } from '../models/Variable/jsonld-list/variable';
import { Variable as VariableJsonldReadVariable } from '../models/Variable/jsonld-read/variable';
import { Variable as VariableJsonldWriteVariable } from '../models/Variable/jsonld-write/variable';

@Injectable({
  providedIn: 'root',
})
export class VariableService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getVariableCollection
   */
  static readonly GetVariableCollectionPath = '/api/variables';

  /**
   * Retrieves the collection of Variable resources.
   *
   * Retrieves the collection of Variable resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVariableCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVariableCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    value?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<VariableListVariable>>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.GetVariableCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('value', params.value, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VariableListVariable>>;
      })
    );
  }

  /**
   * Retrieves the collection of Variable resources.
   *
   * Retrieves the collection of Variable resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVariableCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVariableCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    value?: string;
    context?: HttpContext
  }
): Observable<Array<VariableListVariable>> {

    return this.getVariableCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VariableListVariable>>) => r.body as Array<VariableListVariable>)
    );
  }

  /**
   * Retrieves the collection of Variable resources.
   *
   * Retrieves the collection of Variable resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVariableCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVariableCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    value?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<VariableListVariable>>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.GetVariableCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('value', params.value, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VariableListVariable>>;
      })
    );
  }

  /**
   * Retrieves the collection of Variable resources.
   *
   * Retrieves the collection of Variable resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVariableCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVariableCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    description?: string;
    value?: string;
    context?: HttpContext
  }
): Observable<Array<VariableListVariable>> {

    return this.getVariableCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VariableListVariable>>) => r.body as Array<VariableListVariable>)
    );
  }

  /**
   * Path part for operation postVariableCollection
   */
  static readonly PostVariableCollectionPath = '/api/variables';

  /**
   * Creates a Variable resource.
   *
   * Creates a Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVariableCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postVariableCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<StrictHttpResponse<Variable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.PostVariableCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Variable>;
      })
    );
  }

  /**
   * Creates a Variable resource.
   *
   * Creates a Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVariableCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postVariableCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<Variable> {

    return this.postVariableCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Variable>) => r.body as Variable)
    );
  }

  /**
   * Creates a Variable resource.
   *
   * Creates a Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVariableCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postVariableCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<StrictHttpResponse<Variable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.PostVariableCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Variable>;
      })
    );
  }

  /**
   * Creates a Variable resource.
   *
   * Creates a Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVariableCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postVariableCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<Variable> {

    return this.postVariableCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Variable>) => r.body as Variable)
    );
  }

  /**
   * Creates a Variable resource.
   *
   * Creates a Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVariableCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postVariableCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<StrictHttpResponse<Variable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.PostVariableCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Variable>;
      })
    );
  }

  /**
   * Creates a Variable resource.
   *
   * Creates a Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVariableCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postVariableCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<Variable> {

    return this.postVariableCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Variable>) => r.body as Variable)
    );
  }

  /**
   * Creates a Variable resource.
   *
   * Creates a Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVariableCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postVariableCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<StrictHttpResponse<Variable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.PostVariableCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Variable>;
      })
    );
  }

  /**
   * Creates a Variable resource.
   *
   * Creates a Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVariableCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postVariableCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<Variable> {

    return this.postVariableCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Variable>) => r.body as Variable)
    );
  }

  /**
   * Path part for operation getVariableItem
   */
  static readonly GetVariableItemPath = '/api/variables/{id}';

  /**
   * Retrieves a Variable resource.
   *
   * Retrieves a Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVariableItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVariableItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<VariableReadVariable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.GetVariableItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VariableReadVariable>;
      })
    );
  }

  /**
   * Retrieves a Variable resource.
   *
   * Retrieves a Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVariableItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVariableItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<VariableReadVariable> {

    return this.getVariableItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<VariableReadVariable>) => r.body as VariableReadVariable)
    );
  }

  /**
   * Retrieves a Variable resource.
   *
   * Retrieves a Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVariableItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVariableItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<VariableReadVariable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.GetVariableItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VariableReadVariable>;
      })
    );
  }

  /**
   * Retrieves a Variable resource.
   *
   * Retrieves a Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVariableItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVariableItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<VariableReadVariable> {

    return this.getVariableItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<VariableReadVariable>) => r.body as VariableReadVariable)
    );
  }

  /**
   * Path part for operation putVariableItem
   */
  static readonly PutVariableItemPath = '/api/variables/{id}';

  /**
   * Replaces the Variable resource.
   *
   * Replaces the Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putVariableItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putVariableItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<StrictHttpResponse<Variable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.PutVariableItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Variable>;
      })
    );
  }

  /**
   * Replaces the Variable resource.
   *
   * Replaces the Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putVariableItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putVariableItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<Variable> {

    return this.putVariableItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Variable>) => r.body as Variable)
    );
  }

  /**
   * Replaces the Variable resource.
   *
   * Replaces the Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putVariableItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putVariableItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<StrictHttpResponse<Variable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.PutVariableItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Variable>;
      })
    );
  }

  /**
   * Replaces the Variable resource.
   *
   * Replaces the Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putVariableItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putVariableItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<Variable> {

    return this.putVariableItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Variable>) => r.body as Variable)
    );
  }

  /**
   * Replaces the Variable resource.
   *
   * Replaces the Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putVariableItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putVariableItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<StrictHttpResponse<Variable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.PutVariableItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Variable>;
      })
    );
  }

  /**
   * Replaces the Variable resource.
   *
   * Replaces the Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putVariableItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putVariableItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<Variable> {

    return this.putVariableItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Variable>) => r.body as Variable)
    );
  }

  /**
   * Replaces the Variable resource.
   *
   * Replaces the Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putVariableItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putVariableItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<StrictHttpResponse<Variable>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.PutVariableItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Variable>;
      })
    );
  }

  /**
   * Replaces the Variable resource.
   *
   * Replaces the Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putVariableItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putVariableItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Variable resource
     */
    body: VariableWriteVariable
  }
): Observable<Variable> {

    return this.putVariableItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Variable>) => r.body as Variable)
    );
  }

  /**
   * Path part for operation deleteVariableItem
   */
  static readonly DeleteVariableItemPath = '/api/variables/{id}';

  /**
   * Removes the Variable resource.
   *
   * Removes the Variable resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteVariableItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVariableItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VariableService.DeleteVariableItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Variable resource.
   *
   * Removes the Variable resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteVariableItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVariableItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteVariableItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
