import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CmsService } from 'src/app/api/services/cms.service';
@Component({
	selector: 'app-child-category',
	templateUrl: './child-category.component.html',
	styleUrls: ['./child-category.component.scss'],
})
export class ChildCategoryComponent implements OnInit, AfterViewInit {
	public categoryName: string;
	public listSubcategories: any;

	constructor(
		private cmsService: CmsService,
		private location: Location,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.categoryName = params['categoryName'];
		});
	}

	ngAfterViewInit() {
		this.getCategories();
	}

	goBack() {
		this.location.back();
	}

	getCategories() {
		let params = {
			'category.name': this.categoryName,
		};

		this.cmsService.getCmsCollection$Json(params).subscribe((res) => {
			this.listSubcategories = res;
		});
	}
}
