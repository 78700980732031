import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserWriteUser } from 'src/app/api/models';
import { UserService } from 'src/app/api/services';
import { TokenService } from 'src/app/api/services';
import { ToastMsgsService } from '../../services/toast-msgs.service';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
	public pswdIncorrect: boolean = false;

	public registerForm: FormGroup;
	public user: UserWriteUser;
	public isLoading: boolean;
	public commercial: boolean = false;
	public cookies: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService,
		private tokenService: TokenService,
		private toast: ToastMsgsService,
		private navController: NavController
	) {
		this.registerForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			name: ['', Validators.required],
			pswd: ['', Validators.required],
			pswd2: ['', Validators.required],
			commercial: [false],
			cookies: [false, Validators.required],
		});

		this.user = {
			name: '',
			email: '',
			plainPassword: '',
		};
	}

	ngOnInit() {}

	checkPaswords() {
		let pswd1 = this.registerForm.get('pswd')?.value;
		let pswd2 = this.registerForm.get('pswd2')?.value;

		if (pswd1 != pswd2) {
			this.pswdIncorrect = true;
		} else {
			this.pswdIncorrect = false;
		}
	}

	submit() {
		this.isLoading = true;
		if (this.pswdIncorrect) {
			return;
		}

		this.user.email = this.registerForm.get('email')?.value;
		this.user.name = this.registerForm.get('name')?.value;
		this.user.plainPassword = this.registerForm.get('pswd')?.value;
		this.user.commercialCommunications = this.commercial;
		this.user.acceptedCookies = this.cookies;

		let registerPayload = {
			body: this.user,
		};

		let loginPayload = {
			body: {
				email: this.user.email,
				password: this.user.plainPassword,
			},
		};

		this.userService
			.registerUserCollection$Json$Json(registerPayload)
			.subscribe(
				() => {
					this.tokenService
						.postCredentialsItem(loginPayload)
						.subscribe(() => {
							this.isLoading = false;
							this.navController.navigateRoot(['/user']);
						});
				},
				(error) => {
					if (error.status == '409') {
						this.toast.errorToast(
							'HAY UN USUARIO REGISTRADO CON ESE CORREO'
						);
					}
					this.isLoading = false;
				}
			);
	}

	goback() {
		history.back();
	}

	updateCommercial(event: any) {
		this.commercial = !event.srcElement.checked;
	}

	updateCookies(event: any) {
		this.cookies = !event.srcElement.checked;
	}
}
