import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-cart-tab',
	templateUrl: './cart-tab.component.html',
	styleUrls: ['./cart-tab.component.scss'],
})
export class CartTabComponent implements OnInit {
	constructor(
		private iab: InAppBrowser,
		private navController: NavController
	) {}

	ngOnInit() {}

	openBrowser() {
		let browser = this.iab.create(environment.middleEndUrl, '_blank');

		browser.on('exit').subscribe(() => {
			this.navController.navigateRoot(['/search']);
		});
	}
}
