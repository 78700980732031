import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/api/services';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from 'src/app/api/services';

@Component({
  selector: 'app-cookieBanner',
  templateUrl: './cookieBanner.component.html',
  styleUrls: ['./cookieBanner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  public showCookies = true;
  public message: any;

  constructor(
    private cmsService: CmsService,
    private tokenService: TokenService,
		private cookieService: CookieService

  ) { }

  ngOnInit() {
    this.cmsService
      .getCmsItem$Json({ id: environment.cmsBannerID })
      .subscribe((res) => {
        this.message = res.content;        
      });
  }

  close(){
    this.showCookies=!this.showCookies;
    this.cookieService.set(
      'accepted_cookies',
      "false",
      new Date('2100-12-31'),
      '/',
      environment.cookieDomain
    );
  }

  accept(){
    this.showCookies=!this.showCookies;
    this.cookieService.set(
      'accepted_cookies',
      "true",
      new Date('2100-12-31'),
      '/',
      environment.cookieDomain
    );  }

}
