/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Banner } from '../models/banner';
import { Banner as BannerListBanner } from '../models/Banner-list/banner';
import { Banner as BannerReadBanner } from '../models/Banner-read/banner';
import { Banner as BannerWriteBanner } from '../models/Banner-write/banner';
import { Jsonld as BannerJsonld } from '../models/Banner/jsonld';
import { Banner as BannerJsonldListBanner } from '../models/Banner/jsonld-list/banner';
import { Banner as BannerJsonldReadBanner } from '../models/Banner/jsonld-read/banner';
import { Banner as BannerJsonldWriteBanner } from '../models/Banner/jsonld-write/banner';

@Injectable({
  providedIn: 'root',
})
export class BannerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getBannerCollection
   */
  static readonly GetBannerCollectionPath = '/api/banners';

  /**
   * Retrieves the collection of Banner resources.
   *
   * Retrieves the collection of Banner resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBannerCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBannerCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    isInHome?: boolean;
    'image.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<BannerListBanner>>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.GetBannerCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('isInHome', params.isInHome, {"style":"form","explode":false});
      rb.query('image.name', params['image.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BannerListBanner>>;
      })
    );
  }

  /**
   * Retrieves the collection of Banner resources.
   *
   * Retrieves the collection of Banner resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBannerCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBannerCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    isInHome?: boolean;
    'image.name'?: string;
    context?: HttpContext
  }
): Observable<Array<BannerListBanner>> {

    return this.getBannerCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BannerListBanner>>) => r.body as Array<BannerListBanner>)
    );
  }

  /**
   * Retrieves the collection of Banner resources.
   *
   * Retrieves the collection of Banner resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBannerCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBannerCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    isInHome?: boolean;
    'image.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<BannerListBanner>>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.GetBannerCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('isInHome', params.isInHome, {"style":"form","explode":false});
      rb.query('image.name', params['image.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BannerListBanner>>;
      })
    );
  }

  /**
   * Retrieves the collection of Banner resources.
   *
   * Retrieves the collection of Banner resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBannerCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBannerCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    isInHome?: boolean;
    'image.name'?: string;
    context?: HttpContext
  }
): Observable<Array<BannerListBanner>> {

    return this.getBannerCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BannerListBanner>>) => r.body as Array<BannerListBanner>)
    );
  }

  /**
   * Path part for operation postBannerCollection
   */
  static readonly PostBannerCollectionPath = '/api/banners';

  /**
   * Creates a Banner resource.
   *
   * Creates a Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBannerCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postBannerCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<StrictHttpResponse<Banner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.PostBannerCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Banner>;
      })
    );
  }

  /**
   * Creates a Banner resource.
   *
   * Creates a Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBannerCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postBannerCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<Banner> {

    return this.postBannerCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Banner>) => r.body as Banner)
    );
  }

  /**
   * Creates a Banner resource.
   *
   * Creates a Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBannerCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postBannerCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<StrictHttpResponse<Banner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.PostBannerCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Banner>;
      })
    );
  }

  /**
   * Creates a Banner resource.
   *
   * Creates a Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBannerCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postBannerCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<Banner> {

    return this.postBannerCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Banner>) => r.body as Banner)
    );
  }

  /**
   * Creates a Banner resource.
   *
   * Creates a Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBannerCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postBannerCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<StrictHttpResponse<Banner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.PostBannerCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Banner>;
      })
    );
  }

  /**
   * Creates a Banner resource.
   *
   * Creates a Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBannerCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postBannerCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<Banner> {

    return this.postBannerCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Banner>) => r.body as Banner)
    );
  }

  /**
   * Creates a Banner resource.
   *
   * Creates a Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBannerCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postBannerCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<StrictHttpResponse<Banner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.PostBannerCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Banner>;
      })
    );
  }

  /**
   * Creates a Banner resource.
   *
   * Creates a Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBannerCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postBannerCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<Banner> {

    return this.postBannerCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Banner>) => r.body as Banner)
    );
  }

  /**
   * Path part for operation getBannerItem
   */
  static readonly GetBannerItemPath = '/api/banners/{id}';

  /**
   * Retrieves a Banner resource.
   *
   * Retrieves a Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBannerItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBannerItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BannerReadBanner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.GetBannerItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BannerReadBanner>;
      })
    );
  }

  /**
   * Retrieves a Banner resource.
   *
   * Retrieves a Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBannerItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBannerItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<BannerReadBanner> {

    return this.getBannerItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BannerReadBanner>) => r.body as BannerReadBanner)
    );
  }

  /**
   * Retrieves a Banner resource.
   *
   * Retrieves a Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBannerItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBannerItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BannerReadBanner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.GetBannerItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BannerReadBanner>;
      })
    );
  }

  /**
   * Retrieves a Banner resource.
   *
   * Retrieves a Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBannerItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBannerItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<BannerReadBanner> {

    return this.getBannerItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<BannerReadBanner>) => r.body as BannerReadBanner)
    );
  }

  /**
   * Path part for operation putBannerItem
   */
  static readonly PutBannerItemPath = '/api/banners/{id}';

  /**
   * Replaces the Banner resource.
   *
   * Replaces the Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putBannerItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putBannerItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<StrictHttpResponse<Banner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.PutBannerItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Banner>;
      })
    );
  }

  /**
   * Replaces the Banner resource.
   *
   * Replaces the Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putBannerItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putBannerItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<Banner> {

    return this.putBannerItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Banner>) => r.body as Banner)
    );
  }

  /**
   * Replaces the Banner resource.
   *
   * Replaces the Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putBannerItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putBannerItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<StrictHttpResponse<Banner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.PutBannerItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Banner>;
      })
    );
  }

  /**
   * Replaces the Banner resource.
   *
   * Replaces the Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putBannerItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putBannerItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<Banner> {

    return this.putBannerItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Banner>) => r.body as Banner)
    );
  }

  /**
   * Replaces the Banner resource.
   *
   * Replaces the Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putBannerItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putBannerItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<StrictHttpResponse<Banner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.PutBannerItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Banner>;
      })
    );
  }

  /**
   * Replaces the Banner resource.
   *
   * Replaces the Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putBannerItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putBannerItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<Banner> {

    return this.putBannerItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Banner>) => r.body as Banner)
    );
  }

  /**
   * Replaces the Banner resource.
   *
   * Replaces the Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putBannerItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putBannerItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<StrictHttpResponse<Banner>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.PutBannerItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Banner>;
      })
    );
  }

  /**
   * Replaces the Banner resource.
   *
   * Replaces the Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putBannerItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putBannerItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Banner resource
     */
    body: BannerWriteBanner
  }
): Observable<Banner> {

    return this.putBannerItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Banner>) => r.body as Banner)
    );
  }

  /**
   * Path part for operation deleteBannerItem
   */
  static readonly DeleteBannerItemPath = '/api/banners/{id}';

  /**
   * Removes the Banner resource.
   *
   * Removes the Banner resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBannerItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBannerItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BannerService.DeleteBannerItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Banner resource.
   *
   * Removes the Banner resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteBannerItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBannerItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteBannerItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
