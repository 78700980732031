import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { User } from 'src/app/api/models';
import { Card } from 'src/app/api/models/Card-list/card';
import { UserService } from 'src/app/api/services';
import { CardService } from 'src/app/api/services/card.service';
import { CookieService } from 'ngx-cookie-service';
import { PopoverController } from '@ionic/angular';
import { ConfirmationPopoverComponent } from 'src/app/components/popovers/confirmation-popover/confirmation-popover.component';
import { ToastMsgsService } from 'src/app/components/services/toast-msgs.service';

@Component({
	selector: 'app-payment-details',
	templateUrl: './payment-details.component.html',
	styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent implements OnInit {
	@ViewChild('radioGroup') radioGroup: any;

	public cards: Array<Card> = [];
	public other: boolean = true;
	public logued: boolean = false;
	public default: string = '';
	public userLoged: User = {};
	public noCards: boolean = false;
	public isLoading: boolean;

	@Output() selectedCard: EventEmitter<Card | null> =
		new EventEmitter<Card | null>();
	@Output() saveCard: EventEmitter<boolean> = new EventEmitter<boolean>(
		false
	);

	constructor(
		private cardService: CardService,
		private userService: UserService,
		private cookieService: CookieService,
		private popoverController: PopoverController,
		private toastService: ToastMsgsService
	) {}

	ngOnInit(): void {
		this.isLoading = true;
		this.getCards();
		this.isLogued();
		this.getDefaultCard();
	}

	getCards(): void {
		if (this.cookieService.get('BEARER_TOKEN')) {
			this.cardService.getCardCollection$Json({ active: true }).subscribe(
				(response) => {
					this.cards = response;
					if (this.cards.length == 0) {
						this.noCards = true;
					}
					this.isLoading = false;
				},
				() => {
					this.isLoading = false;
				}
			);
		}
	}

	getDefaultCard(): void {
		this.userService.meUserCollection$Json().subscribe((user) => {
			console.log({ useR: user });
			this.userLoged = user as User;
			if (this.userLoged.defaultCard) {
				let iriCard: any = this.userLoged.defaultCard;
				iriCard = iriCard.split('/');
				this.default = iriCard[iriCard.length - 1];
				this.radioGroup.value = this.default;
			}
		});
	}

	reset(event: any) {
		let newId = event.srcElement.value;

		let user: User = {
			defaultCard: '/api/cards/' + newId,
		};

		let param = {
			id: this.userLoged.id.toString(),
			body: user,
		};

		this.userService.putUserItem$Json$Json(param).subscribe((response) => {
			console.log(response);
		});
	}

	isLogued() {
		this.logued = !!this.cookieService.get('BEARER_TOKEN');
	}

	openAccordion(event?: any) {
		let panel;
		let element;

		if (event) {
			element = event.srcElement;
		}

		if (element) {
			panel = element.nextElementSibling;
			element.classList.toggle('active');
		}

		if (panel.style.display === 'block') {
			panel.style.display = 'none';
		} else {
			panel.style.display = 'block';
		}
	}

	removePopover(cardId: number) {
		this.popoverController
			.create({
				component: ConfirmationPopoverComponent,
				componentProps: {
					callback: () => {
						this.remove(cardId);
					},
					message:
						'¿ESTAS SEGURO DE QUE QUIERES ELIMINAR ESTA TAJETA?',
				},
			})
			.then((popover) => {
				popover.present();
			});
	}

	remove(cardId: number) {
		let params = {
			id: cardId.toString(),
			body: {
				active: false,
			},
		};

		this.cardService.putCardItem$Json$Json(params).subscribe(
			() => {
				this.popoverController.getTop().then((popover) => {
					popover.dismiss();
					this.getCards();
				});
			},
			() => {
				this.toastService.errorToast('Se ha producido un error');
			}
		);
	}
}
