import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
	MarketPlaceCategoryListMarketPlaceCategoryListParent,
	MarketPlaceReadMarketPlace,
} from 'src/app/api/models';
import { MarketPlaceService } from 'src/app/api/services';
import { Location } from '@angular/common';
import SwiperCore from 'swiper';

SwiperCore.use([]);

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
	@Input() parentId: string;
	public isLoading: boolean;
	public page: number = 1;
	public id: string = '';
	public parent: string;
	public navigationHistory: Array<string>;
	public subCategoriesList: Array<MarketPlaceCategoryListMarketPlaceCategoryListParent>;
	public marketplacesList: Array<MarketPlaceReadMarketPlace> = [];

	constructor(
		private marketplaceService: MarketPlaceService,
		private modalCtrl: ModalController,
		private activeRoute: ActivatedRoute,
		private location: Location
	) {
		this.navigationHistory = [];
	}

	ngOnInit() {
		this.activeRoute.params.subscribe((res) => {
			console.log({ activeRoute: res });
			res ? (this.id = res.parentId) : this.id;
			this.getSubcategories(this.id);
		});

		this.parentId
			? this.getSubcategories(this.parentId)
			: this.getSubcategories(this.id);
	}

	getMarketplaces(isFirstLoad, event) {
		let param;
		console.log({ parentId: this.parentId });
		this.parentId ? (this.id = this.parentId) : this.id;
		if (this.id === '') {
			param = {
				page: this.page,
				itemsPerPage: 14,
			};
		} else {
			param = {
				categories: this.id,
				page: this.page,
				itemsPerPage: 14,
			};
		}

		this.marketplaceService
			.getMarketPlaceCollection$Json(param)
			.subscribe((response) => {
				console.log({ res: response });
				if (isFirstLoad) {
					event.target.complete();
				}

				if (event && response.length < 1) {
					event.target.disabled = true;
				}

				let loadedMarkets = this.marketplacesList.map((res) => {
					return res.id;
				});

				this.marketplacesList.push(
					...response.filter((market) => {
						return !loadedMarkets.includes(market.id);
					})
				);
				this.page++;
				this.isLoading = false;
			});
	}

	getSubcategories(id: string) {
		this.isLoading = true;
		this.subCategoriesList = [];
		this.marketplacesList = [];
		this.page = 1;

		let param;
		if (id == '') {
			param = {
				page: this.page,
				itemsPerPage: 14,
			};
		} else {
			param = {
				page: this.page,
				itemsPerPage: 14,
				categories: id,
			};
		}

		if (this.navigationHistory[this.navigationHistory.length - 1] !== id) {
			this.navigationHistory.push(id);
		}

		this.marketplaceService
			.getMarketPlaceCollection$Json(param)
			.subscribe((response) => {
				this.subCategoriesList = response;
			});
		this.getMarketplaces(false, '');
	}

	isOnTop() {
		return (
			this.navigationHistory[this.navigationHistory.length - 1] !==
			this.id
		);
	}

	back() {
		if (!this.isOnTop()) {
			this.modalCtrl.dismiss();
		}

		this.navigationHistory.pop();
		let lastPosition =
			this.navigationHistory[this.navigationHistory.length - 1];
		this.getSubcategories(lastPosition);
	}

	close() {
		this.location.back();
	}

	whoIsParent() {
		this.parent = this.subCategoriesList[0].parentId.toString();
	}
}
