/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MarketPlace } from '../models/market-place';
import { MarketPlace as MarketPlaceListMarketPlace } from '../models/MarketPlace-list/market-place';
import { MarketPlace as MarketPlaceReadMarketPlace } from '../models/MarketPlace-read/market-place';
import { MarketPlace as MarketPlaceWriteMarketPlace } from '../models/MarketPlace-write/market-place';
import { Jsonld as MarketPlaceJsonld } from '../models/MarketPlace/jsonld';
import { MarketPlace as MarketPlaceJsonldListMarketPlace } from '../models/MarketPlace/jsonld-list/market-place';
import { MarketPlace as MarketPlaceJsonldReadMarketPlace } from '../models/MarketPlace/jsonld-read/market-place';
import { MarketPlace as MarketPlaceJsonldWriteMarketPlace } from '../models/MarketPlace/jsonld-write/market-place';

@Injectable({
  providedIn: 'root',
})
export class MarketPlaceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMarketPlaceCollection
   */
  static readonly GetMarketPlaceCollectionPath = '/api/market_places';

  /**
   * Retrieves the collection of MarketPlace resources.
   *
   * Retrieves the collection of MarketPlace resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketPlaceCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'activationDate[before]'?: string;
    'activationDate[strictly_before]'?: string;
    'activationDate[after]'?: string;
    'activationDate[strictly_after]'?: string;
    isInHome?: boolean;
    categories?: string;
    tagNameSearch?: string;
    name?: string;
    description?: string;
    status?: string;
    igic?: number;
    freeShipmentPrice?: number;
    shipmentPrice?: number;
    'categories.name'?: string;
    'logo.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MarketPlaceListMarketPlace>>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.GetMarketPlaceCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('activationDate[before]', params['activationDate[before]'], {"style":"form","explode":false});
      rb.query('activationDate[strictly_before]', params['activationDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('activationDate[after]', params['activationDate[after]'], {"style":"form","explode":false});
      rb.query('activationDate[strictly_after]', params['activationDate[strictly_after]'], {"style":"form","explode":false});
      rb.query('isInHome', params.isInHome, {"style":"form","explode":false});
      rb.query('categories', params.categories, {"style":"form","explode":false});
      rb.query('tagNameSearch', params.tagNameSearch, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('status', params.status, {"style":"form","explode":false});
      rb.query('igic', params.igic, {"style":"form","explode":false});
      rb.query('freeShipmentPrice', params.freeShipmentPrice, {"style":"form","explode":false});
      rb.query('shipmentPrice', params.shipmentPrice, {"style":"form","explode":false});
      rb.query('categories.name', params['categories.name'], {"style":"form","explode":false});
      rb.query('logo.name', params['logo.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MarketPlaceListMarketPlace>>;
      })
    );
  }

  /**
   * Retrieves the collection of MarketPlace resources.
   *
   * Retrieves the collection of MarketPlace resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketPlaceCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'activationDate[before]'?: string;
    'activationDate[strictly_before]'?: string;
    'activationDate[after]'?: string;
    'activationDate[strictly_after]'?: string;
    isInHome?: boolean;
    categories?: string;
    tagNameSearch?: string;
    name?: string;
    description?: string;
    status?: string;
    igic?: number;
    freeShipmentPrice?: number;
    shipmentPrice?: number;
    'categories.name'?: string;
    'logo.name'?: string;
    context?: HttpContext
  }
): Observable<Array<MarketPlaceListMarketPlace>> {

    return this.getMarketPlaceCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MarketPlaceListMarketPlace>>) => r.body as Array<MarketPlaceListMarketPlace>)
    );
  }

  /**
   * Retrieves the collection of MarketPlace resources.
   *
   * Retrieves the collection of MarketPlace resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketPlaceCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'activationDate[before]'?: string;
    'activationDate[strictly_before]'?: string;
    'activationDate[after]'?: string;
    'activationDate[strictly_after]'?: string;
    isInHome?: boolean;
    categories?: string;
    tagNameSearch?: string;
    name?: string;
    description?: string;
    status?: string;
    igic?: number;
    freeShipmentPrice?: number;
    shipmentPrice?: number;
    'categories.name'?: string;
    'logo.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MarketPlaceListMarketPlace>>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.GetMarketPlaceCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('activationDate[before]', params['activationDate[before]'], {"style":"form","explode":false});
      rb.query('activationDate[strictly_before]', params['activationDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('activationDate[after]', params['activationDate[after]'], {"style":"form","explode":false});
      rb.query('activationDate[strictly_after]', params['activationDate[strictly_after]'], {"style":"form","explode":false});
      rb.query('isInHome', params.isInHome, {"style":"form","explode":false});
      rb.query('categories', params.categories, {"style":"form","explode":false});
      rb.query('tagNameSearch', params.tagNameSearch, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('description', params.description, {"style":"form","explode":false});
      rb.query('status', params.status, {"style":"form","explode":false});
      rb.query('igic', params.igic, {"style":"form","explode":false});
      rb.query('freeShipmentPrice', params.freeShipmentPrice, {"style":"form","explode":false});
      rb.query('shipmentPrice', params.shipmentPrice, {"style":"form","explode":false});
      rb.query('categories.name', params['categories.name'], {"style":"form","explode":false});
      rb.query('logo.name', params['logo.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MarketPlaceListMarketPlace>>;
      })
    );
  }

  /**
   * Retrieves the collection of MarketPlace resources.
   *
   * Retrieves the collection of MarketPlace resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketPlaceCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    'activationDate[before]'?: string;
    'activationDate[strictly_before]'?: string;
    'activationDate[after]'?: string;
    'activationDate[strictly_after]'?: string;
    isInHome?: boolean;
    categories?: string;
    tagNameSearch?: string;
    name?: string;
    description?: string;
    status?: string;
    igic?: number;
    freeShipmentPrice?: number;
    shipmentPrice?: number;
    'categories.name'?: string;
    'logo.name'?: string;
    context?: HttpContext
  }
): Observable<Array<MarketPlaceListMarketPlace>> {

    return this.getMarketPlaceCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MarketPlaceListMarketPlace>>) => r.body as Array<MarketPlaceListMarketPlace>)
    );
  }

  /**
   * Path part for operation postMarketPlaceCollection
   */
  static readonly PostMarketPlaceCollectionPath = '/api/market_places';

  /**
   * Creates a MarketPlace resource.
   *
   * Creates a MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketPlaceCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketPlaceCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<StrictHttpResponse<MarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.PostMarketPlaceCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlace>;
      })
    );
  }

  /**
   * Creates a MarketPlace resource.
   *
   * Creates a MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketPlaceCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketPlaceCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<MarketPlace> {

    return this.postMarketPlaceCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlace>) => r.body as MarketPlace)
    );
  }

  /**
   * Creates a MarketPlace resource.
   *
   * Creates a MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketPlaceCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketPlaceCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<StrictHttpResponse<MarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.PostMarketPlaceCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlace>;
      })
    );
  }

  /**
   * Creates a MarketPlace resource.
   *
   * Creates a MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketPlaceCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMarketPlaceCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<MarketPlace> {

    return this.postMarketPlaceCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlace>) => r.body as MarketPlace)
    );
  }

  /**
   * Creates a MarketPlace resource.
   *
   * Creates a MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketPlaceCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketPlaceCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<StrictHttpResponse<MarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.PostMarketPlaceCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlace>;
      })
    );
  }

  /**
   * Creates a MarketPlace resource.
   *
   * Creates a MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketPlaceCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketPlaceCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<MarketPlace> {

    return this.postMarketPlaceCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlace>) => r.body as MarketPlace)
    );
  }

  /**
   * Creates a MarketPlace resource.
   *
   * Creates a MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMarketPlaceCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketPlaceCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<StrictHttpResponse<MarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.PostMarketPlaceCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlace>;
      })
    );
  }

  /**
   * Creates a MarketPlace resource.
   *
   * Creates a MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMarketPlaceCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postMarketPlaceCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<MarketPlace> {

    return this.postMarketPlaceCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlace>) => r.body as MarketPlace)
    );
  }

  /**
   * Path part for operation getMarketPlaceItem
   */
  static readonly GetMarketPlaceItemPath = '/api/market_places/{id}';

  /**
   * Retrieves a MarketPlace resource.
   *
   * Retrieves a MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketPlaceItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MarketPlaceReadMarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.GetMarketPlaceItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceReadMarketPlace>;
      })
    );
  }

  /**
   * Retrieves a MarketPlace resource.
   *
   * Retrieves a MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketPlaceItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<MarketPlaceReadMarketPlace> {

    return this.getMarketPlaceItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceReadMarketPlace>) => r.body as MarketPlaceReadMarketPlace)
    );
  }

  /**
   * Retrieves a MarketPlace resource.
   *
   * Retrieves a MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketPlaceItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MarketPlaceReadMarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.GetMarketPlaceItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlaceReadMarketPlace>;
      })
    );
  }

  /**
   * Retrieves a MarketPlace resource.
   *
   * Retrieves a MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketPlaceItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketPlaceItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<MarketPlaceReadMarketPlace> {

    return this.getMarketPlaceItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlaceReadMarketPlace>) => r.body as MarketPlaceReadMarketPlace)
    );
  }

  /**
   * Path part for operation putMarketPlaceItem
   */
  static readonly PutMarketPlaceItemPath = '/api/market_places/{id}';

  /**
   * Replaces the MarketPlace resource.
   *
   * Replaces the MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketPlaceItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketPlaceItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<StrictHttpResponse<MarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.PutMarketPlaceItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlace>;
      })
    );
  }

  /**
   * Replaces the MarketPlace resource.
   *
   * Replaces the MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketPlaceItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketPlaceItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<MarketPlace> {

    return this.putMarketPlaceItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlace>) => r.body as MarketPlace)
    );
  }

  /**
   * Replaces the MarketPlace resource.
   *
   * Replaces the MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketPlaceItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketPlaceItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<StrictHttpResponse<MarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.PutMarketPlaceItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlace>;
      })
    );
  }

  /**
   * Replaces the MarketPlace resource.
   *
   * Replaces the MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketPlaceItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMarketPlaceItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<MarketPlace> {

    return this.putMarketPlaceItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlace>) => r.body as MarketPlace)
    );
  }

  /**
   * Replaces the MarketPlace resource.
   *
   * Replaces the MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketPlaceItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketPlaceItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<StrictHttpResponse<MarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.PutMarketPlaceItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlace>;
      })
    );
  }

  /**
   * Replaces the MarketPlace resource.
   *
   * Replaces the MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketPlaceItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketPlaceItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<MarketPlace> {

    return this.putMarketPlaceItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlace>) => r.body as MarketPlace)
    );
  }

  /**
   * Replaces the MarketPlace resource.
   *
   * Replaces the MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMarketPlaceItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketPlaceItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<StrictHttpResponse<MarketPlace>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.PutMarketPlaceItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MarketPlace>;
      })
    );
  }

  /**
   * Replaces the MarketPlace resource.
   *
   * Replaces the MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMarketPlaceItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putMarketPlaceItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated MarketPlace resource
     */
    body: MarketPlaceWriteMarketPlace
  }
): Observable<MarketPlace> {

    return this.putMarketPlaceItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<MarketPlace>) => r.body as MarketPlace)
    );
  }

  /**
   * Path part for operation deleteMarketPlaceItem
   */
  static readonly DeleteMarketPlaceItemPath = '/api/market_places/{id}';

  /**
   * Removes the MarketPlace resource.
   *
   * Removes the MarketPlace resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMarketPlaceItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMarketPlaceItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MarketPlaceService.DeleteMarketPlaceItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the MarketPlace resource.
   *
   * Removes the MarketPlace resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMarketPlaceItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMarketPlaceItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteMarketPlaceItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
