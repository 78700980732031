import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { User, UserWriteUser } from 'src/app/api/models';
import { AddressService, UserService } from 'src/app/api/services';
import { ToastMsgsService } from '../../services/toast-msgs.service';
import { ConfirmationPopoverComponent } from '../../popovers/confirmation-popover/confirmation-popover.component';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-user-data',
	templateUrl: './user-data.component.html',
	styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent implements OnInit {
	public shippingAddress: any;
	public billingAddress: any;
	public isValidShipping: boolean;
	public isValidBilling: boolean;
	public isShippingLoading: boolean;
	public isBillingLoading: boolean;
	public userLoged: User = {};
	public shippingId: string = '';
	public billingId: string = '';
	public sameDirection: boolean = false;
	public isLoading: boolean;
	public modified: boolean;
	public isValidShippingPostalCode: boolean = true;
	public isValidShippingNie: boolean = true;
	public isValidBillingPostalCode: boolean = true;
	public isValidBillingNie: boolean = true;

	constructor(
		private userService: UserService,
		private addressService: AddressService,
		private popoverCtrl: PopoverController,
		private toast: ToastMsgsService,
		private cookieService: CookieService,
		private location: Location,
		private navController: NavController
	) {}

	ngOnInit(): void {
		this.userService.meUserCollection$Json().subscribe((response) => {
			this.userLoged = response as User;

			if (
				this.userLoged &&
				this.userLoged.defaultShippingAddress != null
			) {
				let shippingIri =
					this.userLoged?.defaultShippingAddress.split('/');
				this.shippingId = shippingIri[shippingIri.length - 1];
			}

			if (
				this.userLoged &&
				this.userLoged.defaultBillingAddress != null
			) {
				let billingIri =
					this.userLoged?.defaultBillingAddress.split('/');
				this.billingId = billingIri[billingIri.length - 1];
			}
		});
	}

	checkLoged() {
		if (!this.cookieService.get('BEARER_TOKEN')) {
			this.navController.navigateRoot(['/login']);
		}
	}

	goBack() {
		this.location.back();
	}

	isShippingAddressCorrect(event: any) {
		this.isValidShipping = event;
	}

	isBillingAddressCorrect(event: any) {
		this.isValidBilling = event;
	}

	setNewShippingAddress(event: any) {
		this.shippingAddress = event;
		this.setModified();
	}

	setNewBillingngAddress(event: any) {
		this.billingAddress = event;
		this.setModified();
	}

	modifyBillingAddress() {
		this.addressService
			.putAddressItem$Json$Json({
				id: this.billingId,
				body: this.billingAddress,
			})
			.subscribe(
				() => {
					this.toast.successToast(
						'Direccion de facturacion guardada correctamente'
					);
					this.isBillingLoading = false;
					this.billingAddress = undefined;
					this.setLoading();
					this.setModified();
				},
				() => {
					this.toast.errorToast(
						'Error guardando la direccion de facturacion'
					);
					this.isBillingLoading = false;
					this.setLoading();
				}
			);
	}

	modifyShippingAddress() {
		this.addressService
			.putAddressItem$Json$Json({
				id: this.shippingId,
				body: this.shippingAddress,
			})
			.subscribe(
				() => {
					this.toast.successToast(
						'Direccion de envio guardada correctamente'
					);
					this.isShippingLoading = false;
					this.shippingAddress = undefined;
					this.setLoading();
					this.setModified();
				},
				() => {
					this.toast.errorToast(
						'Error guardando la direccion de envio'
					);
					this.isShippingLoading = false;
					this.setLoading();
				}
			);
	}

	setNewDefaultAddress(iri: string, option: string) {
		let newUserData: User = {};

		switch (option) {
			case 'billing':
				newUserData.defaultBillingAddress = iri;
				break;
			case 'shipping':
				newUserData.defaultShippingAddress = iri;
				break;
			default:
				return false;
		}
		this.userService
			.putUserItem$Json$Json({
				id: this.userLoged.id.toString(),
				body: newUserData,
			})
			.subscribe(
				() => {},
				() => {}
			);
	}

	sameAddress(event: any) {
		if (event) {
			this.billingAddress = this.shippingAddress;
			this.isValidBilling = true;
		} else {
			this.billingAddress = {};
			this.isValidBilling = false;
		}
		this.sameDirection = event;
	}

	saveForms() {
		this.isLoading = true;

		if (this.isValidBilling && this.billingAddress) {
			this.isBillingLoading = true;
			if (this.billingId) {
				this.modifyBillingAddress();
			} else {
				let addressIri = '/api/addresses/';
				this.addressService
					.postAddressCollection$Json$Json({
						body: this.billingAddress,
					})
					.subscribe(
						(response) => {
							this.toast.successToast(
								'Direccion de facturacion actualizada correctamente'
							);
							addressIri += response.id;
							this.setNewDefaultAddress(addressIri, 'billing');
							this.isBillingLoading = false;
							this.billingAddress = undefined;
							this.setLoading();
							this.setModified();
						},
						() => {
							this.toast.errorToast(
								'Error actualizando la direccion de facturacion'
							);
							this.isBillingLoading = false;
							this.setLoading();
						}
					);
			}
		}

		if (this.isValidShipping && this.shippingAddress) {
			this.isShippingLoading = true;
			if (this.shippingId) {
				this.modifyShippingAddress();
			} else {
				let addressIri = '/api/addresses/';
				this.addressService
					.postAddressCollection$Json$Json({
						body: this.shippingAddress,
					})
					.subscribe(
						(response) => {
							this.toast.successToast(
								'Direccion de envio actualizada correctamente'
							);
							addressIri += response.id;
							this.setNewDefaultAddress(addressIri, 'shipping');
							this.isShippingLoading = false;
							this.shippingAddress = undefined;
							this.setLoading();
							this.setModified();
						},
						() => {
							this.toast.errorToast(
								'Error actualizando la direccion de envio'
							);
							this.isShippingLoading = false;
							this.setLoading();
						}
					);
			}
		}
	}

	desactivateAccount() {
		let userModifications: UserWriteUser = {
			active: false,
		};

		let params = {
			id: this.userLoged.id.toString(),
			body: userModifications,
		};

		this.userService.putUserItem$Json$Json(params).subscribe((response) => {
			if (!response.active) {
				localStorage.removeItem('auth');
				this.popoverCtrl.getTop().then((popover) => {
					popover.dismiss();
				});
				this.navController.navigateRoot(['/login']);
			}
		});
	}

	confirmationPopover() {
		this.popoverCtrl
			.create({
				component: ConfirmationPopoverComponent,
				componentProps: {
					callback: () => {
						this.desactivateAccount();
					},
					message: '¿ESTAS SEGURO DE QUE QUIERES ELIMINAR TU CUENTA?',
				},
			})
			.then((popover) => {
				popover.present();
			});
	}

	setLoading() {
		this.isLoading = this.isShippingLoading || this.isBillingLoading;
	}

	setModified() {
		this.modified =
			this.billingAddress != undefined ||
			this.shippingAddress != undefined;
		console.log({ modified: this.modified });
	}

	isAdressPostalCodeCorrect(event: any) {
		this.isValidShippingPostalCode = event;
	}

	isAdressNieCorrect(event: any) {
		this.isValidShippingNie = event;
	}

	isBillingPostalCodeCorrect(event: any) {
		this.isValidBillingPostalCode = event;
	}

	isBillingNieCorrect(event: any) {
		this.isValidBillingNie = event;
	}
}
