import { Injectable } from '@angular/core';
import { InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class WebViewService {
	private browser;
	private payUrl: string = environment.middleEndCart;
	private regExClose = /.*error/;
	private regExCorrect = /.*success/;
	private regExBack = /.*back/;

	private options: InAppBrowserOptions = {
		zoom: 'no',
		fullscreen: 'yes',
		hideurlbar: 'yes',
		hidenavigationbuttons: 'yes',
		toolbar: 'no',
		useWideViewPort: 'yes',
		location: 'no',
	};

	constructor(private iab: InAppBrowser) {}

	openWebView(url: string) {
		this.browser = this.iab
			.create(url, '_blank', this.options)
			.on('loaderror')
			.subscribe((event) => {
				this.browser.close();
			});
	}

	openPayView() {
		this.browser = this.iab
			.create(environment.middleEndCart, '_blank', this.options)
			.on('loadstart')
			.subscribe((event) => {
				if (this.regExBack.test(event.url)) {
					this.browser.close();
				}

				if (this.regExClose.test(event.url)) {
					this.browser.close();
				}

				if (this.regExCorrect.test(event.url)) {
					this.browser.close();
					/*Redirect to payment flow*/
				}
			});
	}

	onError() {
		this.browser.on('loaderror').subscribe((event) => {
			this.browser.close();
		});
	}

	listenUrlChanges() {
		this.browser.on('loadstart').subscribe((event) => {
			this.urlController(event.url);
		});
	}

	urlController(url: string) {
		if (this.regExBack.test(url)) {
			this.browser.close();
		}

		if (this.regExClose.test(url)) {
			this.browser.close();
		}

		if (this.regExCorrect.test(url)) {
			this.browser.close();
			/*Redirect to payment flow*/
		}
	}

	isAlreadyOpen() {
		if (this.browser) {
			return true;
		}
		return false;
	}
}
