import { Component, OnInit } from '@angular/core';
import { FacebookAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-login-button-facebook',
	templateUrl: './login-button-facebook.component.html',
	styleUrls: ['./login-button-facebook.component.css'],
})
export class LoginButtonFacebookComponent implements OnInit {
	constructor(
		public afAuth: AngularFireAuth,
		private http: HttpClient,
		private cookieService: CookieService,
		private navController: NavController
	) {}

	ngOnInit() {}

	// Sign in with Facebook
	facebookAuth() {
		return this.authLogin(new FacebookAuthProvider());
	}
	// Auth logic to run auth providers
	authLogin(provider) {
		return this.afAuth
			.signInWithPopup(provider)
			.then((result) => {
				let param = {
					type: 'facebook',
					...result,
				};
				this.http.post(environment.socialLoginURL, param).subscribe(
					(res: any) => {
						console.log(res);
						this.cookieService.set(
							'BEARER_TOKEN',
							res.token,
							new Date('2100-12-31'),
							'/',
							environment.cookieDomain
						);
						this.navController.navigateRoot(['/user']);
					},
					() => {}
				);
			})
			.catch((error) => {
				console.log(error);
			});
	}
}
