/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Card } from '../models/card';
import { Card as CardListCard } from '../models/Card-list/card';
import { Card as CardReadCard } from '../models/Card-read/card';
import { Card as CardWriteCard } from '../models/Card-write/card';
import { Jsonld as CardJsonld } from '../models/Card/jsonld';
import { Card as CardJsonldListCard } from '../models/Card/jsonld-list/card';
import { Card as CardJsonldReadCard } from '../models/Card/jsonld-read/card';
import { Card as CardJsonldWriteCard } from '../models/Card/jsonld-write/card';

@Injectable({
  providedIn: 'root',
})
export class CardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCardCollection
   */
  static readonly GetCardCollectionPath = '/api/cards';

  /**
   * Retrieves the collection of Card resources.
   *
   * Retrieves the collection of Card resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    number?: string;
    'user.email'?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CardListCard>>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.GetCardCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('active', params.active, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CardListCard>>;
      })
    );
  }

  /**
   * Retrieves the collection of Card resources.
   *
   * Retrieves the collection of Card resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCardCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    number?: string;
    'user.email'?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<Array<CardListCard>> {

    return this.getCardCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CardListCard>>) => r.body as Array<CardListCard>)
    );
  }

  /**
   * Retrieves the collection of Card resources.
   *
   * Retrieves the collection of Card resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    number?: string;
    'user.email'?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CardListCard>>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.GetCardCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('active', params.active, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CardListCard>>;
      })
    );
  }

  /**
   * Retrieves the collection of Card resources.
   *
   * Retrieves the collection of Card resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCardCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    number?: string;
    'user.email'?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<Array<CardListCard>> {

    return this.getCardCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CardListCard>>) => r.body as Array<CardListCard>)
    );
  }

  /**
   * Path part for operation getCardItem
   */
  static readonly GetCardItemPath = '/api/cards/{id}';

  /**
   * Retrieves a Card resource.
   *
   * Retrieves a Card resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CardReadCard>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.GetCardItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CardReadCard>;
      })
    );
  }

  /**
   * Retrieves a Card resource.
   *
   * Retrieves a Card resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCardItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<CardReadCard> {

    return this.getCardItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CardReadCard>) => r.body as CardReadCard)
    );
  }

  /**
   * Retrieves a Card resource.
   *
   * Retrieves a Card resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CardReadCard>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.GetCardItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CardReadCard>;
      })
    );
  }

  /**
   * Retrieves a Card resource.
   *
   * Retrieves a Card resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCardItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<CardReadCard> {

    return this.getCardItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<CardReadCard>) => r.body as CardReadCard)
    );
  }

  /**
   * Path part for operation putCardItem
   */
  static readonly PutCardItemPath = '/api/cards/{id}';

  /**
   * Replaces the Card resource.
   *
   * Replaces the Card resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCardItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCardItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Card resource
     */
    body: CardWriteCard
  }
): Observable<StrictHttpResponse<Card>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.PutCardItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Card>;
      })
    );
  }

  /**
   * Replaces the Card resource.
   *
   * Replaces the Card resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCardItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCardItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Card resource
     */
    body: CardWriteCard
  }
): Observable<Card> {

    return this.putCardItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Card>) => r.body as Card)
    );
  }

  /**
   * Replaces the Card resource.
   *
   * Replaces the Card resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCardItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCardItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Card resource
     */
    body: CardWriteCard
  }
): Observable<StrictHttpResponse<Card>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.PutCardItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Card>;
      })
    );
  }

  /**
   * Replaces the Card resource.
   *
   * Replaces the Card resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCardItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCardItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Card resource
     */
    body: CardWriteCard
  }
): Observable<Card> {

    return this.putCardItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Card>) => r.body as Card)
    );
  }

  /**
   * Replaces the Card resource.
   *
   * Replaces the Card resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCardItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCardItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Card resource
     */
    body: CardWriteCard
  }
): Observable<StrictHttpResponse<Card>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.PutCardItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Card>;
      })
    );
  }

  /**
   * Replaces the Card resource.
   *
   * Replaces the Card resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCardItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCardItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Card resource
     */
    body: CardWriteCard
  }
): Observable<Card> {

    return this.putCardItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Card>) => r.body as Card)
    );
  }

  /**
   * Replaces the Card resource.
   *
   * Replaces the Card resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCardItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCardItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Card resource
     */
    body: CardWriteCard
  }
): Observable<StrictHttpResponse<Card>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.PutCardItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Card>;
      })
    );
  }

  /**
   * Replaces the Card resource.
   *
   * Replaces the Card resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCardItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCardItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Card resource
     */
    body: CardWriteCard
  }
): Observable<Card> {

    return this.putCardItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Card>) => r.body as Card)
    );
  }

  /**
   * Path part for operation deleteCardItem
   */
  static readonly DeleteCardItemPath = '/api/cards/{id}';

  /**
   * Removes the Card resource.
   *
   * Removes the Card resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCardItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCardItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CardService.DeleteCardItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Card resource.
   *
   * Removes the Card resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCardItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCardItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteCardItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
