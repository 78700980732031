import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
	constructor(
		private navController: NavController,
		private cookieService: CookieService
	) {}

	ngOnInit() {
		this.checkLoged();
	}

	logOut() {
		this.cookieService.delete(
			'BEARER_TOKEN',
			'/',
			environment.cookieDomain
		);
		this.navController.navigateRoot(['/home']);
	}

	goToHelp() {
		this.navController.navigateRoot(['/help']);
	}

	goToPayments() {
		this.navController.navigateRoot(['/payments']);
	}

	goToUserData() {
		this.navController.navigateRoot(['/user-data']);
	}

	goToShipments() {
		this.navController.navigateRoot(['/shipments']);
	}

	checkLoged() {
		if (!this.cookieService.get('BEARER_TOKEN')) {
			this.navController.navigateRoot(['/login']);
		}
	}
}
