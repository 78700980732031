/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Payment as PaymentListPayment } from '../models/Payment-list/payment';
import { Payment as PaymentReadPayment } from '../models/Payment-read/payment';
import { Payment as PaymentJsonldListPayment } from '../models/Payment/jsonld-list/payment';
import { Payment as PaymentJsonldReadPayment } from '../models/Payment/jsonld-read/payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPaymentCollection
   */
  static readonly GetPaymentCollectionPath = '/api/payments';

  /**
   * Retrieves the collection of Payment resources.
   *
   * Retrieves the collection of Payment resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    status?: string;
    amount?: number;
    'user.email'?: string;
    'order.number'?: string;
    number?: number;
    'paymentDate[before]'?: string;
    'paymentDate[strictly_before]'?: string;
    'paymentDate[after]'?: string;
    'paymentDate[strictly_after]'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PaymentListPayment>>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.GetPaymentCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('status', params.status, {"style":"form","explode":false});
      rb.query('amount', params.amount, {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('order.number', params['order.number'], {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('paymentDate[before]', params['paymentDate[before]'], {"style":"form","explode":false});
      rb.query('paymentDate[strictly_before]', params['paymentDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('paymentDate[after]', params['paymentDate[after]'], {"style":"form","explode":false});
      rb.query('paymentDate[strictly_after]', params['paymentDate[strictly_after]'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentListPayment>>;
      })
    );
  }

  /**
   * Retrieves the collection of Payment resources.
   *
   * Retrieves the collection of Payment resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    status?: string;
    amount?: number;
    'user.email'?: string;
    'order.number'?: string;
    number?: number;
    'paymentDate[before]'?: string;
    'paymentDate[strictly_before]'?: string;
    'paymentDate[after]'?: string;
    'paymentDate[strictly_after]'?: string;
    context?: HttpContext
  }
): Observable<Array<PaymentListPayment>> {

    return this.getPaymentCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PaymentListPayment>>) => r.body as Array<PaymentListPayment>)
    );
  }

  /**
   * Retrieves the collection of Payment resources.
   *
   * Retrieves the collection of Payment resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    status?: string;
    amount?: number;
    'user.email'?: string;
    'order.number'?: string;
    number?: number;
    'paymentDate[before]'?: string;
    'paymentDate[strictly_before]'?: string;
    'paymentDate[after]'?: string;
    'paymentDate[strictly_after]'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PaymentListPayment>>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.GetPaymentCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('status', params.status, {"style":"form","explode":false});
      rb.query('amount', params.amount, {"style":"form","explode":false});
      rb.query('user.email', params['user.email'], {"style":"form","explode":false});
      rb.query('order.number', params['order.number'], {"style":"form","explode":false});
      rb.query('number', params.number, {"style":"form","explode":false});
      rb.query('paymentDate[before]', params['paymentDate[before]'], {"style":"form","explode":false});
      rb.query('paymentDate[strictly_before]', params['paymentDate[strictly_before]'], {"style":"form","explode":false});
      rb.query('paymentDate[after]', params['paymentDate[after]'], {"style":"form","explode":false});
      rb.query('paymentDate[strictly_after]', params['paymentDate[strictly_after]'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentListPayment>>;
      })
    );
  }

  /**
   * Retrieves the collection of Payment resources.
   *
   * Retrieves the collection of Payment resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    status?: string;
    amount?: number;
    'user.email'?: string;
    'order.number'?: string;
    number?: number;
    'paymentDate[before]'?: string;
    'paymentDate[strictly_before]'?: string;
    'paymentDate[after]'?: string;
    'paymentDate[strictly_after]'?: string;
    context?: HttpContext
  }
): Observable<Array<PaymentListPayment>> {

    return this.getPaymentCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PaymentListPayment>>) => r.body as Array<PaymentListPayment>)
    );
  }

  /**
   * Path part for operation getPaymentItem
   */
  static readonly GetPaymentItemPath = '/api/payments/{id}';

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaymentReadPayment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.GetPaymentItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentReadPayment>;
      })
    );
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<PaymentReadPayment> {

    return this.getPaymentItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentReadPayment>) => r.body as PaymentReadPayment)
    );
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaymentReadPayment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.GetPaymentItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentReadPayment>;
      })
    );
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<PaymentReadPayment> {

    return this.getPaymentItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentReadPayment>) => r.body as PaymentReadPayment)
    );
  }

}
