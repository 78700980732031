import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'mediaObject'
})
export class MediaObjectPipe implements PipeTransform {

  transform(route: string): string {
    return environment.mediaUrl+route;
  }

}
