/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { User } from '../models/user';
import { User as UserListUser } from '../models/User-list/user';
import { User as UserReadUser } from '../models/User-read/user';
import { UserRegister } from '../models/user-register';
import { User as UserWriteUser } from '../models/User-write/user';
import { Jsonld as UserJsonld } from '../models/User/jsonld';
import { User as UserJsonldListUser } from '../models/User/jsonld-list/user';
import { User as UserJsonldReadUser } from '../models/User/jsonld-read/user';
import { JsonldRegister as UserJsonldRegister } from '../models/User/jsonld-register';
import { User as UserJsonldWriteUser } from '../models/User/jsonld-write/user';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation meUserCollection
   */
  static readonly MeUserCollectionPath = '/api/me';

  /**
   * Retrieves the collection of User resources.
   *
   * Retrieves the collection of User resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meUserCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  meUserCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    email?: string;
    roles?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.MeUserCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('email', params.email, {"style":"form","explode":false});
      rb.query('roles', params.roles, {"style":"form","explode":false});
      rb.query('active', params.active, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * Retrieves the collection of User resources.
   *
   * Retrieves the collection of User resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meUserCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meUserCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    email?: string;
    roles?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<Array<User>> {

    return this.meUserCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

  /**
   * Retrieves the collection of User resources.
   *
   * Retrieves the collection of User resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `meUserCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  meUserCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    email?: string;
    roles?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.MeUserCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('email', params.email, {"style":"form","explode":false});
      rb.query('roles', params.roles, {"style":"form","explode":false});
      rb.query('active', params.active, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * Retrieves the collection of User resources.
   *
   * Retrieves the collection of User resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `meUserCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  meUserCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    email?: string;
    roles?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<Array<User>> {

    return this.meUserCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

  /**
   * Path part for operation registerUserCollection
   */
  static readonly RegisterUserCollectionPath = '/api/register';

  /**
   * Creates a User resource.
   *
   * Creates a User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUserCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUserCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new User resource
     */
    body: UserRegister
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.RegisterUserCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Creates a User resource.
   *
   * Creates a User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerUserCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUserCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new User resource
     */
    body: UserRegister
  }
): Observable<User> {

    return this.registerUserCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Creates a User resource.
   *
   * Creates a User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUserCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUserCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new User resource
     */
    body: UserRegister
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.RegisterUserCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Creates a User resource.
   *
   * Creates a User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerUserCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUserCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new User resource
     */
    body: UserRegister
  }
): Observable<User> {

    return this.registerUserCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Creates a User resource.
   *
   * Creates a User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUserCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  registerUserCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new User resource
     */
    body: UserRegister
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.RegisterUserCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Creates a User resource.
   *
   * Creates a User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerUserCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  registerUserCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new User resource
     */
    body: UserRegister
  }
): Observable<User> {

    return this.registerUserCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Creates a User resource.
   *
   * Creates a User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUserCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  registerUserCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new User resource
     */
    body: UserRegister
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.RegisterUserCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Creates a User resource.
   *
   * Creates a User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerUserCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  registerUserCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new User resource
     */
    body: UserRegister
  }
): Observable<User> {

    return this.registerUserCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation getUserCollection
   */
  static readonly GetUserCollectionPath = '/api/users';

  /**
   * Retrieves the collection of User resources.
   *
   * Retrieves the collection of User resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    email?: string;
    roles?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserListUser>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('email', params.email, {"style":"form","explode":false});
      rb.query('roles', params.roles, {"style":"form","explode":false});
      rb.query('active', params.active, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserListUser>>;
      })
    );
  }

  /**
   * Retrieves the collection of User resources.
   *
   * Retrieves the collection of User resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    email?: string;
    roles?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<Array<UserListUser>> {

    return this.getUserCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserListUser>>) => r.body as Array<UserListUser>)
    );
  }

  /**
   * Retrieves the collection of User resources.
   *
   * Retrieves the collection of User resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    email?: string;
    roles?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserListUser>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('email', params.email, {"style":"form","explode":false});
      rb.query('roles', params.roles, {"style":"form","explode":false});
      rb.query('active', params.active, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserListUser>>;
      })
    );
  }

  /**
   * Retrieves the collection of User resources.
   *
   * Retrieves the collection of User resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    email?: string;
    roles?: string;
    active?: boolean;
    context?: HttpContext
  }
): Observable<Array<UserListUser>> {

    return this.getUserCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserListUser>>) => r.body as Array<UserListUser>)
    );
  }

  /**
   * Path part for operation getUserItem
   */
  static readonly GetUserItemPath = '/api/users/{id}';

  /**
   * Retrieves a User resource.
   *
   * Retrieves a User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserReadUser>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserReadUser>;
      })
    );
  }

  /**
   * Retrieves a User resource.
   *
   * Retrieves a User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<UserReadUser> {

    return this.getUserItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserReadUser>) => r.body as UserReadUser)
    );
  }

  /**
   * Retrieves a User resource.
   *
   * Retrieves a User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserReadUser>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserReadUser>;
      })
    );
  }

  /**
   * Retrieves a User resource.
   *
   * Retrieves a User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<UserReadUser> {

    return this.getUserItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<UserReadUser>) => r.body as UserReadUser)
    );
  }

  /**
   * Path part for operation putUserItem
   */
  static readonly PutUserItemPath = '/api/users/{id}';

  /**
   * Replaces the User resource.
   *
   * Replaces the User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putUserItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putUserItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated User resource
     */
    body: UserWriteUser
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutUserItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Replaces the User resource.
   *
   * Replaces the User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putUserItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putUserItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated User resource
     */
    body: UserWriteUser
  }
): Observable<User> {

    return this.putUserItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Replaces the User resource.
   *
   * Replaces the User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putUserItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putUserItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated User resource
     */
    body: UserWriteUser
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutUserItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Replaces the User resource.
   *
   * Replaces the User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putUserItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putUserItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated User resource
     */
    body: UserWriteUser
  }
): Observable<User> {

    return this.putUserItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Replaces the User resource.
   *
   * Replaces the User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putUserItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putUserItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated User resource
     */
    body: UserWriteUser
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutUserItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Replaces the User resource.
   *
   * Replaces the User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putUserItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putUserItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated User resource
     */
    body: UserWriteUser
  }
): Observable<User> {

    return this.putUserItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Replaces the User resource.
   *
   * Replaces the User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putUserItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putUserItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated User resource
     */
    body: UserWriteUser
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutUserItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Replaces the User resource.
   *
   * Replaces the User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putUserItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putUserItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated User resource
     */
    body: UserWriteUser
  }
): Observable<User> {

    return this.putUserItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation deleteUserItem
   */
  static readonly DeleteUserItemPath = '/api/users/{id}';

  /**
   * Removes the User resource.
   *
   * Removes the User resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeleteUserItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the User resource.
   *
   * Removes the User resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteUserItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
