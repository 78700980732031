/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Cms } from '../models/cms';
import { Cms as CmsListCms } from '../models/Cms-list/cms';
import { Cms as CmsReadCms } from '../models/Cms-read/cms';
import { Cms as CmsWriteCms } from '../models/Cms-write/cms';
import { Jsonld as CmsJsonld } from '../models/Cms/jsonld';
import { Cms as CmsJsonldListCms } from '../models/Cms/jsonld-list/cms';
import { Cms as CmsJsonldReadCms } from '../models/Cms/jsonld-read/cms';
import { Cms as CmsJsonldWriteCms } from '../models/Cms/jsonld-write/cms';

@Injectable({
  providedIn: 'root',
})
export class CmsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCmsCollection
   */
  static readonly GetCmsCollectionPath = '/api/cms';

  /**
   * Retrieves the collection of Cms resources.
   *
   * Retrieves the collection of Cms resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCmsCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCollection$Json$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    'category.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CmsListCms>>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.GetCmsCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('category.name', params['category.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CmsListCms>>;
      })
    );
  }

  /**
   * Retrieves the collection of Cms resources.
   *
   * Retrieves the collection of Cms resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCmsCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCollection$Json(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    'category.name'?: string;
    context?: HttpContext
  }
): Observable<Array<CmsListCms>> {

    return this.getCmsCollection$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CmsListCms>>) => r.body as Array<CmsListCms>)
    );
  }

  /**
   * Retrieves the collection of Cms resources.
   *
   * Retrieves the collection of Cms resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCmsCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCollection$Html$Response(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    'category.name'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CmsListCms>>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.GetCmsCollectionPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form","explode":false});
      rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
      rb.query('name', params.name, {"style":"form","explode":false});
      rb.query('category.name', params['category.name'], {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CmsListCms>>;
      })
    );
  }

  /**
   * Retrieves the collection of Cms resources.
   *
   * Retrieves the collection of Cms resources.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCmsCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsCollection$Html(params?: {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    name?: string;
    'category.name'?: string;
    context?: HttpContext
  }
): Observable<Array<CmsListCms>> {

    return this.getCmsCollection$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CmsListCms>>) => r.body as Array<CmsListCms>)
    );
  }

  /**
   * Path part for operation postCmsCollection
   */
  static readonly PostCmsCollectionPath = '/api/cms';

  /**
   * Creates a Cms resource.
   *
   * Creates a Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCmsCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCmsCollection$Json$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Cms resource
     */
    body: CmsWriteCms
  }
): Observable<StrictHttpResponse<Cms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.PostCmsCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cms>;
      })
    );
  }

  /**
   * Creates a Cms resource.
   *
   * Creates a Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCmsCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCmsCollection$Json$Json(params: {
    context?: HttpContext

    /**
     * The new Cms resource
     */
    body: CmsWriteCms
  }
): Observable<Cms> {

    return this.postCmsCollection$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Cms>) => r.body as Cms)
    );
  }

  /**
   * Creates a Cms resource.
   *
   * Creates a Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCmsCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCmsCollection$Json$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Cms resource
     */
    body: CmsWriteCms
  }
): Observable<StrictHttpResponse<Cms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.PostCmsCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cms>;
      })
    );
  }

  /**
   * Creates a Cms resource.
   *
   * Creates a Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCmsCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCmsCollection$Json$Html(params: {
    context?: HttpContext

    /**
     * The new Cms resource
     */
    body: CmsWriteCms
  }
): Observable<Cms> {

    return this.postCmsCollection$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Cms>) => r.body as Cms)
    );
  }

  /**
   * Creates a Cms resource.
   *
   * Creates a Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCmsCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postCmsCollection$Html$Json$Response(params: {
    context?: HttpContext

    /**
     * The new Cms resource
     */
    body: CmsWriteCms
  }
): Observable<StrictHttpResponse<Cms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.PostCmsCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cms>;
      })
    );
  }

  /**
   * Creates a Cms resource.
   *
   * Creates a Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCmsCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postCmsCollection$Html$Json(params: {
    context?: HttpContext

    /**
     * The new Cms resource
     */
    body: CmsWriteCms
  }
): Observable<Cms> {

    return this.postCmsCollection$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Cms>) => r.body as Cms)
    );
  }

  /**
   * Creates a Cms resource.
   *
   * Creates a Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCmsCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postCmsCollection$Html$Html$Response(params: {
    context?: HttpContext

    /**
     * The new Cms resource
     */
    body: CmsWriteCms
  }
): Observable<StrictHttpResponse<Cms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.PostCmsCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cms>;
      })
    );
  }

  /**
   * Creates a Cms resource.
   *
   * Creates a Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCmsCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  postCmsCollection$Html$Html(params: {
    context?: HttpContext

    /**
     * The new Cms resource
     */
    body: CmsWriteCms
  }
): Observable<Cms> {

    return this.postCmsCollection$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Cms>) => r.body as Cms)
    );
  }

  /**
   * Path part for operation getCmsItem
   */
  static readonly GetCmsItemPath = '/api/cms/{id}';

  /**
   * Retrieves a Cms resource.
   *
   * Retrieves a Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCmsItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsItem$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CmsReadCms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.GetCmsItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsReadCms>;
      })
    );
  }

  /**
   * Retrieves a Cms resource.
   *
   * Retrieves a Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCmsItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsItem$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<CmsReadCms> {

    return this.getCmsItem$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CmsReadCms>) => r.body as CmsReadCms)
    );
  }

  /**
   * Retrieves a Cms resource.
   *
   * Retrieves a Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCmsItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsItem$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CmsReadCms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.GetCmsItemPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmsReadCms>;
      })
    );
  }

  /**
   * Retrieves a Cms resource.
   *
   * Retrieves a Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCmsItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCmsItem$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<CmsReadCms> {

    return this.getCmsItem$Html$Response(params).pipe(
      map((r: StrictHttpResponse<CmsReadCms>) => r.body as CmsReadCms)
    );
  }

  /**
   * Path part for operation putCmsItem
   */
  static readonly PutCmsItemPath = '/api/cms/{id}';

  /**
   * Replaces the Cms resource.
   *
   * Replaces the Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCmsItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCmsItem$Json$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Cms resource
     */
    body: CmsWriteCms
  }
): Observable<StrictHttpResponse<Cms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.PutCmsItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cms>;
      })
    );
  }

  /**
   * Replaces the Cms resource.
   *
   * Replaces the Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCmsItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCmsItem$Json$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Cms resource
     */
    body: CmsWriteCms
  }
): Observable<Cms> {

    return this.putCmsItem$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Cms>) => r.body as Cms)
    );
  }

  /**
   * Replaces the Cms resource.
   *
   * Replaces the Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCmsItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCmsItem$Json$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Cms resource
     */
    body: CmsWriteCms
  }
): Observable<StrictHttpResponse<Cms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.PutCmsItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cms>;
      })
    );
  }

  /**
   * Replaces the Cms resource.
   *
   * Replaces the Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCmsItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCmsItem$Json$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Cms resource
     */
    body: CmsWriteCms
  }
): Observable<Cms> {

    return this.putCmsItem$Json$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Cms>) => r.body as Cms)
    );
  }

  /**
   * Replaces the Cms resource.
   *
   * Replaces the Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCmsItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCmsItem$Html$Json$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Cms resource
     */
    body: CmsWriteCms
  }
): Observable<StrictHttpResponse<Cms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.PutCmsItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cms>;
      })
    );
  }

  /**
   * Replaces the Cms resource.
   *
   * Replaces the Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCmsItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCmsItem$Html$Json(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Cms resource
     */
    body: CmsWriteCms
  }
): Observable<Cms> {

    return this.putCmsItem$Html$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Cms>) => r.body as Cms)
    );
  }

  /**
   * Replaces the Cms resource.
   *
   * Replaces the Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCmsItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCmsItem$Html$Html$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Cms resource
     */
    body: CmsWriteCms
  }
): Observable<StrictHttpResponse<Cms>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.PutCmsItemPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
      rb.body(params.body, 'text/html');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cms>;
      })
    );
  }

  /**
   * Replaces the Cms resource.
   *
   * Replaces the Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCmsItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  putCmsItem$Html$Html(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext

    /**
     * The updated Cms resource
     */
    body: CmsWriteCms
  }
): Observable<Cms> {

    return this.putCmsItem$Html$Html$Response(params).pipe(
      map((r: StrictHttpResponse<Cms>) => r.body as Cms)
    );
  }

  /**
   * Path part for operation deleteCmsItem
   */
  static readonly DeleteCmsItemPath = '/api/cms/{id}';

  /**
   * Removes the Cms resource.
   *
   * Removes the Cms resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCmsItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCmsItem$Response(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CmsService.DeleteCmsItemPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes the Cms resource.
   *
   * Removes the Cms resource.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCmsItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCmsItem(params: {

    /**
     * Resource identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteCmsItem$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
