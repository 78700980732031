import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
	selector: 'app-payments',
	templateUrl: './payments.component.html',
	styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
	constructor(private location: Location) {}

	ngOnInit() {}

	goBack() {
		this.location.back();
	}
}
